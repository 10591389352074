import axios from 'axios'

import { SnackbarService } from '../../shared/snackbar/snackbar.service'
import { dispatchAppConfig } from '../../shared/store/app-config.store'
import { type PreviewGrabData } from '../../shared/types/applicationData.type'
import { type GEDFile } from '../../shared/types/files.type'

export type GEDFileExport = {
  message: string
  dateToPublish: Date
  countAllData: CountAllData
  versionData: VersionData
} & GEDFile

export type CountAllData = {
  genreCount: number
  infoPratiqueCount: number
  infoPratiqueSectionCount: number
  musicGroupCount: number
  programCount: number
  programEventCount: number
  sceneCount: number
  sponsorCount: number
  sponsorCategoryCount: number
  poiCount: number
  markerIconsCount: number
  zoiCount: number
  typeCounts: Record<string, number>
}

export type VersionData = {
  datas: Record<string, PreviewGrabData>
  dataCounts: CountAllData
} & BaseVersionData

export type BaseVersionData = {
  date: number | null // Assuming Joda DateTime is represented as a timestamp
  version: number | null
  api: number | null
  edition: number | null
  published: boolean | null
  locale: string | null
}

export type ExtractDTO = {
  type: ExtractDTOType
  message: string
  owner: string
  date?: number
  latest?: boolean
  versionData?: VersionData
  countAllData?: CountAllData
  id?: string
  stackTrace?: string
}

export enum ExtractDTOType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export type ExtractVM = {
  message: string
}

export const PublicationsService = {
  async getAll(): Promise<ExtractDTO[]> {
    const { data } = await axios.get('/api/exports')
    if (data.length > 0) {
      data[0].latest = true
    }
    return data
  },

  async getDiff(versionNumber: number): Promise<Record<string, unknown>> {
    const { data } = await axios.get(
      `/api/versionData/withVersion/${versionNumber}`,
    )
    return data
  },

  async publishPublication(): Promise<void> {
    try {
      await axios.post('/api/versionData')
      SnackbarService.info('Les données ont bien été publiées')
    } catch (error) {
      SnackbarService.error('Une erreur est survenue')
    }
  },

  async doPublication(message: ExtractVM): Promise<void> {
    try {
      await axios.post('/api/exports', message)
      await dispatchAppConfig('LOAD_APP_CONFIG')
      SnackbarService.info("L'export est en cours de génération.")
    } catch (error: any) {
      if (error.response && error.response.status === 304) {
        SnackbarService.warn(
          'Un export est déjà en cours, merci de patienter quelques instants...',
        )
      } else {
        SnackbarService.error("Oups, l'export n'a pas pu être planifié")
      }
    }
  },
}
