import { type RouteConfig } from 'vue-router'

import { commitMap, dispatchMap } from './store/map.store'
import { dispatchMaps } from './store/maps.store'

export const MapRouter: RouteConfig[] = [
  {
    path: '/maps',
    component: async () => import('./MapsList.vue'),
    async beforeEnter(from, to, next): Promise<void> {
      await dispatchMaps('list')
      next()
    },
    children: [],
  },
  {
    path: '/maps/:id',
    component: async () => import('./Map.vue'),
    props: (route) => ({
      mapOid: route.params.id,
    }),
    async beforeEnter(to, from, next): Promise<void> {
      commitMap('LOAD')
      await Promise.all([
        dispatchMap('getMap', { id: to.params.id }),
        dispatchMap('getMapEntityChoices'),
      ])
      next()
    },
  },
]
