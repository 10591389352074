export const LANGS = ['fr', 'en'] as const

export type Lang = (typeof LANGS)[number]

export type TranslatableText = {
  [key in Lang]: string
}

export function makeTranslatableText(fr = '', en = '') {
  return {
    fr,
    en,
  }
}
