import { ErrorService } from '@/shared/service/errorService'

import router from '../../router'
import { PAGE_MODE, type PageMode } from '../../shared/constants'
import { createStore, type GettersObj } from '../../utils/createStore'
import {
  type AttendeeWhiteListEntry,
  AttendeeWhiteListService,
} from './attendeeWhiteList.service'

export type GettersAttendeeWhiteList = GettersObj<
  typeof mapGettersAttendeeWhiteList
>

export class AttendeeWhiteListState {
  mode: PageMode = PAGE_MODE.VIEW
  attendeeWhiteLists: AttendeeWhiteListEntry[] = []
  currentAttendeeWhiteList: AttendeeWhiteListEntry = {
    email: '',
    lastname: '',
    firstname: '',
  }

  pagination = {
    sortBy: ['email'],
    sortDesc: [true],
    itemsPerPage: 10,
    page: 1,
    totalItems: 0,
  }

  filter = {
    search: '',
    onlyReported: false,
  }

  loading = false
}

export const {
  attendeeWhiteList,
  commit: commitAttendeeWhiteList,
  dispatch: dispatchAttendeeWhiteList,
  mapGetters: mapGettersAttendeeWhiteList,
  mapState: mapStateAttendeeWhiteList,
  useGetter: useGetterAttendeeWhiteList,
  useState: useStateAttendeeWhiteList,
} = createStore({
  namespaced: true,
  moduleName: 'attendeeWhiteList',
  initState: new AttendeeWhiteListState(),
  mutations: {
    LOAD(state) {
      state.loading = true
    },
    SET_DELETING(state, payload: AttendeeWhiteListEntry) {
      state.mode = PAGE_MODE.DELETE
      state.currentAttendeeWhiteList = { ...payload }
    },
    SET_DELETE_ALL(state) {
      state.mode = PAGE_MODE.DELETE_ALL
    },
    SET_VIEW(state) {
      state.mode = PAGE_MODE.VIEW
      state.loading = false
      state.currentAttendeeWhiteList = {
        email: '',
        lastname: '',
        firstname: '',
      }
    },
    UPDATE_CURRENT_ATTENDEE_WHITE_LIST(
      state,
      payload: Partial<AttendeeWhiteListEntry>,
    ) {
      state.currentAttendeeWhiteList = {
        ...state.currentAttendeeWhiteList,
        ...payload,
      }
    },
  },
  actions: {
    async list(
      { commit, state },
      payload: {
        filter?: AttendeeWhiteListState['filter']
        pagination?: AttendeeWhiteListState['pagination']
      } = {},
    ) {
      commit('LOAD')
      if (payload.pagination) {
        state.pagination = {
          ...state.pagination,
          ...payload.pagination,
        }
      }
      if (payload.filter) {
        state.filter = { ...state.filter, ...payload.filter }
      }
      const { sortBy, sortDesc, page, itemsPerPage } = state.pagination
      const { attendeeWhiteLists, total } =
        await AttendeeWhiteListService.getAll(
          state.filter.search,
          page,
          itemsPerPage,
          sortBy[0],
          sortDesc[0],
          state.filter.onlyReported,
        )
      state.attendeeWhiteLists = attendeeWhiteLists
      state.pagination.totalItems = total
      state.loading = false
    },

    async save({ commit, dispatch, state }) {
      commit('LOAD')
      try {
        await AttendeeWhiteListService.update(state.currentAttendeeWhiteList)
        await dispatch('list')
        await router.push('/attendee-white-list')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_VIEW')
      }
    },

    async saveFile({ commit, dispatch }, file: Blob) {
      try {
        commit('LOAD')
        const x64File = await new Promise<string | ArrayBuffer | null>(
          (resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = (): void => {
              resolve(reader.result)
            }
            reader.onerror = (error): void => {
              console.error(error)
              reject(
                error instanceof Error
                  ? error
                  : new Error('Error reading file'),
              )
            }
          },
        )
        await AttendeeWhiteListService.uploadFile(x64File as string)
        await dispatch('list')
        await router.push('/attendee-white-list')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_VIEW')
      }
    },

    async delete({ commit, state }) {
      commit('LOAD')
      try {
        if (!state.currentAttendeeWhiteList._id) {
          return
        }
        await AttendeeWhiteListService.delete(
          state.currentAttendeeWhiteList._id,
        )
        state.attendeeWhiteLists = state.attendeeWhiteLists.filter(
          (item) => item._id !== state.currentAttendeeWhiteList._id,
        )
        state.pagination.totalItems = state.pagination.totalItems - 1
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_VIEW')
      }
    },

    async deleteAll({ commit, state }) {
      commit('LOAD')
      try {
        await AttendeeWhiteListService.deleteAll()
        state.attendeeWhiteLists = []
        state.pagination.totalItems = 0
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_VIEW')
      }
    },
  },
  getters: {},
})
