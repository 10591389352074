import axios from 'axios'

import { type TranslatableText } from '../../../types/TranslatableText.type'

export type NotificationTopic = {
  _id?: string
  names: TranslatableText
  selectedByDefault?: boolean
  color: string
  deleted?: boolean
}

export const NotificationsTopicsService = {
  async getAll(): Promise<NotificationTopic[]> {
    const { data } = await axios.get('/api/notif/topic')
    return data
  },

  async getTopicById(id: string): Promise<NotificationTopic | null> {
    const { data } = await axios.get(`/api/notif/topic/${id}`)
    return data
  },

  async deleteNotifTopic(id: string): Promise<void> {
    await axios.delete('/api/notif/topic/' + id)
  },

  async addNotifTopic(
    notifTopic: NotificationTopic,
  ): Promise<NotificationTopic> {
    const { data } = await axios.post('/api/notif/topic', notifTopic)
    return data
  },

  async editNotifTopic(
    id: string,
    notifTopic: NotificationTopic,
  ): Promise<NotificationTopic> {
    const { data } = await axios.put(`/api/notif/topic/${id}`, notifTopic)
    return data
  },
}
