import { IdsService } from '@/shared/service/utils.service'

import { makeTranslatableText } from '../../../types/TranslatableText.type'
import { type Coords, type POI, type ZOI } from './map.type'

type Position = [number, number]
type Lines = [[Position]]

type GeoJsonFeatures = {
  features: GeoJsonFeature[]
}

type Point = { type: 'Point'; coordinates: Position }
type Polygon = { type: 'Polygon'; coordinates: Lines }
type Geometry = Point | Polygon
type GeoJsonFeature = {
  geometry: Geometry
  properties: {
    name?: string
    nom?: string // specific field for Fimu
    categorie?: string // specific field for Fimu
    color?: string
    image?: string
  }
}

type GeoJsonImportResult = {
  pois: POI[]
  zois: ZOI[]
  errors: string[]
}

function isPoint(geometry: Geometry): geometry is Point {
  return geometry.type === 'Point'
}

function isPolygon(geometry: Geometry): geometry is Polygon {
  return geometry.type === 'Polygon'
}

function toCoords(position: Position): Coords {
  return { lng: position[0], lat: position[1] }
}

export const GeoJsonParser = {
  parse(
    json: string,
    idGenerator = (): string => IdsService.generateId(),
  ): GeoJsonImportResult {
    const summary: GeoJsonImportResult = { pois: [], zois: [], errors: [] }
    try {
      const result: GeoJsonFeatures = JSON.parse(json)
      result.features
        .filter(({ geometry }) => isPoint(geometry) || isPolygon(geometry))
        .reduce((result, feature) => {
          if (isPoint(feature.geometry)) {
            result.pois.push({
              coords: toCoords(feature.geometry.coordinates),
              labels: makeTranslatableText(
                feature.properties.name ??
                  feature.properties.nom ??
                  feature.properties.categorie ??
                  'sans nom',
              ),
              pic: feature.properties.image ?? 'DEFAULT',
              markerIconId: feature.properties.image ?? 'DEFAULT',
              entityType: 'NONE',
              entityId: '',
              oid: idGenerator(),
            })
          }
          if (isPolygon(feature.geometry)) {
            result.zois.push({
              zoneCorners: feature.geometry.coordinates[0].map(toCoords),
              color: feature.properties.color ?? '#4CB9DC',
              labels: makeTranslatableText(
                feature.properties.name ??
                  feature.properties.nom ??
                  feature.properties.categorie ??
                  'sans nom',
              ),
              pic: '',
              entityType: 'NONE',
              entityId: '',
              oid: idGenerator(),
            })
          }
          return result
        }, summary)
    } catch (e) {
      summary.errors.push(
        'Le fichier ne respecte pas le format GeoJson convenu.',
      )
    }
    return summary
  },
}
