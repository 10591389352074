import cloneDeep from 'lodash/cloneDeep'

import { ErrorService } from '@/shared/service/errorService'

import { i18n } from '../../i18n/i18n'
import { PAGE_MODE, type PageMode } from '../../shared/constants'
import { SnackbarService } from '../../shared/snackbar/snackbar.service'
import { type RootGetters } from '../../store'
import { createStore, type GettersObj } from '../../utils/createStore'
import { type NotificationTopic } from './notificationsTopics.service'
import { NotificationsTopicsService } from './notificationsTopics.service'

export type GettersNotificationsTopics = GettersObj<
  typeof mapGettersNotificationsTopics
>

export class NotificationsTopicsState {
  mode: PageMode = PAGE_MODE.VIEW
  notifTopics: NotificationTopic[] = []
  deleteNotifTopic: NotificationTopic | null = null
  editNotifTopic: NotificationTopic | null = null
  loading = false
}

export const {
  notificationsTopics,
  commit: commitNotificationsTopics,
  mapGetters: mapGettersNotificationsTopics,
  dispatch: dispatchNotificationsTopics,
  mapState: mapStateNotificationsTopics,
  useGetter: useGetterNotificationsTopics,
  useState: useStateNotificationsTopics,
} = createStore({
  namespaced: true,
  moduleName: 'notificationsTopics',
  initState: new NotificationsTopicsState(),
  mutations: {
    LOAD(state) {
      state.loading = true
    },

    SET_DELETING(state, payload: NotificationTopic) {
      state.mode = PAGE_MODE.DELETE
      state.deleteNotifTopic = { ...payload }
    },

    SET_VIEW(state) {
      state.mode = PAGE_MODE.VIEW
      state.deleteNotifTopic = null
      state.editNotifTopic = null
      state.loading = false
    },

    SET_ADDING(state) {
      state.mode = PAGE_MODE.ADD
      state.loading = false
    },

    SET_EDITING(state, notifTopic: NotificationTopic) {
      state.editNotifTopic = notifTopic
      state.mode = PAGE_MODE.EDIT
      state.loading = false
    },

    SET_DELETED(state) {
      if (!state.deleteNotifTopic) return
      state.deleteNotifTopic.deleted = true
    },
  },
  actions: {
    async list({ commit, state }) {
      commit('LOAD')
      const notificationsTopics = await NotificationsTopicsService.getAll()
      state.notifTopics = notificationsTopics
      state.loading = false
    },

    async delete({ commit, state, dispatch, rootGetters }) {
      commit('LOAD')
      try {
        if (!state.deleteNotifTopic?._id) return
        if (
          (rootGetters as RootGetters)['notifications/getNotifList'].some(
            (element) => {
              return element.topicId === state.deleteNotifTopic!._id
            },
          )
        ) {
          commit('SET_DELETED')
          await NotificationsTopicsService.editNotifTopic(
            state.deleteNotifTopic._id,
            state.deleteNotifTopic,
          )
        } else {
          await NotificationsTopicsService.deleteNotifTopic(
            state.deleteNotifTopic._id,
          )
        }
        await dispatch('list')
        SnackbarService.info(
          i18n.tc('NOTIFICATIONS_TOPICS.SNACK_BAR.DELETE', undefined, {
            string: state.deleteNotifTopic.names.fr,
          }),
        )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_VIEW')
      }
    },

    async addNotifTopic({ state, commit, dispatch }, topic: NotificationTopic) {
      commit('LOAD')
      try {
        if (state.editNotifTopic?._id == null) {
          await NotificationsTopicsService.addNotifTopic(topic)
          SnackbarService.info(
            i18n.tc('NOTIFICATIONS_TOPICS.SNACK_BAR.ADD', undefined, {
              string: topic.names.fr,
            }),
          )
        } else {
          await NotificationsTopicsService.editNotifTopic(
            state.editNotifTopic._id,
            topic,
          )
          SnackbarService.info(
            i18n.tc('NOTIFICATIONS_TOPICS.SNACK_BAR.EDIT', undefined, {
              string: topic.names.fr,
            }),
          )
        }
        await dispatch('list')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_VIEW')
      }
    },
  },
  getters: {
    notifTopic: (state): NotificationTopic => {
      if (state.mode !== 'edit') {
        return {
          names: {
            fr: '',
            en: '',
          },
          color: '#000000',
          selectedByDefault: true,
        }
      }
      const topic = {
        color: '#000000',
        names: { fr: '', en: '' },
        ...state.editNotifTopic,
      }
      return topic
    },

    getNotifTopics: (state): NotificationTopic[] => {
      return state.notifTopics.filter((n) => !n.deleted)
    },

    getNotificationsTopicsNotDeletedSorted: (state) => {
      const sortedTopics = cloneDeep(state.notifTopics)
      sortedTopics.sort((a, b) => a.names.fr.localeCompare(b.names.fr, 'fr'))
      const topics = [
        {
          _id: null,
          names: { fr: i18n.tc('NOTIFICATIONS.ADDING_MODAL.ALL') },
          color: 'white',
        },
        ...sortedTopics,
      ]
      return topics.filter((n) => !('deleted' in n) || !n.deleted)
    },

    getNotificationsTopics: (state) => {
      return (id: string): NotificationTopic | undefined => {
        return state.notifTopics.filter((n) => n._id === id).at(0)
      }
    },
  },
})
