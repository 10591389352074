import axios from 'axios'

import { i18n } from '@/i18n/i18n'
import {
  type ExportUrl,
  type ImportStatus,
} from '@/pages/import/store/import.type'
import { ErrorService } from '@/shared/service/errorService'
import { SnackbarService } from '@/shared/snackbar/snackbar.service'

async function updloadFile(file: string | Blob, url: string): Promise<void> {
  const data = new FormData()
  data.append('file', file)
  await axios
    .post(url, data)
    .then((_) => {
      SnackbarService.info(i18n.tc('IMPORT.SYNC_STARTED'))
    })
    .catch((err: unknown) => {
      ErrorService.handleError(err)
    })
}

export const ImportService = {
  async uploadArtist(file: string | Blob): Promise<void> {
    await updloadFile(file, '/api/bridging/heed/data')
  },

  async uploadScene(file: string | Blob): Promise<void> {
    await updloadFile(file, '/api/bridging/gc/scenes')
  },

  async uploadEvent(file: string | Blob): Promise<void> {
    await updloadFile(file, '/api/bridging/gc/events')
  },

  async uploadHeeds(file: string | Blob): Promise<void> {
    await updloadFile(file, '/api/bridging/heeds')
  },

  async syncHeeds(clean: boolean): Promise<void> {
    await axios
      .post('/api/bridging/heeds/sync', { clean })
      .then((_) => {
        SnackbarService.info(i18n.tc('IMPORT.SYNC_STARTED'))
      })
      .catch((err: unknown) => {
        ErrorService.handleError(err)
      })
  },

  async sync(urls: ExportUrl[], clean: boolean): Promise<void> {
    await axios
      .post('/api/bridging/gc/urls', { urls, clean }, { timeout: 240000 })
      .then((_) => {
        SnackbarService.info(i18n.tc('IMPORT.SYNC_SUCCESS'))
      })
      .catch((err: unknown) => {
        ErrorService.handleError(err)
      })
  },

  async getLastImportStatus(): Promise<ImportStatus> {
    const { data } = await axios.get('/api/bridging/status')
    return data
  },
}
