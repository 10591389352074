import type { TranslatableText } from './../../../types/TranslatableText.type'

export class GalleryItem {
  _id = ''
  titles: TranslatableText = {
    en: '',
    fr: '',
  }
  thumbnailImage = ''
  image = ''
  weight = -1
  visible = false
}
