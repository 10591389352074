<template>
  <modal
    max-width="500px"
    :title="$tc('GENERAL.CONFIRMATION')"
    btn-ok-color="success"
    :btn-ok-label="$tc('GENERAL.CONFIRMATION_BUTTON')"
    @okAction="$emit('confirmAction')"
    @cancelAction="$emit('cancelAction')"
  >
    {{ message }}
  </modal>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    message: {
      type: String,
      required: true,
    },
  },
})
</script>
