import axios from 'axios'

import { computeFakeTitle } from '@/pages/progEvent/progEvent.service'

import { weightService } from '../../shared/service/utils.service'
import { type ProgEvent } from '../../shared/types/progEvent.type'
import { type Scene } from '../../shared/types/scene.type'

export const ScenesService = {
  async getAll(): Promise<Scene[]> {
    const { data } = await axios.get('/api/scenes')
    return weightService.formatWeight(data)
  },

  async deleteScene(id: string): Promise<void> {
    await axios.delete(`/api/scenes/${id}`)
  },

  async addScene(scene: Scene): Promise<Scene> {
    const { data } = await axios.post('/api/scenes', scene)
    return data
  },

  async updateScene(scene: Scene): Promise<Scene> {
    const { data } = await axios.put(`/api/scenes/${scene._id}`, scene)
    return data
  },

  async getSceneById(sceneId: string): Promise<Scene | null> {
    const { data } = await axios.get(`/api/scenes/${sceneId}`)
    return data
  },

  async getSceneEvents(sceneId: string): Promise<ProgEvent[]> {
    const { data } = await axios.get(`/api/scenes/events/${sceneId}`)
    return eventsToJsFormat(data)
  },

  async getSceneMarkers(sceneId: string): Promise<
    {
      _id: string
      name: string
    }[]
  > {
    const { data } = await axios.get(`/api/scenes/markers/${sceneId}`)
    return data
  },
}

function eventsToJsFormat(data: ProgEvent[]): ProgEvent[] {
  data.forEach((element) => {
    element.fakeTitle = computeFakeTitle(element)
  })
  return data
}
