import axios from 'axios'

import { type ScreenTableDateDto } from '@/pages/analytics/models/analytics.type'
import { type AdvertisementDto } from '@/pages/analytics/models/analytics-advertisement.type'
import { type AnalyticsNotification } from '@/pages/analytics/models/analytics-notification.type'
import {
  type UserCountryDto,
  type UserSectionDashboardDto,
  type UserSessionPeriodDto,
} from '@/pages/analytics/models/analytics-user.type'

const baseUrl = 'api/analytics'

export const AnalyticsService = {
  async getUsersNumber(
    startDate: string,
    endDate: string,
  ): Promise<Record<string, number>> {
    const { data } = await axios.get(`${baseUrl}/users`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getUsersSectionDashboardMetrics(
    startDate: string,
    endDate: string,
  ): Promise<UserSectionDashboardDto> {
    const { data } = await axios.get(`${baseUrl}/users/dashboard`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getUsersLocation(
    startDate: string,
    endDate: string,
  ): Promise<UserCountryDto[]> {
    const { data } = await axios.get(`${baseUrl}/users/location`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getUsersSessions(
    startDate: string,
    endDate: string,
  ): Promise<Record<string, UserSessionPeriodDto>> {
    const { data } = await axios.get(`${baseUrl}/users/sessions`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getScheduleMetrics(
    startDate: string,
    endDate: string,
  ): Promise<ScreenTableDateDto[]> {
    const { data } = await axios.get(`${baseUrl}/schedules`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getEventsNumber(
    startDate: string,
    endDate: string,
  ): Promise<ScreenTableDateDto[]> {
    const { data } = await axios.get(`${baseUrl}/programevents`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getFavoriteEventMetrics(
    startDate: string,
    endDate: string,
  ): Promise<ScreenTableDateDto[]> {
    const { data } = await axios.get(`${baseUrl}/programevents/favorites`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getCommentedEventMetrics(
    startDate: string,
    endDate: string,
  ): Promise<ScreenTableDateDto[]> {
    const { data } = await axios.get(`${baseUrl}/programevents/comments`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getRatedEventMetrics(
    startDate: string,
    endDate: string,
  ): Promise<ScreenTableDateDto[]> {
    const { data } = await axios.get(`${baseUrl}/programevents/ratings`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getParticipatedEventMetrics(
    startDate: string,
    endDate: string,
  ): Promise<ScreenTableDateDto[]> {
    const { data } = await axios.get(
      `${baseUrl}/programevents/participations`,
      {
        params: {
          startDate,
          endDate,
        },
      },
    )
    return data
  },

  async getGuestsNumber(
    startDate: string,
    endDate: string,
  ): Promise<Record<string, ScreenTableDateDto[]>> {
    const { data } = await axios.get(`${baseUrl}/guests`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getFavoriteGuestMetrics(
    startDate: string,
    endDate: string,
  ): Promise<Record<string, ScreenTableDateDto>> {
    const { data } = await axios.get(`${baseUrl}/guests/favorites`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getGuestListMetrics(
    startDate: string,
    endDate: string,
  ): Promise<ScreenTableDateDto[]> {
    const { data } = await axios.get(`${baseUrl}/guests/lists`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getGalleryNumber(
    startDate: string,
    endDate: string,
  ): Promise<ScreenTableDateDto[]> {
    const { data } = await axios.get(`${baseUrl}/gallery`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getInfoPratiqueMetrics(
    startDate: string,
    endDate: string,
  ): Promise<ScreenTableDateDto[]> {
    const { data } = await axios.get(`${baseUrl}/infoPratiques`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getWebviewMetrics(
    startDate: string,
    endDate: string,
  ): Promise<ScreenTableDateDto[]> {
    const { data } = await axios.get(`${baseUrl}/webview`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getAdvertisementMetrics(
    startDate: string,
    endDate: string,
  ): Promise<AdvertisementDto> {
    const { data } = await axios.get(`${baseUrl}/commercials`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getNotificationMetrics(
    startDate: string,
    endDate: string,
  ): Promise<AnalyticsNotification[]> {
    const { data } = await axios.get(`${baseUrl}/notifications`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async getOtherScreenMetrics(
    startDate: string,
    endDate: string,
  ): Promise<ScreenTableDateDto[]> {
    const { data } = await axios.get(`${baseUrl}/otherScreen`, {
      params: {
        startDate,
        endDate,
      },
    })
    return data
  },

  async init(): Promise<void> {
    await axios.post(`${baseUrl}/init`)
  },
}
