import { type RouteConfig } from 'vue-router'

import { dispatchInfos } from './infos.store'

export const InfosRouter: RouteConfig[] = [
  {
    path: '/infosPratiques',
    component: async () => import('./Infos.vue'),
  },
  {
    path: '/infosPratiques/create',
    component: async () => import('./AddingInfos.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await dispatchInfos('loadInfo', undefined)
      next()
    },
  },
  {
    path: '/infosPratiques/:id',
    component: async () => import('./AddingInfos.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await dispatchInfos('loadInfo', to.params.id)
      next()
    },
  },
]
