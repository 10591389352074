import axios from 'axios'

import { type StaticConfig } from './staticCfg.types'

export const StaticConfigService = {
  async getAll(): Promise<StaticConfig[]> {
    const { data } = await axios.get<StaticConfig[]>('/api/staticConfig/all')
    return data
  },

  async get(id: string): Promise<StaticConfig | null> {
    const { data } = await axios.get<StaticConfig | null>(
      `/api/staticConfig/${id}`,
    )
    return data
  },

  async delete(id: string): Promise<void> {
    await axios.delete(`/api/staticConfig/${id}`)
  },

  async save(config: StaticConfig): Promise<StaticConfig> {
    const { data } = await axios.put('/api/staticConfig', config)
    return data
  },
}
