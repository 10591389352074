import axios from 'axios'

import { type Genre } from './store/guestgenres.store'

export const GenresService = {
  async getAll(): Promise<Genre[]> {
    const { data } = await axios.get('/api/genres')
    return data
  },

  async addGenre(genre: Omit<Genre, '_id'>): Promise<Genre> {
    const { data } = await axios.post('/api/genres', genre)
    return data
  },

  async updateGenre(genre: Genre): Promise<Genre> {
    const { data } = await axios.put(`/api/genres/${genre._id}`, genre)
    return data
  },

  async deleteGenre(genreId: string): Promise<void> {
    await axios.delete(`/api/genres/${genreId}`)
  },
}
