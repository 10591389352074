import axios from 'axios'

export const ResetAppService = {
  async resetApp(fullReset: boolean): Promise<void> {
    await axios.post('/api/admin/reset', { fullReset })
  },
  async resetMenu(): Promise<void> {
    await axios.post('/api/reinitMenuEntry')
  },
}
