import { type RouteConfig } from 'vue-router'

import { dispatchGuestType } from '../guestTypes/guestType.store'
import { dispatchPrograms } from '../programs/programs.store'
import { dispatchScenes } from '../scenes/scenes.store'
import { commitGuests, dispatchGuests } from './store/guests.store'

export const GuestsRouter: RouteConfig[] = [
  {
    path: '/guests',
    component: async () => import('./Guests.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      if (!from.path.startsWith('/guests')) {
        commitGuests('RESET_PAGINATION')
      }
      await dispatchGuestType('list')
      next()
    },
    children: [],
  },
  {
    path: '/guests/create',
    component: async () => import('./AddingGuests.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await Promise.all([
        dispatchGuests('getGuestsLinks'),
        dispatchGuestType('list'),
        dispatchScenes('list'),
        dispatchPrograms('list'),
      ])
      next()
    },
  },
  {
    path: '/guests/import',
    component: async () => import('./GuestImport.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await Promise.all([
        dispatchGuests('resetImport'),
        dispatchGuestType('list'),
      ])
      next()
    },
  },
  {
    path: '/guests/:id',
    component: async () => import('./AddingGuests.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await Promise.all([
        dispatchGuests('loadGuest', to.params.id),
        dispatchGuestType('list'),
        dispatchScenes('list'),
        dispatchPrograms('list'),
      ])
      next()
    },
  },
]
