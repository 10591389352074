import { ErrorService } from '@/shared/service/errorService'

import { i18n } from '../../i18n/i18n'
import { PAGE_MODE, type PageMode } from '../../shared/constants'
import { SnackbarService } from '../../shared/snackbar/snackbar.service'
import { type Attendee } from '../../shared/types/attendee.type'
import { createStore, type GettersObj } from '../../utils/createStore'
import { AttendeesService } from './attendees.service'

export type GettersAttendee = GettersObj<typeof mapGettersAttendee>
export class AttendeeState {
  mode: PageMode = PAGE_MODE.VIEW
  attendees: Attendee[] = []
  currentAttendee: null | Attendee = null
  pagination = {
    sortBy: ['lastName'],
    sortDesc: [true],
    itemsPerPage: 25,
    page: 1,
    totalItems: 0,
  }
  filter = {
    search: '',
    onlyReported: false,
  }
  loading = false
}

export const {
  attendee,
  commit: commitAttendee,
  dispatch: dispatchAttendee,
  mapGetters: mapGettersAttendee,
  mapState: mapStateAttendee,
  useGetter: useGetterAttendee,
  useState: useStateAttendee,
} = createStore({
  moduleName: 'attendee',
  namespaced: true,
  initState: new AttendeeState(),
  mutations: {
    LOAD(state) {
      state.loading = true
    },
    SET_ATTENDEES(
      state,
      { attendees, total }: { attendees: Attendee[]; total: number },
    ) {
      state.attendees = attendees
      state.pagination.totalItems = total
      state.loading = false
    },
    SET_PAGINATION(state, pagination: AttendeeState['pagination']) {
      state.pagination = pagination
    },
    SET_FILTER(state, filter: AttendeeState['filter']) {
      state.filter = filter
    },
    SET_EDITING(state, payload: Attendee) {
      state.mode = PAGE_MODE.EDIT
      state.currentAttendee = { ...payload }
    },
    SET_DELETING(state, payload: Attendee) {
      state.mode = PAGE_MODE.DELETE
      state.currentAttendee = { ...payload }
    },
    SET_VIEW(state) {
      state.mode = PAGE_MODE.VIEW
      state.loading = false
      state.currentAttendee = null
    },
    UPDATE_ATTENDEE(state, payload: Partial<Attendee>) {
      state.currentAttendee = { ...state.currentAttendee!, ...payload }
    },
    SET_DISABLE(state, payload: Attendee) {
      state.mode = PAGE_MODE.DISABLE
      state.currentAttendee = { ...payload }
    },
    SET_ENABLE(state, payload: Attendee) {
      state.mode = PAGE_MODE.ENABLE
      state.currentAttendee = { ...payload }
    },
  },
  getters: {},
  actions: {
    async list(
      { commit, state },
      payload: {
        filter?: AttendeeState['filter']
        pagination?: AttendeeState['pagination']
      } = {},
    ) {
      commit('LOAD')
      if (payload && payload.pagination) {
        commit('SET_PAGINATION', {
          ...state.pagination,
          ...payload.pagination,
        })
      }
      if (payload && payload.filter) {
        commit('SET_FILTER', { ...state.filter, ...payload.filter })
      }
      const { sortBy, sortDesc, page, itemsPerPage } = state.pagination
      const { attendees, total } = await AttendeesService.getAll(
        state.filter.search,
        page,
        itemsPerPage,
        sortBy[0],
        sortDesc[0],
        state.filter.onlyReported,
      )
      commit('SET_ATTENDEES', { attendees, total })
    },

    async save({ commit, dispatch, state }) {
      commit('LOAD')
      try {
        if (!state.currentAttendee) {
          return
        }
        await AttendeesService.update(state.currentAttendee)
        await dispatch('list')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_VIEW')
      }
    },

    async delete({ commit, state }) {
      commit('LOAD')
      try {
        if (!state.currentAttendee) {
          return
        }
        await AttendeesService.delete(state.currentAttendee._id)
        commit('SET_ATTENDEES', {
          attendees: state.attendees.filter(
            (item) => item._id !== state.currentAttendee!._id,
          ),
          total: state.pagination.totalItems - 1,
        })
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_VIEW')
      }
    },

    async toggle({ commit, state }) {
      commit('LOAD')
      try {
        if (!state.currentAttendee) {
          return
        }
        await AttendeesService.toggle(
          state.currentAttendee._id,
          state.currentAttendee.disabled,
        )
        commit('SET_ATTENDEES', {
          attendees: state.attendees.map((item) => {
            if (item._id !== state.currentAttendee!._id) return item
            return {
              ...state.currentAttendee,
              disabled: !state.currentAttendee!.disabled,
            }
          }),
          total: state.pagination.totalItems,
        })
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_VIEW')
      }
    },
    async resetPwd({}, attendee: Attendee) {
      try {
        await AttendeesService.resetPwd(attendee._id)
        SnackbarService.info(i18n.tc('ATTENDEE.CONFIRMATION.RESET_PWD'))
      } catch (e) {
        ErrorService.handleError(e)
      }
    },
  },
})
