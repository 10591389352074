import axios from 'axios'

import { type DashboardState } from './dashboard.store'

export const DashboardService = {
  async loadDashboard(): Promise<Omit<typeof DashboardState, 'showModal'>> {
    const { data } = await axios.get('/api/dashboard')
    return data
  },
}
