import { default as axios } from 'axios'

import { GuestsService } from '@/pages/guests/guests.service'
import { ScenesService } from '@/pages/scenes/scenes.service'
import { type MusicGroup } from '@/shared/types/musicGroup/musicGroup.type'

import { type TranslatableText } from '../../../types/TranslatableText.type'
import { type MapObject } from './map.type'

export const MapService = {
  async getSceneList(): Promise<{ names: TranslatableText; _id: string }[]> {
    return await ScenesService.getAll()
  },

  async getGuestList(): Promise<MusicGroup[]> {
    return await GuestsService.getAll()
  },

  async getMapList(): Promise<MapObject[]> {
    const { data } = await axios.get(`/api/maps`)
    return data
  },

  async getMapById(id: string): Promise<MapObject> {
    const { data } = await axios.get(`/api/maps/${id}`)
    return data
  },

  async saveMap(payload: MapObject): Promise<MapObject> {
    const { data } = await axios.post(`api/maps`, payload)
    return data
  },

  async deleteMap(id: string): Promise<void> {
    await axios.delete(`api/maps/${id}`)
    return
  },
}
