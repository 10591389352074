import { type RouteConfig } from 'vue-router'

import { dispatchApplicationCfg } from '../applicationCfg/store/applicationCfg.store'
import { dispatchFestival } from '../festival/festival.store'
import { dispatchGuestType } from '../guestTypes/guestType.store'
import { dispatchPrograms } from '../programs/programs.store'
import { dispatchStoresInformations } from '../storesInformations/storesInformations.store'
import { dispatchStaticCfgs } from './store/staticCfgs.store'
import { dispatchStaticConfig } from './store/staticConfig.store'

export const StaticCfgRouter: RouteConfig[] = [
  {
    path: '/staticCfgs',
    component: async () => import('./staticCfgList.vue'),
    async beforeEnter(from, to, next) {
      await dispatchStaticCfgs('list')
      next()
    },
    children: [],
  },
  {
    path: '/staticCfg/create',
    component: async () => import('./staticCfg.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await dispatchApplicationCfg('loadApplicationCfg')
      await Promise.all([
        dispatchStaticConfig('createStaticConfig'),
        dispatchStoresInformations('loadAppStore'),
        dispatchFestival('loadFestival'),
        dispatchPrograms('list'),
        dispatchGuestType('list'),
      ])
      next()
    },
  },
  {
    path: '/staticCfg/:id',
    component: async () => import('./staticCfg.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await Promise.all([
        dispatchStaticConfig('loadStaticConfig', { oid: to.params.id }),
        dispatchStoresInformations('loadAppStore'),
        dispatchFestival('loadFestival'),
        dispatchApplicationCfg('loadApplicationCfg'),
        dispatchPrograms('list'),
        dispatchGuestType('list'),
      ])
      next()
    },
  },
]
