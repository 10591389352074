import axios from 'axios'

import { type SponsorCategory } from '../../../shared/types/sponsor.type'

export const SponsorsCategoriesService = {
  async getAll(): Promise<SponsorCategory[]> {
    const { data } = await axios.get('/api/sponsorsCategories')
    return data
  },

  async updateSponsorCategory(
    sponsorCategory: SponsorCategory,
  ): Promise<SponsorCategory> {
    const { data } = await axios.put(
      `/api/sponsorsCategories/${sponsorCategory._id}`,
      sponsorCategory,
    )
    return data
  },

  async deleteSponsorCategory(id: string): Promise<void> {
    await axios.delete(`/api/sponsorsCategories/${id}`)
  },

  async addSponsorCategory(
    sponsorCategory: SponsorCategory,
  ): Promise<SponsorCategory> {
    const { data } = await axios.post(
      '/api/sponsorsCategories',
      sponsorCategory,
    )
    return data
  },

  async getSponsorCategoryById(id: string): Promise<SponsorCategory | null> {
    const { data } = await axios.get(`/api/sponsorsCategories/${id}`)
    return data
  },

  async moveSponsorCategory(id: string, upDown: number): Promise<void> {
    const direction = upDown === 1 ? 'up' : 'down'
    await axios.put(`/api/sponsorsCategories/${id}/${direction}`)
  },
}
