import axios from 'axios'

import { type Attendee } from '../../shared/types/attendee.type'

export const AttendeesService = {
  async getAll(
    query: string,
    page: number,
    itemsPerPage: number,
    sortBy: string,
    desc: boolean,
    onlyReported: boolean,
  ): Promise<{ attendees: Attendee[]; total: number }> {
    const { data } = await axios.get('/api/attendee', {
      params: {
        query,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        order: sortBy,
        desc,
        onlyReported: !!onlyReported,
      },
    })
    return data
  },

  async delete(id: string): Promise<void> {
    await axios.delete('/api/attendee/' + id)
  },

  async toggle(id: string, enable: boolean): Promise<void> {
    if (enable) {
      await axios.put('/api/attendee/' + id + '/enable')
    } else {
      await axios.put('/api/attendee/' + id + '/disable')
    }
  },

  async update(attendee: Attendee): Promise<Attendee> {
    const { data } = await axios.post('/api/attendee', attendee)
    return data
  },

  async resetPwd(attendeeId: string): Promise<void> {
    await axios.post(`/api/attendee/resetpassword/${attendeeId}`)
  },

  async getAllByFirebaseUserIds(
    firebaseUserIds: string[],
  ): Promise<Attendee[]> {
    const { data } = await axios.post(
      '/api/attendee/listByFirebaseUserId',
      firebaseUserIds,
    )
    return data
  },
}
