import { type RouteConfig } from 'vue-router'

import { dispatchAds } from './ads.store'

export const AdsRouter: RouteConfig[] = [
  {
    path: '/ads',
    component: async () => import('./ads.vue'),
    async beforeEnter(to, _from, next): Promise<void> {
      await dispatchAds('loadApplicationCfg')
      next()
    },
  },
]
