import { ErrorService } from '@/shared/service/errorService'

import { i18n } from '../../i18n/i18n'
import { SnackbarService } from '../../shared/snackbar/snackbar.service'
import { type GEDFile } from '../../shared/types/files.type'
import { createStore, type GettersObj } from '../../utils/createStore'
import { OrphanedService } from './orphaned.service'

export type GettersOrphaned = GettersObj<typeof mapGettersOrphaned>

export class OrphanedState {
  orphanedFiles: Record<string, GEDFile> = {}
  loading = false
  deleting: false | { imageId: string } | 'ALL' = false
}

export const {
  orphaned,
  commit: commitOrphaned,
  dispatch: dispatchOrphaned,
  mapState: mapStateOrphaned,
  useGetter: useGetterOrphaned,
  useState: useStateOrphaned,
  mapGetters: mapGettersOrphaned,
} = createStore({
  namespaced: true,
  moduleName: 'orphaned',
  initState: new OrphanedState(),
  mutations: {
    LOAD(state) {
      state.loading = true
    },

    UNLOAD(state) {
      state.loading = false
    },

    SET_FILES(state, files: GEDFile[]) {
      state.orphanedFiles = files.reduce<Record<string, GEDFile>>(
        (acc, file) => {
          acc[file._id] = file
          return acc
        },
        {},
      )
    },

    SET_DELETING_ALL(state) {
      state.deleting = 'ALL'
    },

    SET_DELETING(state, imageId: string) {
      state.deleting = { imageId }
    },

    CANCEL_DELETING(state) {
      state.deleting = false
    },
  },
  actions: {
    async list({ commit }) {
      try {
        commit('LOAD')
        const files = await OrphanedService.getAll()
        commit('SET_FILES', files)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async delete({ commit, dispatch, state }) {
      try {
        commit('LOAD')

        if (state.deleting === 'ALL') {
          await OrphanedService.deleteAll()
          SnackbarService.info(i18n.tc('ORPHANED.ALL_DELETED'))
        } else if (state.deleting !== false) {
          await OrphanedService.delete(state.deleting.imageId)
          SnackbarService.info(i18n.tc('ORPHANED.DELETED'))
        }
        await dispatch('list')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('CANCEL_DELETING')
        commit('UNLOAD')
      }
    },
  },
  getters: {
    getOrphanedFiles: (state) => {
      return Object.values(state.orphanedFiles)
    },
  },
})
