import { ErrorService } from '@/shared/service/errorService'

import { createStore, type GettersObj } from '../../utils/createStore'
import { DashboardService } from './dashboard.service'

export type GettersDashboard = GettersObj<typeof mapGettersDashboard>

export class DashboardState {
  nbMusicGroup = 0
  nbScenes = 0
  nbPrograms = 0
  nbEvents = 0
  nbSponsors = 0
  nbPOI = 0
  nbNotifications = 0
  nbInfos = 0

  showModal = false
}

export const {
  dashboard,
  mapGetters: mapGettersDashboard,
  commit: commitDashboard,
  dispatch: dispatchDashboard,
  mapState: mapStateDashboard,
  useState: useStateDashboard,
  useGetter: useGetterDashboard,
} = createStore({
  namespaced: true,
  moduleName: 'dashboard',
  initState: new DashboardState(),
  getters: {},
  actions: {
    async loadDashboard({ commit }) {
      try {
        const data = await DashboardService.loadDashboard()
        commit('SET_NUMBERS', data)
      } catch (e) {
        ErrorService.handleError(e)
      }
    },
  },

  mutations: {
    SET_NUMBERS(state: DashboardState, data) {
      state.nbMusicGroup = data.nbMusicGroup
      state.nbScenes = data.nbScenes
      state.nbPrograms = data.nbPrograms
      state.nbEvents = data.nbEvents
      state.nbSponsors = data.nbSponsors
      state.nbPOI = data.nbPOI
      state.nbNotifications = data.nbNotifications
      state.nbInfos = data.nbInfos
    },

    SHOW_MODAL(state: DashboardState) {
      state.showModal = true
    },

    HIDE_MODAL(state: DashboardState) {
      state.showModal = false
    },
  },
})
