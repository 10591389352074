<template>
  <v-app v-resize="onResize">
    <v-overlay v-if="isConnecting" color="#eeeeee" opacity="1">
      <v-progress-circular
        class="spinner"
        :size="140"
        :width="7"
        color="#3ABBDB"
        indeterminate
      />
    </v-overlay>
    <ChapiMenu v-model="drawer" v-if="showMenu" />
    <v-main class="content-background">
      <div class="menu-button-wrapper" v-if="showMenu">
        <div class="menu-button" @click="setDrawer">
          <v-icon class="menu-button-icon" dark>{{
            drawer ? 'keyboard_arrow_left' : 'keyboard_arrow_right'
          }}</v-icon>
        </div>
      </div>
      <Snackbar />
      <WarningBanner v-if="showMenu" />
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import utc from 'dayjs/plugin/utc'
import Vue, { defineComponent } from 'vue'

import ChapiMenu from './components/Menu.vue'
import { publicRoutes } from './router'
import WarningBanner from './shared/components/WarningBanner.vue'
import Snackbar from './shared/snackbar/SnackBar.vue'
import { mapGettersAppConfig } from './shared/store/app-config.store'
import { commitIsMobile } from './shared/store/isMobile.store'
import { mapGettersSession } from './shared/store/session.store'
import store from './store'

dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(localizedFormat)
dayjs.locale('fr')
export default defineComponent({
  name: 'App',
  components: {
    ChapiMenu,
    WarningBanner,
    Snackbar,
  },
  data: () => ({
    drawer: true,
  }),

  mounted() {
    dayjs.tz.setDefault(this.getTimezone)
    Vue.prototype.$dayjs = dayjs
    store.watch(
      (state) => state.appConfig.timezone,
      (newValue: string) => {
        dayjs.tz.setDefault(newValue)
        Vue.prototype.$dayjs = dayjs
      },
    )
  },

  computed: {
    ...mapGettersAppConfig(['getTimezone']),
    ...mapGettersSession(['isConnecting', 'isConnected']),
    showMenu() {
      return (
        this.isConnected &&
        !this.isConnecting &&
        ![...publicRoutes, '/login'].includes(this.$route.path)
      )
    },
  },

  methods: {
    setDrawer() {
      this.drawer = !this.drawer
    },

    onResize() {
      if (window.innerWidth < 769) {
        commitIsMobile('SET_IS_MOBILE')
      } else if (window.innerWidth < 1264) {
        commitIsMobile('SET_IS_SMALL_LAPTOP')
      } else {
        commitIsMobile('SET_IS_DEFAULT')
      }
    },
  },
})
</script>
<style lang="scss" scoped>
.v-application {
  font-family: 'Montserrat', 'Helvetica Neue', Arial, sans-serif;
}

.content-background {
  background-color: #eeeeee;
}

.menu-button {
  position: absolute;
  overflow: hidden;
  border-radius: 0 0 100% 0;
  height: 25px;
  background-color: #0f708a;
  width: 25px;
  cursor: pointer;
  top: 0;
}

.menu-button-wrapper {
  position: sticky;
  height: 0px;
  width: 0px;
  left: 0px;
  top: 0px;
  z-index: 6;
}

.menu-button-icon {
  margin: -6px 0 0 -3px;
  cursor: pointer;
}

.spinner {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  text-align: center;
}
</style>
