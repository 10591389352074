import cloneDeep from 'lodash/cloneDeep'

import { i18n } from '@/i18n/i18n'
import { ErrorService } from '@/shared/service/errorService'
import { SnackbarService } from '@/shared/snackbar/snackbar.service'

import { IdsService } from '../../../shared/service/utils.service'
import { type SponsorCategory } from '../../../shared/types/sponsor.type'
import { createStore, type GettersObj } from '../../../utils/createStore'
import { dispatchSponsors } from '../sponsors.store'
import { SponsorsCategoriesService } from './sponsorsCategories.service'

export type GettersSponsorsCategories = GettersObj<
  typeof mapGettersSponsorsCategories
>

export class SponsorsCategoriesState {
  loading = false
  sponsorsCategories: Record<string, SponsorCategory> = {}
  deletingSponsorCategoryId: string | null = null
  editingSponsorCategoryId: string | null = null
  showModal = false

  //comboBox
  selectedCategory: SponsorCategory | null = null
  categoriesSearch = ''
  addedCategories: Record<string, SponsorCategory> = {}
  deletedCategoriesIds: string[] = []
}

export const {
  sponsorsCategories,
  mapState: mapStateSponsorsCategories,
  commit: commitSponsorsCategories,
  useGetter: useGetterSponsorsCategories,
  useState: useStateSponsorsCategories,
  dispatch: dispatchSponsorsCategories,
  mapGetters: mapGettersSponsorsCategories,
} = createStore({
  namespaced: true,
  moduleName: 'sponsorsCategories',
  initState: new SponsorsCategoriesState(),
  mutations: {
    LOAD(state) {
      state.loading = true
    },

    UNLOAD(state) {
      state.loading = false
    },

    SET_NOT_DELETING(state) {
      state.deletingSponsorCategoryId = null
    },

    SET_SPONSORS_CATEGORIES(state, sponsorsCategories: SponsorCategory[]) {
      state.sponsorsCategories = sponsorsCategories.reduce<
        Record<string, SponsorCategory>
      >((acc, sponsorCategory) => {
        if (!sponsorCategory._id) return acc
        acc[sponsorCategory._id] = sponsorCategory
        return acc
      }, {})
    },

    SET_DELETING(state, id: string) {
      state.deletingSponsorCategoryId = id
    },

    OPEN_ADD_SPONSOR_CATEGORY(state) {
      state.showModal = true
    },

    SET_EDITING(state, id: string) {
      state.editingSponsorCategoryId = id
    },

    RESET_EDITING(state) {
      state.editingSponsorCategoryId = null
    },

    //comboBox
    RESET_BOX(state) {
      state.selectedCategory = null
      state.deletedCategoriesIds = []
      state.addedCategories = {}
      state.categoriesSearch = ''
    },

    DELETE_CATEGORY(state, category: SponsorCategory) {
      if (!category._id) return
      if (category._id in state.addedCategories) {
        delete state.addedCategories[category._id]
        state.addedCategories = { ...state.addedCategories }
      } else {
        state.deletedCategoriesIds.push(category._id)
        delete state.sponsorsCategories[category._id]
        state.sponsorsCategories = { ...state.sponsorsCategories }
      }
    },

    CLEAR_SELECTED_CATEGORY(state) {
      state.selectedCategory = null
    },

    SET_CATEGORIES_SELECTION(
      state,
      _category: string | SponsorCategory | null,
    ) {
      if (_category == null) return

      let category: SponsorCategory =
        typeof _category === 'string'
          ? {
              name: _category,
              nameNormalized: _category.toLowerCase(),
              _id: undefined,
              weight: 100,
            }
          : _category

      const tmpCategories = Object.values(state.sponsorsCategories).filter(
        (n) => n.nameNormalized === category.nameNormalized,
      )
      if (tmpCategories.length > 0) {
        ;[category] = tmpCategories
      } else if (
        Object.values(state.sponsorsCategories).filter(
          (n) => n.nameNormalized === category.nameNormalized,
        ).length === 0 &&
        Object.values(state.addedCategories).filter(
          (n) => n.nameNormalized === category.nameNormalized,
        ).length === 0
      ) {
        category._id = IdsService.generateId()
        state.addedCategories[category._id] = category
      }

      state.selectedCategory = category
      state.categoriesSearch = ''
    },

    SET_CATEGORIES_SEARCH(state, categoriesSearch: string) {
      state.categoriesSearch = categoriesSearch
    },
  },
  actions: {
    async list({ commit }) {
      commit('LOAD')
      try {
        const sponsorsCategories = await SponsorsCategoriesService.getAll()
        commit('SET_SPONSORS_CATEGORIES', sponsorsCategories)
        commit('UNLOAD')
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    async deleteSponsorCategory({ commit, state, dispatch }) {
      commit('LOAD')
      try {
        if (!state.deletingSponsorCategoryId) {
          return
        }
        await SponsorsCategoriesService.deleteSponsorCategory(
          state.deletingSponsorCategoryId,
        )
        SnackbarService.info(
          i18n.tc('SPONSORS_CATEGORIES.SNACK_BAR.DELETE', undefined, {
            string:
              state.sponsorsCategories[state.deletingSponsorCategoryId].name,
          }),
        )
        commit('SET_NOT_DELETING')
        await dispatch('list')
        await dispatchSponsors('list')
        commit('UNLOAD')
      } catch (e) {
        ErrorService.handleError(e)
        commit('UNLOAD')
      }
    },

    async addSponsorCategory(
      { commit, state, dispatch },
      sponsorCategory: SponsorCategory,
    ) {
      commit('LOAD')
      try {
        let message = ''
        if (state.editingSponsorCategoryId) {
          await SponsorsCategoriesService.updateSponsorCategory(sponsorCategory)
          message = i18n.tc('SPONSORS_CATEGORIES.SNACK_BAR.EDIT', undefined, {
            string: sponsorCategory.name,
          })
        } else {
          await SponsorsCategoriesService.addSponsorCategory(sponsorCategory)
          message = i18n.tc('SPONSORS_CATEGORIES.SNACK_BAR.ADD', undefined, {
            string: sponsorCategory.name,
          })
        }
        SnackbarService.info(message)
        await dispatch('list')
        await dispatchSponsors('list')
        commit('RESET_EDITING')
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    async updateSponsorsCategories({ state, commit, dispatch }) {
      commit('LOAD')
      try {
        const values = cloneDeep(Object.values(state.addedCategories))
        await Promise.all(
          values.map(async (item) => {
            delete item._id
            return await SponsorsCategoriesService.addSponsorCategory(item)
          }),
        )

        await Promise.all(
          state.deletedCategoriesIds.map(async (id) => {
            return SponsorsCategoriesService.deleteSponsorCategory(id)
          }),
        )
        await dispatch('list')
      } catch (e) {
        ErrorService.handleError(e)
      }
    },
  },
  getters: {
    getSponsorsCategories: (state) => {
      return Object.values(state.sponsorsCategories)
    },

    getSponsorCategory: (state) => {
      return state.editingSponsorCategoryId
        ? state.sponsorsCategories[state.editingSponsorCategoryId]
        : {
            name: '',
          }
    },

    getFilteredCategories: (state) => {
      const categoriesTab = [
        ...Object.values(state.sponsorsCategories),
        ...Object.values(state.addedCategories),
      ]

      return state.categoriesSearch && state.categoriesSearch.length > 0
        ? [
            ...categoriesTab,
            {
              name: state.categoriesSearch,
              nameNormalized: state.categoriesSearch.toLowerCase(),
              _id: null,
            },
          ]
        : categoriesTab
    },
  },
})
