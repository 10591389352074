import { type RouteConfig } from 'vue-router'

export const AttendeesWhiteListRouter: RouteConfig[] = [
  {
    path: '/attendee-white-list-create',
    component: async () => import('./AttendeeWhiteListCreate.vue'),
  },
  {
    path: '/attendee-white-list',
    component: async () => import('./AttendeeWhiteListList.vue'),
  },
]
