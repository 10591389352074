import axios from 'axios'
import qs from 'querystring'
import SparkMD5 from 'spark-md5'

import { type PrincipalDto } from '../store/session.store'

export const SessionService = {
  async getSession(): Promise<{ principal: PrincipalDto; key: string }> {
    const { data } = await axios.get(`/api/session/current`)
    return data
  },

  async login(
    username: string,
    password: string,
  ): Promise<{ principal: PrincipalDto; key: string }> {
    const principal = {
      username,
      password: SparkMD5.hash(password),
    }
    const { data } = await axios.post(
      '/api/sessions',
      qs.stringify(principal),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    return data
  },

  async logout(): Promise<void> {
    await axios.post(`/api/logout`)
  },
}
