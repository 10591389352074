import axios from 'axios'

import { type TranslatableText } from '../../../types/TranslatableText.type'

export type DeeplinkTarget =
  | 'home'
  | 'showMan'
  | 'show'
  | 'program'
  | 'sponsors'
  | 'practicalInfo'
  | 'map'
  | 'notifications'
  | 'webview'
  | 'extern'
  | 'gallery'
  | 'showmen'
  | 'practicalInfos'

export type NotificationContent = {
  _id?: string
  dateToSend: string
  titles: TranslatableText
  contents: TranslatableText
  author?: string
  deeplinkBase: DeeplinkTarget
  deeplinkParam: string
  creationDate: string
  sent: boolean
  sentDate?: string
  topicId?: string
  sendToDevTopic?: boolean
}

export const NotificationsService = {
  async getAll(): Promise<NotificationContent[]> {
    const { data } = await axios.get('/api/notif')
    return data
  },

  async deleteNotification(id: string): Promise<void> {
    await axios.delete('/api/notif/' + id)
  },

  async addNotification(
    notification: NotificationContent,
  ): Promise<NotificationContent> {
    const { data } = await axios.post('/api/notif', notification)
    return data
  },

  async addNotificationNow(
    notification: NotificationContent,
  ): Promise<NotificationContent> {
    const { data } = await axios.post('/api/notif/fb', notification)
    return data
  },

  async sendOnDevice(
    notification: NotificationContent,
    token: string,
  ): Promise<void> {
    await axios.post(`/api/notif/fb/${token}`, notification)
  },

  async forceGrabDevice(token: string): Promise<void> {
    await axios.post(`/api/notif/forcegrab/${token}`)
  },

  async deleteAll(): Promise<void> {
    await axios.post(`/api/notif/delete_all`)
  },
}
