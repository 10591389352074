import {
  type ExportUrl,
  type ImportStatus,
} from '@/pages/import/store/import.type'
import { ErrorService } from '@/shared/service/errorService'
import { FestivalService } from '@/shared/service/festival.service'

import { createStore, type GettersObj } from '../../../utils/createStore'
import { ImportService } from '../import.service'

export type GettersImports = GettersObj<typeof mapGettersImports>

export class ImportsState {
  loading = false
  disabled = false
  lastImportStatus: ImportStatus = {} as ImportStatus
  syncUrls: ExportUrl[] = []
}
async function uploadFile(
  file: File,
  commit: (mutation: string) => void,
  handler: (file: File) => Promise<void>,
): Promise<void> {
  commit('LOAD')
  try {
    await handler(file)
  } catch (e) {
    ErrorService.handleError(e)
  }
  commit('UNLOAD')
}

export const {
  imports,
  mapState: mapStateImports,
  commit: commitImports,
  dispatch: dispatchImports,
  mapGetters: mapGettersImports,
  useGetter: useGetterImports,
  useState: useStateImports,
} = createStore({
  namespaced: true,
  moduleName: 'imports',
  initState: new ImportsState(),
  mutations: {
    LOAD(state) {
      state.loading = true
      state.disabled = true
    },
    UNLOAD(state) {
      state.loading = false
    },
  },
  actions: {
    async uploadGcArtist({ commit }, file: File) {
      await uploadFile(file, commit, async (file) =>
        ImportService.uploadArtist(file),
      )
    },
    async uploadGcScene({ commit }, file: File) {
      await uploadFile(file, commit, async (file) =>
        ImportService.uploadScene(file),
      )
    },
    async uploadGcEvent({ commit }, file: File) {
      await uploadFile(file, commit, async (file) =>
        ImportService.uploadEvent(file),
      )
    },
    async uploadHeeds({ commit }, file: File) {
      await uploadFile(file, commit, async (file) =>
        ImportService.uploadHeeds(file),
      )
    },
    async syncHeeds({ commit }, { clean }: { clean: boolean }) {
      commit('LOAD')
      await ImportService.syncHeeds(clean)
      commit('UNLOAD')
    },
    async sync(
      { commit },
      { urls, clean }: { urls: ExportUrl[]; clean: boolean },
    ) {
      commit('LOAD')
      await ImportService.sync(urls, clean)
      commit('UNLOAD')
    },

    async loadSyncUrls({ state }) {
      const festivalImportUrl = await FestivalService.getFestival()

      if (!festivalImportUrl.gcExportUrls) {
        festivalImportUrl.gcExportUrls = []
      }

      if (festivalImportUrl.gcExportUrls.length < 3) {
        const artist = festivalImportUrl.gcExportUrls.find(
          (value) => value.type === 'ARTIST',
        )
        const scene = festivalImportUrl.gcExportUrls.find(
          (value) => value.type === 'SCENE',
        )
        const event = festivalImportUrl.gcExportUrls.find(
          (value) => value.type === 'EVENT',
        )
        state.syncUrls = [
          { type: 'ARTIST', url: artist ? artist.url : '' },
          { type: 'SCENE', url: scene ? scene.url : '' },
          { type: 'EVENT', url: event ? event.url : '' },
        ]
      } else {
        state.syncUrls = festivalImportUrl.gcExportUrls
      }
    },

    async getLastStatus({ state }) {
      const importStatus: ImportStatus =
        await ImportService.getLastImportStatus()

      state.lastImportStatus = importStatus
      if (importStatus.end) {
        state.disabled = false
      }
    },
  },
  getters: {},
})
