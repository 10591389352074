import axios from 'axios'

export type AttendeeWhiteListEntry = {
  _id?: string
  email: string
  firstname: string
  lastname: string
}

export const AttendeeWhiteListService = {
  async getAll(
    query: string | null,
    page: number,
    itemsPerPage: number,
    sortBy: string,
    desc: boolean,
    onlyReported: boolean,
  ): Promise<{ attendeeWhiteLists: AttendeeWhiteListEntry[]; total: number }> {
    const { data } = await axios.get('/api/attendee/whitelist', {
      params: {
        query: query ?? '',
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        order: sortBy,
        desc,
        onlyReported: !!onlyReported,
      },
    })
    return data
  },

  async delete(id: string): Promise<void> {
    await axios.delete('/api/attendee/whitelist/id/' + id)
  },

  async deleteAll(): Promise<void> {
    await axios.delete('/api/attendee/whitelist/all')
  },

  async update(
    attendeeWhiteList: AttendeeWhiteListEntry,
  ): Promise<AttendeeWhiteListEntry> {
    const { data } = await axios.post(
      '/api/attendee/whitelist',
      attendeeWhiteList,
    )
    return data
  },

  async uploadFile(file: string): Promise<void> {
    await axios.post('/api/attendee/whitelist/importXls', {
      file,
    })
  },
}
