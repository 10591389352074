import { type Program } from '@/shared/types/program.type'

import { type TranslatableText } from '../../../types/TranslatableText.type'
import { type MusicGroup } from './musicGroup/musicGroup.type'
import { type Scene } from './scene.type'

export type StringDate = string

export type ProgEvent = {
  _id: string
  copyright?: string | null
  day: StringDate
  descriptions?: TranslatableText | null
  enableAttending: boolean
  enableRating: boolean
  eventAttendeesList: EventAttendeesDTO[]
  eventTicketLink?: string | null
  genres: string[] | null
  hideEndDate: false
  image?: string | null
  isVisible: boolean
  meanRate: number
  musicGroups?: MusicGroup[]
  notifPlanned: boolean
  price?: string
  program: Program
  programEventNotifSendTime: ProgramEventNotifSendTime
  scene: Scene
  showEndDate?: StringDate
  showStartDate?: StringDate
  soldOut: boolean
  ticketOpenInWebview: boolean
  titles?: TranslatableText | null

  //computed fields
  startTime: `${number}:${number}`
  endTime: `${number}:${number}`
  fakeTitle: string
  date: string
  attendees: number
}

export type EventAttendeesDTO = {
  deviceUUID: string
  firebaseUserId: string
  comment: string
  rate: number | null
}

export enum ProgramEventNotifSendTime {
  _10M_AFTER = '_10M_AFTER',
  NEXT_DAY_AT_10 = 'NEXT_DAY_AT_10',
  NEVER = 'NEVER',
}
