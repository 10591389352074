import { type RouteConfig } from 'vue-router'

import { dispatchAppConfig } from '../../shared/store/app-config.store'
import { dispatchGuestType } from './guestType.store'

export const GuestTypeRouter: RouteConfig[] = [
  {
    path: '/guest-types',
    component: async () => import('./GuestTypes.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await Promise.all([
        dispatchAppConfig('LOAD_APP_CONFIG'),
        dispatchGuestType('list'),
      ])
      next()
    },
  },
]
