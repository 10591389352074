import { cloneDeep } from 'lodash'

import { i18n } from '@/i18n/i18n'
import { ErrorService } from '@/shared/service/errorService'
import { FilesService } from '@/shared/service/files.service'
import { SnackbarService } from '@/shared/snackbar/snackbar.service'

import { createStore } from '../../utils/createStore'
import { type GettersObj } from '../../utils/createStore'
import { MenuElement } from '../menuEntries/MenuEntry.type'
import { GalleryService } from './gallery.service'
import { GalleryItem } from './gallery.type'

export type GettersGallery = GettersObj<typeof mapGettersGallery>

export class GalleryState {
  gallery: Record<string, GalleryItem> = {}
  loading = false
  editingGalleryItemId = ''
  deletingGalleryItemId = ''
  showAddingModal = false
  thumbnailImage = ''
  thumbnailImageCrop = ''
  image = ''
  galleryEntry: MenuElement = new MenuElement()
  pdfFilename = ''
}

export const {
  gallery,
  commit: commitGallery,
  dispatch: dispatchGallery,
  mapState: mapStateGallery,
  useGetter: useGetterGallery,
  useState: useStateGallery,
  mapGetters: mapGettersGallery,
} = createStore({
  namespaced: true,
  initState: new GalleryState(),
  moduleName: 'gallery',
  mutations: {
    LOAD(state) {
      state.loading = true
    },

    UNLOAD(state) {
      state.loading = false
    },

    SET_ADDING_ITEM(state) {
      state.editingGalleryItemId = ''
      state.pdfFilename = ''
      state.showAddingModal = true
    },

    SET_THUMBNAIL_CROP(state, crop: string) {
      state.thumbnailImageCrop = crop
    },

    SET_THUMBNAIL(state, img: string) {
      state.thumbnailImage = img
    },

    SET_DELETING(state, id: string) {
      state.deletingGalleryItemId = id
    },

    SET_NOT_DELETING(state) {
      state.deletingGalleryItemId = ''
    },

    RESET_ADDING(state) {
      state.image = ''
      state.thumbnailImage = ''
      state.thumbnailImageCrop = ''
      state.editingGalleryItemId = ''
      state.showAddingModal = false
      state.pdfFilename = ''
    },
  },
  actions: {
    async getGalleryItems({ commit, state }) {
      try {
        commit('LOAD')
        const galleryItems: GalleryItem[] = await GalleryService.getAll()
        state.gallery = galleryItems.reduce<Record<string, GalleryItem>>(
          (acc, item) => {
            acc[item._id] = item
            return acc
          },
          {},
        )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async uploadFile({ commit, dispatch, state }, file: File) {
      try {
        commit('LOAD')
        const { filename } = await FilesService.uploadFile(file)
        state.image = filename
        await dispatch('getFileInfo')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async uploadThumbnailImage({ commit, state }) {
      try {
        commit('LOAD')
        const image = FilesService.getImgFromCrop(state.thumbnailImageCrop)
        const { filename } = await FilesService.uploadImage(image)
        commit('SET_THUMBNAIL', filename)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async changeVisibility({ commit, state, dispatch }, id: string) {
      try {
        commit('LOAD')
        const gallery: GalleryItem = cloneDeep(state.gallery[id])
        gallery.visible = !gallery.visible
        await GalleryService.updateGalleryItem(gallery)
        gallery.visible
          ? SnackbarService.info(
              i18n.tc('GALLERY.SNACKBAR.HIDE_OFF', undefined, {
                string: gallery.titles.fr,
              }),
            )
          : SnackbarService.info(
              i18n.tc('GALLERY.SNACKBAR.HIDE', undefined, {
                string: gallery.titles.fr,
              }),
            )
        await dispatch('getGalleryItems')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async delete({ commit, state, dispatch }) {
      try {
        commit('LOAD')
        await GalleryService.delete(state.deletingGalleryItemId)
        commit('SET_NOT_DELETING')
        SnackbarService.info(i18n.tc('GALLERY.SNACKBAR.DELETE'))
        await dispatch('getGalleryItems')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async addGalleryItem({ commit, dispatch, state }, gallery: GalleryItem) {
      try {
        commit('LOAD')
        if (state.thumbnailImageCrop) {
          await dispatch('uploadThumbnailImage')
        }
        gallery.thumbnailImage = state.thumbnailImage
        gallery.image = state.image
        let message = ''
        if (state.editingGalleryItemId) {
          await GalleryService.updateGalleryItem(gallery)
          message = i18n.tc('GALLERY.SNACKBAR.EDIT', undefined, {
            string: gallery.titles.fr,
          })
        } else {
          await GalleryService.postGallery(gallery)
          message = i18n.tc('GALLERY.SNACKBAR.ADD', undefined, {
            string: gallery.titles.fr,
          })
        }
        SnackbarService.info(message)
        commit('RESET_ADDING')
        await dispatch('getGalleryItems')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async reorder({ commit, dispatch, state }, newOrder: GalleryItem[]) {
      try {
        commit('LOAD')
        let weightCounter = 0
        newOrder.forEach((item) => {
          state.gallery[item._id].weight = weightCounter
          weightCounter++
        })
        await GalleryService.updateGalleryOrder(newOrder.map((i) => i._id))
        await dispatch('getGalleryItems')
        SnackbarService.info(i18n.tc('GALLERY.SNACKBAR.REORDER'))
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async getFileInfo({ commit, state }) {
      {
        try {
          commit('LOAD')
          const fileInfo = await FilesService.getFileInfo(state.image)
          state.pdfFilename = fileInfo.fileName
        } catch (e) {
          ErrorService.handleError(e)
        } finally {
          commit('UNLOAD')
        }
      }
    },

    async setEditing({ commit, state }, id: string) {
      try {
        commit('LOAD')
        const fileInfo = await FilesService.getFileInfo(state.gallery[id].image)
        state.image = state.gallery[id].image
        state.thumbnailImage = state.gallery[id].thumbnailImage
        state.editingGalleryItemId = id
        state.pdfFilename = fileInfo.fileName
        state.showAddingModal = true
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },
  },
  getters: {
    getGalleryItems: (state) => {
      return Object.values(state.gallery).sort(
        (a: GalleryItem, b: GalleryItem) => {
          return a.weight - b.weight
        },
      )
    },

    getAddingGalleryItem: (state) => {
      return state.editingGalleryItemId
        ? state.gallery[state.editingGalleryItemId]
        : new GalleryItem()
    },
  },
})
