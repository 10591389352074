import dayjs, { type Dayjs } from 'dayjs'

import { AnalyticsService } from '@/pages/analytics/analyticsService'
import { type ScreenTableDateDto } from '@/pages/analytics/models/analytics.type'
import { type AdvertisementDto } from '@/pages/analytics/models/analytics-advertisement.type'
import { type AnalyticsNotification } from '@/pages/analytics/models/analytics-notification.type'
import {
  type UserCountryDto,
  type UserSectionDashboardDto,
  type UserSessionPeriodDto,
} from '@/pages/analytics/models/analytics-user.type'
import { ErrorService } from '@/shared/service/errorService'

import { type RootGetters } from '../../store'
import { createStore, type GettersObj } from '../../utils/createStore'

const TODAY = { name: 'MATOMO.PERIOD.TODAY', id: 'TODAY' }
const LAST_WEEK = { days: 7, name: 'MATOMO.PERIOD.LAST_WEEK', id: 'LAST_WEEK' }
const LAST_MONTH = {
  days: 30,
  name: 'MATOMO.PERIOD.LAST_MONTH',
  id: 'LAST_MONTH',
}
const LAST_QUARTER = {
  months: 3,
  name: 'MATOMO.PERIOD.LAST_QUARTER',
  id: 'LAST_QUARTER',
}
const LAST_SEMESTER = {
  months: 6,
  name: 'MATOMO.PERIOD.LAST_SEMESTER',
  id: 'LAST_SEMESTER',
}
const LAST_YEAR = {
  months: 12,
  name: 'MATOMO.PERIOD.LAST_YEAR',
  id: 'LAST_YEAR',
}
const START_OF_YEAR = {
  name: 'MATOMO.PERIOD.START_OF_YEAR',
  id: 'START_OF_YEAR',
}

export const PERIODS: Period[] = [
  TODAY,
  LAST_WEEK,
  LAST_MONTH,
  LAST_QUARTER,
  LAST_SEMESTER,
  LAST_YEAR,
  START_OF_YEAR,
]

export type Period = { name: string; id: string }
export type DayPeriod = { days: number } & Period
export type MonthPeriod = { months: number } & Period

function isDayPeriod(period: Period): period is DayPeriod {
  return 'days' in period
}

function isMonthPeriod(period: Period): period is MonthPeriod {
  return 'months' in period
}

function getPeriodFromDateRange(period: Period): {
  start: Dayjs
  end: Dayjs
} {
  const today = dayjs()
  if (period.id === 'TODAY') {
    return { start: today, end: today }
  }
  if (isDayPeriod(period)) {
    return {
      start: dayjs().subtract(1, 'day').subtract(period.days, 'day'),
      end: today.subtract(1, 'day'),
    }
  }
  if (isMonthPeriod(period)) {
    return {
      start: dayjs().subtract(1, 'day').subtract(period.months, 'month'),
      end: today.subtract(1, 'day'),
    }
  }
  if (period.id === 'START_OF_YEAR') {
    return { start: dayjs().startOf('year'), end: today.subtract(1, 'day') }
  }
  throw Error('period not implemented')
}

export function getPeriodById(id: string | null): Period | undefined {
  if (id == null) return undefined
  return PERIODS.find((period) => period.id === id)
}

export const STAT_REQUEST_LOADING = { status: 'loading' } as const
export const STAT_REQUEST_ERROR = { status: 'error' } as const

export type StatRequestDTO<T> =
  | typeof STAT_REQUEST_LOADING
  | T
  | typeof STAT_REQUEST_ERROR

export function isLoaded<T>(stat: StatRequestDTO<T>): stat is T {
  return stat !== STAT_REQUEST_LOADING && stat !== STAT_REQUEST_ERROR
}

export type GettersDashboardMatomo = GettersObj<
  typeof mapGettersDashboardMatomo
>

export class DashboardMatomoState {
  userMetrics: StatRequestDTO<Map<string, number>> = STAT_REQUEST_LOADING
  userSectionDashboardMetrics: StatRequestDTO<UserSectionDashboardDto> =
    STAT_REQUEST_LOADING
  userCountryMetrics: StatRequestDTO<UserCountryDto[]> = STAT_REQUEST_LOADING
  userSessionMetrics: StatRequestDTO<Map<string, UserSessionPeriodDto>> =
    STAT_REQUEST_LOADING
  schedulesMetrics: StatRequestDTO<ScreenTableDateDto[]> = STAT_REQUEST_LOADING
  eventMetrics: StatRequestDTO<ScreenTableDateDto[]> = STAT_REQUEST_LOADING
  favoriteEventMetrics: StatRequestDTO<ScreenTableDateDto[]> =
    STAT_REQUEST_LOADING
  commentedEventMetrics: StatRequestDTO<ScreenTableDateDto[]> =
    STAT_REQUEST_LOADING
  ratedEventMetrics: StatRequestDTO<ScreenTableDateDto[]> = STAT_REQUEST_LOADING
  participatedEventMetrics: StatRequestDTO<ScreenTableDateDto[]> =
    STAT_REQUEST_LOADING
  guestMetrics: StatRequestDTO<Map<string, ScreenTableDateDto[]>> =
    STAT_REQUEST_LOADING
  favoriteGuestMetrics: StatRequestDTO<Map<string, ScreenTableDateDto[]>> =
    STAT_REQUEST_LOADING
  guestListMetrics: StatRequestDTO<ScreenTableDateDto[]> = STAT_REQUEST_LOADING
  galleryMetrics: StatRequestDTO<ScreenTableDateDto[]> = STAT_REQUEST_LOADING
  infoPratiqueMetrics: StatRequestDTO<ScreenTableDateDto[]> =
    STAT_REQUEST_LOADING
  webviewMetrics: StatRequestDTO<ScreenTableDateDto[]> = STAT_REQUEST_LOADING
  advertisementMetrics: StatRequestDTO<AdvertisementDto> = STAT_REQUEST_LOADING
  notificationMetrics: StatRequestDTO<AnalyticsNotification[]> =
    STAT_REQUEST_LOADING
  otherScreenMetrics: StatRequestDTO<ScreenTableDateDto[]> =
    STAT_REQUEST_LOADING

  filterDatesModel: Period =
    getPeriodById(localStorage.getItem('ANALYTICS_PERIOD')) ?? LAST_WEEK
}

export type StatName = keyof Omit<DashboardMatomoState, 'filterDatesModel'>

export type PeriodRangeDate = {
  period: string
} & RangeDate

export type RangeDate = {
  startDate: string
  endDate: string
}

async function queryWithLoading(
  { commit }: { commit: (arg0: string, arg1: any) => void },
  stat: StatName,
  apiCall: () => Promise<void>,
): Promise<void> {
  commit('SET_STAT_LOADING', stat)
  try {
    await apiCall()
  } catch (e) {
    ErrorService.handleError(e)
    commit('SET_STAT_ERROR', stat)
  }
}

export const {
  matomoDashboard,
  commit: commitMatomoDashboard,
  dispatch: dispatchMatomoDashboard,
  mapGetters: mapGettersDashboardMatomo,
  mapState: mapStateDashboardMatomo,
  useGetter: useGetterDashboardMatomo,
  useState: useStateDashboardMatomo,
} = createStore({
  namespaced: true,
  initState: new DashboardMatomoState(),
  moduleName: 'matomoDashboard',
  actions: {
    async loadAllStats(context) {
      await Promise.all([
        //user
        context.dispatch('loadUserMetrics'),
        context.dispatch('loadUserSectionDashboardMetrics'),
        context.dispatch('loadUserCountryMetrics'),
        context.dispatch('loadUserSessionMetrics'),
        // navigation
        context.dispatch('loadScheduleMetrics'),
        context.dispatch('loadEventMetrics'),
        context.dispatch('loadGuestsMetrics'),
        context.dispatch('loadGuestListMetrics'),
        context.dispatch('loadGalleryMetrics'),
        context.dispatch('loadInfoPratiqueMetrics'),
        context.dispatch('loadWebviewMetrics'),
        context.dispatch('loadOtherScreenMetrics'),
        // interactions
        context.dispatch('loadFavoriteEventMetrics'),
        (context.rootGetters as RootGetters).featureIsEnable('allowRateOnEvent')
          ? context.dispatch('loadCommentedEventMetrics')
          : Promise.resolve(),
        (context.rootGetters as RootGetters).featureIsEnable('allowRateOnEvent')
          ? context.dispatch('loadRatedEventMetrics')
          : Promise.resolve(),
        (context.rootGetters as RootGetters).featureIsEnable(
          'allowAttendingEvent',
        )
          ? context.dispatch('loadParticipateEventMetrics')
          : Promise.resolve(),
        context.dispatch('loadFavoriteGuestMetrics'),
        (context.rootGetters as RootGetters).featureIsEnable(
          'showDrawerBottomAdvertisement',
        ) ||
        (context.rootGetters as RootGetters).featureIsEnable(
          'displaySplashAdvertisement',
        )
          ? context.dispatch('loadAdvertisementMetrics')
          : Promise.resolve(),
        context.dispatch('loadNotificationMetrics'),
      ])
    },

    async loadUserMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'userMetrics', async () => {
        const data = await AnalyticsService.getUsersNumber(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )
        context.commit('SET_USER_METRICS', data)
      })
    },

    async loadUserSectionDashboardMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(
        context,
        'userSectionDashboardMetrics',
        async () => {
          const data = await AnalyticsService.getUsersSectionDashboardMetrics(
            start.format('YYYY-MM-DD'),
            end.format('YYYY-MM-DD'),
          )
          context.commit('SET_USER_SECTION_DASHBOARD_METRICS', data)
        },
      )
    },

    async loadUserCountryMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'userCountryMetrics', async () => {
        const data = await AnalyticsService.getUsersLocation(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )
        context.commit('SET_USER_COUNTRY_METRICS', data)
      })
    },

    async loadUserSessionMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'userSessionMetrics', async () => {
        const data = await AnalyticsService.getUsersSessions(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )
        context.commit('SET_USER_SESSION_METRICS', data)
      })
    },

    async loadScheduleMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'schedulesMetrics', async () => {
        const schedule = await AnalyticsService.getScheduleMetrics(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )
        context.commit('SET_SCHEDULES_METRICS', schedule)
      })
    },

    async loadEventMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'eventMetrics', async () => {
        const data = await AnalyticsService.getEventsNumber(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )
        context.commit('SET_EVENT_METRICS', data)
      })
    },

    async loadFavoriteEventMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'favoriteEventMetrics', async () => {
        const eventfav = await AnalyticsService.getFavoriteEventMetrics(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )

        context.commit('SET_FAVORITE_EVENT_METRICS', eventfav)
      })
    },

    async loadCommentedEventMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'commentedEventMetrics', async () => {
        const commentEvent = await AnalyticsService.getCommentedEventMetrics(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )

        context.commit('SET_COMMENTED_EVENT_METRICS', commentEvent)
      })
    },

    async loadRatedEventMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'ratedEventMetrics', async () => {
        const rateEvent = await AnalyticsService.getRatedEventMetrics(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )

        context.commit('SET_RATED_EVENT_METRICS', rateEvent)
      })
    },
    async loadParticipateEventMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'participatedEventMetrics', async () => {
        const participateEvent =
          await AnalyticsService.getParticipatedEventMetrics(
            start.format('YYYY-MM-DD'),
            end.format('YYYY-MM-DD'),
          )

        context.commit('SET_PARTICIPATED_EVENT_METRICS', participateEvent)
      })
    },

    async loadGuestsMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'guestMetrics', async () => {
        const data = await AnalyticsService.getGuestsNumber(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )
        context.commit('SET_GUESTS_METRICS', data)
      })
    },

    async loadFavoriteGuestMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'favoriteGuestMetrics', async () => {
        const favGuest = await AnalyticsService.getFavoriteGuestMetrics(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )

        context.commit('SET_FAVORITE_GUEST_METRICS', favGuest)
      })
    },

    async loadGuestListMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'guestListMetrics', async () => {
        const data = await AnalyticsService.getGuestListMetrics(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )
        context.commit('SET_GUEST_LIST_METRICS', data)
      })
    },

    async loadGalleryMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'galleryMetrics', async () => {
        const data = await AnalyticsService.getGalleryNumber(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )
        context.commit('SET_GALLERY_METRICS', data)
      })
    },

    async loadInfoPratiqueMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'infoPratiqueMetrics', async () => {
        const data = await AnalyticsService.getInfoPratiqueMetrics(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )
        context.commit('SET_INFO_PRATIQUE_METRICS', data)
      })
    },

    async loadWebviewMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'webviewMetrics', async () => {
        const webview = await AnalyticsService.getWebviewMetrics(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )

        context.commit('SET_WEBVIEW_METRICS', webview)
      })
    },

    async loadAdvertisementMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'advertisementMetrics', async () => {
        const data = await AnalyticsService.getAdvertisementMetrics(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )
        context.commit('SET_ADVERTISEMENT_METRICS', data)
      })
    },

    async loadNotificationMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'notificationMetrics', async () => {
        const data = await AnalyticsService.getNotificationMetrics(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )
        context.commit('SET_NOTIFICATION_METRICS', data)
      })
    },

    async loadOtherScreenMetrics(context) {
      const { start, end } = (context.getters as GettersDashboardMatomo)
        .getPeriodDates
      await queryWithLoading(context, 'otherScreenMetrics', async () => {
        const data = await AnalyticsService.getOtherScreenMetrics(
          start.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )
        context.commit('SET_OTHER_SCREEN_METRICS', data)
      })
    },

    async persistFilterDatesModel(context, payload: Period) {
      context.commit('SET_FILTER_DATES_MODEL', payload)
      await context.dispatch('loadAllStats')
    },
  },
  getters: {
    getPeriodDates: (state): { start: Dayjs; end: Dayjs } => {
      return getPeriodFromDateRange(state.filterDatesModel)
    },
  },
  mutations: {
    SET_USER_METRICS(state, data: Record<string, number>) {
      state.userMetrics = new Map(Object.entries(data))
    },

    SET_USER_SECTION_DASHBOARD_METRICS(state, data: UserSectionDashboardDto) {
      state.userSectionDashboardMetrics = data
    },

    SET_USER_COUNTRY_METRICS(state, data: UserCountryDto[]) {
      state.userCountryMetrics = data
    },

    SET_USER_SESSION_METRICS(
      state,
      data: Record<string, UserSessionPeriodDto>,
    ) {
      state.userSessionMetrics = new Map(Object.entries(data))
    },

    SET_SCHEDULES_METRICS(state, data: ScreenTableDateDto[]) {
      state.schedulesMetrics = data
    },

    SET_EVENT_METRICS(state, data: ScreenTableDateDto[]) {
      state.eventMetrics = data
    },

    SET_FAVORITE_EVENT_METRICS(state, data: ScreenTableDateDto[]) {
      state.favoriteEventMetrics = data
    },

    SET_COMMENTED_EVENT_METRICS(state, data: ScreenTableDateDto[]) {
      state.commentedEventMetrics = data
    },

    SET_RATED_EVENT_METRICS(state, data: ScreenTableDateDto[]) {
      state.ratedEventMetrics = data
    },

    SET_PARTICIPATED_EVENT_METRICS(state, data: ScreenTableDateDto[]) {
      state.participatedEventMetrics = data
    },

    SET_GUESTS_METRICS(state, data: Record<string, ScreenTableDateDto[]>) {
      state.guestMetrics = new Map(Object.entries(data))
    },

    SET_FAVORITE_GUEST_METRICS(
      state,
      data: Record<string, ScreenTableDateDto[]>,
    ) {
      state.favoriteGuestMetrics = new Map(Object.entries(data))
    },

    SET_GUEST_LIST_METRICS(state, data: ScreenTableDateDto[]) {
      state.guestListMetrics = data
    },

    SET_GALLERY_METRICS(state, data: ScreenTableDateDto[]) {
      state.galleryMetrics = data
    },

    SET_INFO_PRATIQUE_METRICS(state, data: ScreenTableDateDto[]) {
      state.infoPratiqueMetrics = data
    },

    SET_WEBVIEW_METRICS(state, data: ScreenTableDateDto[]) {
      state.webviewMetrics = data
    },

    SET_ADVERTISEMENT_METRICS(state, data: AdvertisementDto) {
      state.advertisementMetrics = data
    },

    SET_NOTIFICATION_METRICS(state, data: AnalyticsNotification[]) {
      state.notificationMetrics = data
    },

    SET_OTHER_SCREEN_METRICS(state, data: ScreenTableDateDto[]) {
      state.otherScreenMetrics = data
    },

    SET_FILTER_DATES_MODEL(state, data: Period) {
      localStorage.setItem('ANALYTICS_PERIOD', data.id)
      state.filterDatesModel = data
    },

    SET_STAT_LOADING(state, stat: StatName) {
      state[stat] = STAT_REQUEST_LOADING
    },

    SET_STAT_ERROR(state, stat: StatName) {
      state[stat] = STAT_REQUEST_ERROR
    },
  },
})
