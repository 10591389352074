import axios from 'axios'

export type User = {
  _id: string
  name: string
  email: string
  roles: string[]
  companyRef?: string
  blocked?: boolean
  /** Assuming DateTime is represented as an ISO string in the API response */
  expiryDate?: string
}

export const UsersService = {
  async getAll(): Promise<User[]> {
    const { data } = await axios.get('/api/users')
    return data
  },

  async deleteUser(id: string): Promise<void> {
    await axios.delete(`/api/users/${id}`)
  },

  async addUser(user: User): Promise<User> {
    const { data } = await axios.post('/api/users', user)
    return data
  },

  async updateUser(user: User): Promise<User> {
    const { data } = await axios.put(`/api/users/${user._id}`, user)
    return data
  },

  async resetPassword(id: string): Promise<void> {
    await axios.post(`/api/users/resetPassword/${id}`)
  },

  async getUserById(id: string): Promise<User | null> {
    const { data } = await axios.get(`/api/users/${id}`)
    return data
  },
}
