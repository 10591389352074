import { type RouteConfig } from 'vue-router'

import { dispatchFestival } from './festival.store'

export const FestivalRouter: RouteConfig[] = [
  {
    path: '/festival',
    component: async () => import('./Festival.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await dispatchFestival('loadFestival')
      next()
    },
  },
]
