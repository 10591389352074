import { i18n } from '@/i18n/i18n'
import { ApplicationDataService } from '@/shared/service/applicationDataService'
import { ErrorService } from '@/shared/service/errorService'
import { FilesService } from '@/shared/service/files.service'
import { type PreviewGrabData } from '@/shared/types/applicationData.type'
import { entries, isNotNullish } from '@/utils'

import { type ApplicationConfig } from '../../../shared/service/applicationConfig.service'
import { SnackbarService } from '../../../shared/snackbar/snackbar.service'
import { type RootGetters } from '../../../store'
import { createStore } from '../../../utils/createStore'
import { type GettersObj } from '../../../utils/createStore'
import { StaticConfigService } from '../staticCfg.service'
import {
  type FontChangeSet,
  type Lang,
  LANGS,
  type StaticConfig,
} from '../staticCfg.types'

export type GettersStaticConfig = GettersObj<typeof mapGettersStaticConfig>

export class StaticConfigState {
  config: StaticConfig | undefined
  dataSets: Record<Lang, PreviewGrabData | undefined> = {
    en: undefined,
    fr: undefined,
  }
}

export const {
  staticConfig,
  commit: commitStaticConfig,
  mapState: mapStateStaticConfig,
  useGetter: useStaticConfigGetter,
  dispatch: dispatchStaticConfig,
  mapGetters: mapGettersStaticConfig,
  useState: useStateStaticConfig,
} = createStore({
  namespaced: true,
  initState: new StaticConfigState(),
  moduleName: 'staticConfig',
  mutations: {
    SET_CONFIG(state: StaticConfigState, config: StaticConfig): void {
      state.config = config
    },

    SET_DATASETS(
      state: StaticConfigState,
      dataSets: Record<Lang, PreviewGrabData>,
    ) {
      state.dataSets = dataSets
    },
  },
  actions: {
    async loadStaticConfig({ commit }, { oid }: { oid: string }) {
      try {
        const config = await StaticConfigService.get(oid)
        commit('SET_CONFIG', config)
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    createStaticConfig({ commit, rootGetters }) {
      const appCfg: ApplicationConfig = (rootGetters as RootGetters)[
        'applicationCfg/getApplicationCfg'
      ]
      const config: StaticConfig = {
        colorAction: appCfg.colorAction,
        colorBlockAction: appCfg.colorAction,
        colorBackground: appCfg.colorBackground,
        colorTitle: appCfg.colorTitle,
        colorBlockBackground: appCfg.colorBlockBackground,
        colorBlockText: appCfg.colorBlockText,
        fontText: appCfg.fontTextRegular,
        fontTitle: appCfg.fontTitle,
        smartBannerEnabled: true,
        label: 'Nouveau widget',
        pages: [
          (rootGetters as RootGetters)['festival/getFestival']
            ?.alwaysShowEventHour &&
          !(rootGetters as RootGetters)['festival/getFestival']
            ?.eventsHideEndDate
            ? ({
                icon: 'fa-table',
                blockName: {
                  fr: 'Vue grille',
                  en: 'Grid view',
                },
                default: true,
                visible: true,
                view: 'TIMELINE',
              } as const)
            : null,
          {
            icon: 'fa-list',
            blockName: {
              fr: 'Vue événements',
              en: 'Event view',
            },
            default: false,
            visible: true,
            view: 'EVENTS',
          } as const,
        ].filter(isNotNullish),
      }
      commit('SET_CONFIG', config)
    },

    async loadPreviewDataSets({ commit }) {
      try {
        const dataSets: Record<Lang, PreviewGrabData | undefined> = {
          en: undefined,
          fr: undefined,
        }
        await Promise.all(
          LANGS.map(
            async (lang: Lang): Promise<any> =>
              (dataSets[lang] = await ApplicationDataService.getCurrent(
                lang,
                true,
              )),
          ),
        )
        commit('SET_DATASETS', dataSets)
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    async uploadFonts(
      ctx,
      { config, changeset }: { config: StaticConfig; changeset: FontChangeSet },
    ) {
      try {
        await Promise.all(
          entries(changeset)
            .filter(
              <U, T>(arg: [U, T | undefined]): arg is [U, T] => arg[1] != null,
            )
            .map(async ([key, value]) =>
              FilesService.uploadFont(value).then(
                ({ filename, postScriptName }) => {
                  const configFont = config[key]
                  if (!configFont) return
                  configFont.id = filename
                  configFont.postScriptName = postScriptName
                },
              ),
            ),
        )
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    async saveStaticConfig({ commit }, staticCfg: StaticConfig) {
      try {
        const saved = await StaticConfigService.save(staticCfg)
        commit('SET_CONFIG', saved)
        SnackbarService.info(i18n.tc('GENERAL.SAVED'))
      } catch (e) {
        ErrorService.handleError(e)
      }
    },
  },
  getters: {
    getStaticConfig: (state: StaticConfigState): StaticConfig | undefined => {
      return state.config
    },

    getDataSets: (
      state: StaticConfigState,
    ): Record<Lang, PreviewGrabData | undefined> => {
      return state.dataSets
    },
  },
})
