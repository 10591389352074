import { type RouteConfig } from 'vue-router'

import { dispatchProgEvent } from '../progEvent/progEvent.store'
import { dispatchApplicationCfg } from './store/applicationCfg.store'

export const ApplicationCfgRouter: RouteConfig[] = [
  {
    path: '/applicationCfg',
    component: async () => import('./applicationCfg.vue'),
    async beforeEnter(to, _from, next): Promise<void> {
      await Promise.all([
        dispatchApplicationCfg('loadApplicationCfg'),
        dispatchProgEvent('list'),
      ])
      next()
    },
  },
]
