<template>
  <v-container fluid class="pageContainer">
    <div class="mainContent">
      <v-card>
        <div class="div-buttons">
          <slot name="additionalButtons" />
        </div>
        <card-header v-if="!!titleBarTitle">
          {{ titleBarTitle }}
        </card-header>
        <v-card-text v-if="$slots.content" class="card-text-padding">
          <slot name="content" />
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import CardHeader from '../CardHeader.vue'

export default defineComponent({
  components: {
    CardHeader,
  },

  props: {
    titleBarTitle: {
      type: String,
      required: false,
      default: '',
    },
    headerAdditionalClass: {
      type: String,
      required: false,
    },
  },
})
</script>
<style scoped>
.pageContainer {
  align-items: flex-start;
  flex-direction: column;
}

.container {
  max-width: 100%;
  width: 100%;
  margin-top: 30px;
}

.div-buttons {
  position: absolute;
  right: 30px;
  margin-top: -30px;
  z-index: 4;
}

.card-text-padding {
  padding: 30px 20px 30px 20px;
}
</style>
