import Vue from 'vue'
import VueRouter from 'vue-router'

import { AdsRouter } from '@/pages/ads/ads.router'
import { DashboardAnalyticsRouter } from '@/pages/analytics/dashboardAnalytics.router'
import { GuestTypeRouter } from '@/pages/guestTypes/guestType.router'
import { ImportRouter } from '@/pages/import/import.router'
import { SwaggerRouter } from '@/pages/swagger/swagger.router'

import { ApplicationCfgRouter } from './pages/applicationCfg/applicationCfg.router'
import { AttendeeRouter } from './pages/attendee/attendee.router'
import { AttendeesWhiteListRouter } from './pages/attendee-white-list/attendeesWhiteList.router'
import { ConfigDevRouter } from './pages/configDev/configDev.router'
import { DashboardRouter } from './pages/dashboard/dashboard.router'
import { FestivalRouter } from './pages/festival/festival.router'
import {
  dispatchFestival,
  useStateFestival,
} from './pages/festival/festival.store'
import { GalleryRouter } from './pages/gallery/gallery.router'
import { GenresRouter } from './pages/guests/genres.router'
import { GuestsRouter } from './pages/guests/guest.router'
import { InfosRouter } from './pages/infos/infos.router'
import { LoginRouter } from './pages/login/login.router'
import { MapRouter } from './pages/map/map.router'
import { MenuRouter } from './pages/menuEntries/menu.router'
import { NotificationsRouter } from './pages/notifications/notifications.router'
import { NotificationsTopicsRouter } from './pages/notificationsTopics/notificationsTopics.router'
import { OrphanedRouter } from './pages/orphaned/orphaned.router'
import { privateSectionsRouter } from './pages/privateSections/privateSections.router'
import { ProgEventRouter } from './pages/progEvent/progEvent.router'
import { ProgramsRouter } from './pages/programs/programs.router'
import { PublicationRouter } from './pages/publications/publication.router'
import { ResetAppRouter } from './pages/resetApp/resetApp.router'
import { ScenesRouter } from './pages/scenes/scenes.router'
import { SponsorsRouter } from './pages/sponsors/sponsors.router'
import { StaticCfgRouter } from './pages/staticCfg/staticCfg.router'
import { StoresInformationsRouter } from './pages/storesInformations/storesInformations.router'
import {
  dispatchStoresInformations,
  useStoresInformationsState,
} from './pages/storesInformations/storesInformations.store'
import { UsersRouter } from './pages/users/users.router'
import { ValidationMailSentRouter } from './pages/validationMailSent/validationMailSent.router'
import {
  dispatchAppConfig,
  useStateAppConfig,
} from './shared/store/app-config.store'
import { commitSession, dispatchSession } from './shared/store/session.store'
import store from './store'

Vue.use(VueRouter)

export const publicRoutes = ['/ValidationMailSent']

const routes = [
  ...LoginRouter,
  ...ResetAppRouter,
  ...OrphanedRouter,
  ...ConfigDevRouter,
  ...ApplicationCfgRouter,
  ...AdsRouter,
  ...StaticCfgRouter,
  ...PublicationRouter,
  ...UsersRouter,
  ...NotificationsRouter,
  ...NotificationsTopicsRouter,
  ...MenuRouter,
  ...AttendeesWhiteListRouter,
  ...StoresInformationsRouter,
  ...FestivalRouter,
  ...InfosRouter,
  ...SponsorsRouter,
  ...AttendeeRouter,
  ...GuestsRouter,
  ...GenresRouter,
  ...ScenesRouter,
  ...ProgramsRouter,
  ...ProgramsRouter,
  ...ProgEventRouter,
  ...DashboardRouter,
  ...MapRouter,
  ...GalleryRouter,
  ...ValidationMailSentRouter,
  ...SwaggerRouter,
  ...DashboardAnalyticsRouter,
  ...GuestTypeRouter,
  ...ImportRouter,
  ...privateSectionsRouter,
  { path: '*', redirect: '/' },
]

const router = new VueRouter({
  //mode: "history", //Need to update the nginx configuration
  base: import.meta.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (publicRoutes.includes(to.path)) {
    next()
    return
  }

  if (
    store.state.session.key == null &&
    store.state.session.redirectAfterLogin == null
  ) {
    await dispatchSession('LOAD_SESSION')
  }

  if (store.state.session.key == null && to.path !== '/login') {
    commitSession('SET_REDIRECT_AFTER_LOGIN', to.path)
    next('/login')
    return
  }

  if (to.path !== '/login') {
    await Promise.all([
      useStoresInformationsState('appStore').value == null
        ? dispatchStoresInformations('loadAppStore')
        : undefined,
      useStateAppConfig('appConfig').value == null
        ? dispatchAppConfig('LOAD_APP_CONFIG')
        : undefined,
      useStateFestival('festival').value == null
        ? dispatchFestival('loadFestival')
        : undefined,
    ])
  }

  next()
})

export default router
