import { type ActionContext } from 'vuex'

import { i18n } from '../../i18n/i18n'
import router from '../../router'
import { createStore } from '../../utils/createStore'
import { type GettersObj } from '../../utils/createStore'
import { ErrorService } from '../service/errorService'
import { SessionService } from '../service/session.service'
import { type Version, VersionService } from '../service/version.service'
import { SnackbarService } from '../snackbar/snackbar.service'
import { type RootState } from './../../store'

export type GettersSession = GettersObj<typeof mapGettersSession>

export class SessionState {
  loading = true
  key: string | null = null
  principal: null | PrincipalDto = null
  redirectAfterLogin: string | null = null
  version: Version | null = null
}

export type PrincipalDto = {
  email: string
  name: string
  roles: string[]
}

export const {
  session,
  commit: commitSession,
  dispatch: dispatchSession,
  mapGetters: mapGettersSession,
  mapState: mapStateSession,
  useGetter: useGetterSession,
  useState: useStateSession,
} = createStore({
  namespaced: false,
  moduleName: 'session',
  initState: new SessionState(),
  mutations: {
    SET_REDIRECT_AFTER_LOGIN(state: SessionState, path?: string) {
      state.redirectAfterLogin = path != null && path !== '/login' ? path : '/'
    },
    RESET_LOGIN_ON_401(state: SessionState) {
      state.principal = null
      state.key = null
    },
  },
  actions: {
    async LOAD_SESSION({ state }: ActionContext<SessionState, RootState>) {
      try {
        state.loading = true
        const { principal, key } = await SessionService.getSession()
        state.principal = principal
        state.key = key
      } catch (e) {
        ErrorService.handleError(e, false)
      } finally {
        state.loading = false
      }
    },

    async LOGIN(
      { state },
      { username, password }: { username: string; password: string },
    ) {
      try {
        const { principal, key } = await SessionService.login(
          username,
          password,
        )
        state.principal = principal
        state.key = key
        await router.replace(state.redirectAfterLogin ?? '/')
        state.redirectAfterLogin = null
      } catch (e) {
        SnackbarService.error(i18n.tc('LOGIN.ERROR'))
      }
    },

    async LOGOUT({ state }: ActionContext<SessionState, RootState>) {
      try {
        state.loading = true
        await SessionService.logout()
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        state.principal = null
        state.key = null
        state.loading = false
        await router.replace('/login').catch(() => {
          /* do nothing avoid throwing in finally */
        })
      }
    },

    async CHECK_VERSION({ state }: ActionContext<SessionState, RootState>) {
      try {
        const version = await VersionService.getVersion()
        state.version = version
      } catch (e) {
        ErrorService.handleError(e, false)
      }
    },
  },
  getters: {
    isAdmin: (state: SessionState): boolean => {
      return Boolean(state.principal?.roles.includes('admin'))
    },
    isConnected: (state: SessionState): boolean => state.key != null,
    isConnecting: (state: SessionState): boolean => state.loading,
  },
})
