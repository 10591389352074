import { cloneDeep } from 'lodash'

import { i18n } from '../../../i18n/i18n'
import { PAGE_MODE, type PageMode } from '../../../shared/constants'
import { ErrorService } from '../../../shared/service/errorService'
import { SnackbarService } from '../../../shared/snackbar/snackbar.service'
import { fromEntries, isNotNullish } from '../../../utils'
import { createStore } from '../../../utils/createStore'
import { type GettersObj } from '../../../utils/createStore'
import { MapService } from '../map.service'
import { DEFAULT_MAP, type MapObject } from '../map.type'

export type GettersMaps = GettersObj<typeof mapGettersMaps>

export class MapsState {
  mode: PageMode = PAGE_MODE.VIEW
  maps: Record<string, MapObject> = {}
  current: MapObject | null = null
  loading = false
}

export const {
  maps,
  commit: commitMaps,
  dispatch: dispatchMaps,
  mapGetters: mapGettersMaps,
  mapState: mapStateMaps,
  useGetter: useGetterMaps,
  useState: useStateMaps,
} = createStore({
  namespaced: true,

  moduleName: 'maps',
  initState: new MapsState(),
  mutations: {
    SET_ADDING(state) {
      state.mode = PAGE_MODE.ADD
      state.current = cloneDeep(DEFAULT_MAP)
    },
    SET_EDITING(state, payload: MapObject) {
      state.mode = PAGE_MODE.EDIT
      state.current = cloneDeep(payload)
    },
    LOAD(state) {
      state.loading = true
    },
    SET_DELETING(state, payload: MapObject) {
      state.mode = PAGE_MODE.DELETE
      state.current = cloneDeep(payload)
    },
    SET_VIEW(state) {
      state.mode = PAGE_MODE.VIEW
      state.current = null
      state.loading = false
    },
    SET_MAPS(state, payload: MapObject[]) {
      state.maps = fromEntries(
        payload
          .map((item) => (item._id ? ([item._id, item] as const) : null))
          .filter(isNotNullish),
      )
      state.loading = false
    },
  },
  actions: {
    async list({ commit }) {
      try {
        commit('LOAD')
        commit('SET_MAPS', await MapService.getMapList())
      } catch (e) {
        ErrorService.handleError(e)
      }
    },
    async editMap({ commit, dispatch }, payload: MapObject) {
      try {
        commit('LOAD')
        await MapService.saveMap(payload)
        SnackbarService.info(
          i18n.tc('MAPS.SNACK_BAR.EDIT_SUCCESS', undefined, {
            name: payload.names.fr,
          }),
        )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        await dispatch('list')
        commit('SET_VIEW')
      }
    },
    async addMap({ commit, dispatch }, payload: MapObject) {
      try {
        commit('LOAD')
        await MapService.saveMap(payload)
        SnackbarService.info(
          i18n.tc('MAPS.SNACK_BAR.ADD_SUCCESS', undefined, {
            name: payload.names.fr,
          }),
        )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        await dispatch('list')
        commit('SET_VIEW')
      }
    },
    async delete({ commit, dispatch, state }) {
      try {
        commit('LOAD')
        if (!state.current?._id) return
        await MapService.deleteMap(state.current._id)
        SnackbarService.info(i18n.tc('MAPS.SNACK_BAR.DELETE_SUCCESS'))
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        await dispatch('list')
        commit('SET_VIEW')
      }
    },
    async toggleVisibility({ commit, dispatch }, payload: MapObject) {
      commit('LOAD')
      try {
        payload.show = !payload.show
        await MapService.saveMap(payload)
        SnackbarService.info(
          i18n.tc(
            payload.show ? 'MAPS.SNACK_BAR.SHOW' : 'MAPS.SNACK_BAR.HIDE',
            undefined,
            {
              string: payload.names.fr,
            },
          ),
        )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        await dispatch('list')
        commit('SET_VIEW')
      }
    },
  },
  getters: {
    getMaps(state) {
      return Object.values(state.maps)
    },
  },
})
