import { ErrorService } from '@/shared/service/errorService'

import { i18n } from '../../i18n/i18n'
import { FilesService } from '../../shared/service/files.service'
import { SnackbarService } from '../../shared/snackbar/snackbar.service'
import { InternalType } from '../../shared/types/files.type'
import { createStore, type GettersObj } from '../../utils/createStore'
import { type RedirectPage, RedirectPageService } from './redirect-page.service'
import {
  type FestivalPlayStore,
  StoresInformationsService,
} from './storesInformations.service'

export type GettersStoresInformations = GettersObj<
  typeof mapGettersStoresInformations
>

export class StoreInformationsState {
  appStore: FestivalPlayStore | null = null
  redirectPage: RedirectPage | null = null

  icon?: string | null = null
  image?: string | null = null

  cropIcon: string | null = null
  cropImage: string | null = null
}

export const {
  storesInformations,
  commit: commitStoresInformations,
  dispatch: dispatchStoresInformations,
  mapGetters: mapGettersStoresInformations,
  mapState: mapStateStoresInformations,
  useGetter: useStoresInformationsGetter,
  useState: useStoresInformationsState,
} = createStore({
  namespaced: true,
  moduleName: 'storesInformations',
  initState: new StoreInformationsState(),
  mutations: {
    SET_IMAGE(state, image: string) {
      state.image = image
    },

    SET_ICON(state, icon: string) {
      state.icon = icon
    },

    SET_IMAGE_CROP(state, crop: string) {
      state.cropImage = crop
    },

    SET_ICON_CROP(state, crop: string) {
      state.cropIcon = crop
    },
  },
  actions: {
    async loadAppStore({ state }) {
      try {
        const [appStore, redirectPage] = await Promise.all([
          StoresInformationsService.getAppStore(),
          RedirectPageService.getRedirectPage(),
        ])

        state.appStore = appStore
        if (state.appStore.shortDescriptions == null) {
          state.appStore.shortDescriptions = {
            fr: '',
            en: '',
          }
        }
        if (state.appStore.longDescriptions == null) {
          state.appStore.longDescriptions = {
            fr: '',
            en: '',
          }
        }
        state.icon = appStore.applicationIcone
        state.image = appStore.playStoreImage

        state.redirectPage = redirectPage
      } catch (e) {
        ErrorService.handleError(e, false)
      }
    },

    async updateStoresInformations({ state }, appStore: FestivalPlayStore) {
      try {
        appStore.playStoreImage = state.image
        appStore.applicationIcone = state.icon
        await StoresInformationsService.updateStoresInformations(appStore)
        SnackbarService.info(i18n.tc('STORES_INFO.UPDATE'))
        await dispatchStoresInformations('loadAppStore')
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    async saveAllImg({ state }) {
      const [{ filename: imgFilename }, { filename: iconFilename }] =
        await Promise.all([
          state.cropImage
            ? FilesService.uploadImage({
                image: state.cropImage,
                internalType: InternalType.PICTURE,
              })
            : { filename: null },
          state.cropIcon
            ? FilesService.uploadImage({
                image: state.cropIcon,
                internalType: InternalType.PICTURE,
              })
            : { filename: null },
        ])

      if (imgFilename) {
        commitStoresInformations('SET_IMAGE', imgFilename)
      }
      if (iconFilename) {
        commitStoresInformations('SET_ICON', iconFilename)
      }
    },
  },
  getters: {
    getAppStore: (state) => {
      return state.appStore
    },
  },
})
