import axios from 'axios'

import { type GuestType } from '@/pages/guestTypes/GuestType.model'

function removeKey(guestType: GuestType) {
  const { key: _key, ...rest } = guestType
  return rest
}

export const GuestTypeService = {
  baseUrl: '/api/guest-type',

  async getAll(): Promise<GuestType[]> {
    const { data } = await axios.get(this.baseUrl + '/all')
    return data
  },

  async deleteGuestType(id: string): Promise<void> {
    await axios.delete(`${this.baseUrl}/${id}`)
  },

  async addGuestType(guestType: GuestType): Promise<void> {
    await axios.post(this.baseUrl, removeKey(guestType))
  },

  async updateGuestType(guestType: GuestType): Promise<void> {
    await axios.put(`${this.baseUrl}/${guestType._id}`, removeKey(guestType))
  },

  async getGuestNamesByType(id: string): Promise<string[]> {
    const { data } = await axios.get('/api/groups/names-by-type/' + id)
    return data
  },
}
