import { i18n } from '@/i18n/i18n'
import {
  type AdImageLinkWithFiles,
  type AdModeType,
  type ApplicationCfgWithAdsFiles,
} from '@/pages/ads/ads.types'
import { ApplicationConfigService } from '@/shared/service/applicationConfig.service'
import { ErrorService } from '@/shared/service/errorService'
import { FilesService } from '@/shared/service/files.service'
import { SnackbarService } from '@/shared/snackbar/snackbar.service'

import { InternalType } from '../../shared/types/files.type'
import { isNotNullish } from '../../utils'
import { createStore } from '../../utils/createStore'
import { type GettersObj } from '../../utils/createStore'

export type GettersAds = GettersObj<typeof mapGettersAds>

export class AdsState {
  applicationCfg: ApplicationCfgWithAdsFiles | undefined = undefined
  loading = false
  isDrawerAdsPercentageValid = false
  isSplashAdsPercentageValid = false
  currentAdIndex: number | undefined = undefined
  adMode: AdModeType | undefined = undefined
  showDeleteDialog = false
  showAdModal = false
  splashAds: AdImageLinkWithFiles[] = []
  drawerAds: AdImageLinkWithFiles[] = []
  splashDuration = 2
  currentAd: AdImageLinkWithFiles | undefined = undefined
}

function addOrUpdateAd(
  payload: { index: number | undefined; ad: AdImageLinkWithFiles },
  adsArray: AdImageLinkWithFiles[],
): void {
  if (payload.index != null) {
    adsArray.splice(payload.index, 1)
    adsArray.splice(payload.index, 0, payload.ad)
  } else {
    adsArray.push(payload.ad)
  }
}

function isAdsPercentageValid(ads: AdImageLinkWithFiles[]): boolean {
  const total = ads.reduce((acc, ad: AdImageLinkWithFiles) => {
    return acc + ad.percentage
  }, 0)
  return total === 100 || ads.length === 0
}

export const {
  ads,
  commit: commitAds,
  dispatch: dispatchAds,
  mapGetters: mapGettersAds,
  mapState: mapStateAds,
  useGetter: useGetterAds,
  useState: useStateAds,
} = createStore({
  moduleName: 'ads',
  namespaced: true,
  initState: new AdsState(),
  mutations: {
    LOAD(state: AdsState) {
      state.loading = true
    },
    UNLOAD(state: AdsState) {
      state.loading = false
    },
    SET_AD_TO_DELETE_INDEX(
      state: AdsState,
      payload: {
        index: number
        mode: AdModeType
      },
    ) {
      state.currentAdIndex = payload.index
      state.adMode = payload.mode
      state.showDeleteDialog = true
    },

    SET_APPLICATION_CFG(
      state: AdsState,
      applicationCfg: ApplicationCfgWithAdsFiles,
    ) {
      state.applicationCfg = applicationCfg
      state.drawerAds = applicationCfg.drawerAds
      state.splashAds = applicationCfg.splashAds
      state.splashDuration = applicationCfg.splashDuration
      state.isDrawerAdsPercentageValid =
        !applicationCfg.features.showDrawerBottomAdvertisement ||
        (applicationCfg.features.showDrawerBottomAdvertisement &&
          isAdsPercentageValid(state.drawerAds))
      state.isSplashAdsPercentageValid =
        !applicationCfg.features.displaySplashAdvertisement ||
        (applicationCfg.features.displaySplashAdvertisement &&
          isAdsPercentageValid(state.splashAds))
    },

    SET_DRAWER_AD_IMAGE(
      state: AdsState,
      payload: {
        index: number
        filename: string
      },
    ) {
      state.drawerAds[payload.index].croppedImage = undefined
      state.drawerAds[payload.index].image = payload.filename
      state.isDrawerAdsPercentageValid = isAdsPercentageValid(state.drawerAds)
    },

    SET_SPLASH_AD_IMAGE(
      state: AdsState,
      payload: {
        index: number
        filename: string | null
      },
    ) {
      state.splashAds[payload.index].croppedImage = undefined
      state.splashAds[payload.index].image = payload.filename
      state.isSplashAdsPercentageValid = isAdsPercentageValid(state.splashAds)
    },

    SET_DRAWER_AD_VIDEO(
      state: AdsState,
      payload: {
        index: number
        filename: string | null
      },
    ) {
      state.drawerAds[payload.index].videoFile = undefined
      state.drawerAds[payload.index].videoFilename = payload.filename
      state.isDrawerAdsPercentageValid = isAdsPercentageValid(state.drawerAds)
    },

    SET_SPLASH_AD_VIDEO(
      state: AdsState,
      payload: {
        index: number
        filename: string | null
      },
    ) {
      state.splashAds[payload.index].videoFile = undefined
      state.splashAds[payload.index].videoFilename = payload.filename
      state.isSplashAdsPercentageValid = isAdsPercentageValid(state.splashAds)
    },

    DELETE_AD(state: AdsState) {
      if (state.currentAdIndex == null) {
        SnackbarService.error(
          'Error : Deletion of Ad impossible because currentAdIndex is undefined',
        )
        return
      }
      if (state.adMode === 'DRAWER') {
        state.drawerAds.splice(state.currentAdIndex, 1)
        state.isDrawerAdsPercentageValid = isAdsPercentageValid(state.drawerAds)
      } else {
        state.splashAds.splice(state.currentAdIndex, 1)
        state.isSplashAdsPercentageValid = isAdsPercentageValid(state.splashAds)
      }
      state.currentAdIndex = undefined
      state.adMode = undefined
      state.showDeleteDialog = false
    },

    CLOSE_DELETE_AD(state: AdsState) {
      state.currentAdIndex = undefined
      state.adMode = undefined
      state.showDeleteDialog = false
    },

    ADD_AD(state: AdsState, mode: AdModeType) {
      state.adMode = mode
      state.currentAd = undefined
      state.currentAdIndex = undefined
      state.showAdModal = true
    },

    CLOSE_AD_MODAL(state: AdsState) {
      state.adMode = undefined
      state.currentAd = undefined
      state.currentAdIndex = undefined
      state.showAdModal = false
    },

    SAVE_OR_UPDATE_AD(
      state: AdsState,
      payload: { index: number | undefined; ad: AdImageLinkWithFiles },
    ) {
      if (state.adMode === 'DRAWER') {
        addOrUpdateAd(payload, state.drawerAds)
        state.isDrawerAdsPercentageValid = isAdsPercentageValid(state.drawerAds)
      } else {
        addOrUpdateAd(payload, state.splashAds)
        state.isSplashAdsPercentageValid = isAdsPercentageValid(state.splashAds)
      }
      state.currentAdIndex = undefined
      state.currentAd = undefined
      state.adMode = undefined
      state.showAdModal = false
    },

    EDIT_AD(
      state: AdsState,
      payload: {
        index: number
        ad: AdImageLinkWithFiles
        mode: AdModeType
      },
    ) {
      state.adMode = payload.mode
      state.currentAdIndex = payload.index
      state.currentAd = { ...payload.ad }
      state.showAdModal = true
    },
  },
  actions: {
    async loadApplicationCfg({ commit }) {
      try {
        commit('LOAD')
        const applicationCfg = await ApplicationConfigService.get()
        commit('SET_APPLICATION_CFG', applicationCfg)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async updateApplicationCfg(
      { state, dispatch, commit },
      payload: { splashDuration: number },
    ) {
      try {
        commit('LOAD')
        await dispatch('saveAllImg')
        if (state.applicationCfg) {
          state.applicationCfg.splashAds = state.splashAds
          state.applicationCfg.drawerAds = state.drawerAds
          state.applicationCfg.splashDuration = payload.splashDuration

          await ApplicationConfigService.updateApplicationCfg(
            state.applicationCfg,
          )
          SnackbarService.info(i18n.tc('ADS.SAVE'))
          await dispatch('loadApplicationCfg')
        } else {
          SnackbarService.error(
            'updateApplicationCfg in ads page failed: applicationCfg was not loaded',
          )
        }
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async saveAllImg({ state, commit }) {
      await Promise.all(
        [
          ...state.drawerAds.flatMap((ad, index) => {
            return [
              ad.croppedImage
                ? ({
                    index,
                    commitKey: `SET_DRAWER_AD_IMAGE`,
                    croppedImage: ad.croppedImage,
                  } as const)
                : null,
              ad.videoFile
                ? ({
                    index,
                    commitKey: `SET_DRAWER_AD_VIDEO`,
                    videoFile: ad.videoFile,
                  } as const)
                : ad.videoFilename
                  ? ({
                      index,
                      commitKey: `SET_DRAWER_AD_VIDEO`,
                      videoFilename: ad.videoFilename,
                    } as const)
                  : ({
                      index,
                      commitKey: `SET_DRAWER_AD_VIDEO`,
                      videoFilename: null,
                    } as const),
            ]
          }),
          ...state.splashAds.flatMap((ad, index) => {
            return [
              ad.croppedImage
                ? ({
                    index,
                    commitKey: `SET_SPLASH_AD_IMAGE`,
                    croppedImage: ad.croppedImage,
                  } as const)
                : null,
              ad.videoFile
                ? ({
                    index,
                    commitKey: `SET_SPLASH_AD_VIDEO`,
                    videoFile: ad.videoFile,
                  } as const)
                : ad.videoFilename
                  ? ({
                      index,
                      commitKey: `SET_SPLASH_AD_VIDEO`,
                      videoFilename: ad.videoFilename,
                    } as const)
                  : ({
                      index,
                      commitKey: `SET_SPLASH_AD_VIDEO`,
                      videoFilename: null,
                    } as const),
            ]
          }),
        ]
          .filter(isNotNullish)
          .map(async (a) => {
            const { filename } = a.croppedImage
              ? await FilesService.uploadImage({
                  image: a.croppedImage,
                  internalType: InternalType.PICTURE,
                })
              : a.videoFile
                ? await FilesService.uploadVideo(
                    a.videoFile,
                    a.commitKey === 'SET_DRAWER_AD_VIDEO' ? 4 : 9 / 16,
                  )
                : { filename: a.videoFilename }
            commit(a.commitKey, { filename, index: a.index })
          }),
      )
    },
  },
  getters: {},
})
