import { createStore, type GettersObj } from '../../utils/createStore'

export type GettersIsMobile = GettersObj<typeof mapGettersIsMobile>

export class IsMobileState {
  isMobile = false
  isSmallLaptop = false
}

export const {
  isMobile,
  mapGetters: mapGettersIsMobile,
  useGetter: useGetterIsMobile,
  useState: useStateIsMobile,
  mapState: mapStateIsMobile,
  dispatch: dispatchIsMobile,
  commit: commitIsMobile,
} = createStore({
  namespaced: false,
  moduleName: 'isMobile',
  initState: new IsMobileState(),
  mutations: {
    SET_IS_MOBILE(state: IsMobileState) {
      state.isMobile = true
      state.isSmallLaptop = true
    },

    SET_IS_SMALL_LAPTOP(state: IsMobileState) {
      state.isMobile = false
      state.isSmallLaptop = true
    },

    SET_IS_DEFAULT(state: IsMobileState) {
      state.isSmallLaptop = false
      state.isMobile = false
    },
  },
  actions: {},
  getters: {
    getIsMobile: (state: IsMobileState) => {
      return state.isMobile
    },

    getIsSmallLaptop: (state: IsMobileState) => {
      return state.isSmallLaptop
    },
  },
})
