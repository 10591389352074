import { type RouteConfig } from 'vue-router'

import { dispatchSponsors } from './sponsors.store'

export const SponsorsRouter: RouteConfig[] = [
  {
    path: '/sponsors',
    component: async () => import('./Sponsors.vue'),
  },
  {
    path: '/sponsors/create',
    component: async () => import('./AddingSponsors.vue'),
  },
  {
    path: '/sponsors/:id',
    component: async () => import('./AddingSponsors.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await dispatchSponsors('loadSponsor', to.params.id)
      next()
    },
  },
]
