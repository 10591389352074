import axios from 'axios'

import { type GalleryItem } from './gallery.type'

function galleryItemToDbFormat(item: GalleryItem): Omit<GalleryItem, '_id'> {
  const { _id, ...rest } = item
  return rest
}

export const GalleryService = {
  async getAll(): Promise<GalleryItem[]> {
    const { data }: any = await axios.get('/api/galleryItems')
    return data
  },

  async postGallery(gallery: GalleryItem): Promise<void> {
    await axios.post('/api/galleryItems', galleryItemToDbFormat(gallery))
  },

  async delete(id: string): Promise<void> {
    await axios.delete(`/api/galleryItems/${id}`)
  },

  async updateGalleryItem(item: GalleryItem): Promise<void> {
    await axios.put(`/api/galleryItems/${item._id}`, item)
  },

  async updateGalleryOrder(ids: string[]): Promise<void> {
    await axios.post('/api/galleryItems/order', ids)
  },
}
