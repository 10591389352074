import { cloneDeep } from 'lodash'

import { i18n } from '@/i18n/i18n'
import { type GuestType } from '@/pages/guestTypes/GuestType.model'
import { GuestTypeService } from '@/pages/guestTypes/guestType.service'
import { PAGE_MODE, type PageMode } from '@/shared/constants'
import { ErrorService } from '@/shared/service/errorService'
import { SnackbarService } from '@/shared/snackbar/snackbar.service'

import { createStore, type GettersObj } from '../../utils/createStore'

export type GettersGuestType = GettersObj<typeof mapGettersGuestType>

export class GuestTypeState {
  mode: PageMode = PAGE_MODE.VIEW
  guestTypes: Record<string, GuestType> = {}
  currentGuestType: GuestType | null = null
  loading = false
  guestNames: string[] = []
}

export const {
  guestType,
  mapGetters: mapGettersGuestType,
  useGetter: useGetterGuestType,
  useState: useStateGuestType,
  mapState: mapStateGuestType,
  commit: commitGuestType,
  dispatch: dispatchGuestType,
} = createStore({
  namespaced: true,
  initState: new GuestTypeState(),
  mutations: {
    LOAD(state) {
      state.loading = true
    },

    SET_DELETING(state, payload: GuestType) {
      state.mode = PAGE_MODE.DELETE
      state.currentGuestType = { ...payload }
    },

    SET_VIEW(state) {
      state.mode = PAGE_MODE.VIEW
      state.currentGuestType = null
      state.guestNames = []
      state.loading = false
    },

    SET_GUEST_TYPES(state, guestTypes: GuestType[]) {
      state.guestTypes = guestTypes.reduce(
        (acc: Record<string, GuestType>, current) => {
          acc[current._id] = current
          return acc
        },
        {},
      )
      state.loading = false
    },

    SET_ADDING(state) {
      state.mode = PAGE_MODE.ADD
    },

    SET_EDITING(state, payload: GuestType) {
      state.mode = PAGE_MODE.EDIT
      state.currentGuestType = cloneDeep(payload)
    },

    SET_GUEST_NAMES(state, names: string[]) {
      state.guestNames = names
      state.loading = false
    },
  },
  moduleName: 'guestType',
  actions: {
    async list({ commit }) {
      try {
        commit('LOAD')
        commit('SET_GUEST_TYPES', await GuestTypeService.getAll())
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    async delete({ commit, state, dispatch }) {
      try {
        commit('LOAD')
        await GuestTypeService.deleteGuestType(state.currentGuestType!._id)
        SnackbarService.info(i18n.tc('GUEST_TYPE.SNACK_BAR.DELETE_SUCCESS'))
        await dispatch('list')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_VIEW')
      }
    },

    async loadGuestNames({ commit }, guestType: GuestType) {
      try {
        commit('LOAD')
        commit(
          'SET_GUEST_NAMES',
          await GuestTypeService.getGuestNamesByType(guestType._id),
        )
        commit('SET_DELETING', guestType)
      } catch (e) {
        ErrorService.handleError(e)
        commit('SET_VIEW')
      }
    },

    async addGuestType({ commit, dispatch }, payload: GuestType) {
      commit('LOAD')
      try {
        await GuestTypeService.addGuestType(payload)

        SnackbarService.info(
          i18n.tc('GUEST_TYPE.SNACK_BAR.ADD_SUCCESS', undefined, {
            name: payload.names.fr,
          }),
        )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        await dispatch('list')
        commit('SET_VIEW')
      }
    },

    async editGuestType({ commit, dispatch }, payload: GuestType) {
      commit('LOAD')
      try {
        await GuestTypeService.updateGuestType(payload)

        SnackbarService.info(
          i18n.tc('GUEST_TYPE.SNACK_BAR.EDIT_SUCCESS', undefined, {
            name: payload.names.fr,
          }),
        )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        await dispatch('list')
        commit('SET_VIEW')
      }
    },
  },
  getters: {
    getGuestTypes: (state): GuestType[] => {
      return Object.values(state.guestTypes)
    },

    getGuestTypeById: (state): ((id: string) => GuestType) => {
      return (id: string): GuestType => state.guestTypes[id]
    },

    getUsedFrGuestNameLowercase: (state): string[] => {
      return Object.values(state.guestTypes)
        .filter(
          (g) =>
            state.currentGuestType == null ||
            g._id !== state.currentGuestType._id,
        )
        .map((g) => g.names.fr.toLowerCase())
    },
  },
})
