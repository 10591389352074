import { createStore, type GettersObj } from '../../utils/createStore'

export type GettersSnackbar = GettersObj<typeof mapGettersSnackbar>

export class SnackbarState {
  visible = false
  message = ''
  color = ''
}

export const {
  snackbar,
  commit: commitSnackbar,
  dispatch: dispatchSnackbar,
  mapState: mapStateSnackbar,
  mapGetters: mapGettersSnackbar,
  useGetter: useGetterSnackbar,
  useState: useStateSnackbar,
} = createStore({
  namespaced: true,
  initState: new SnackbarState(),
  getters: {},
  moduleName: 'snackbar',
  actions: {},
  mutations: {
    SHOW(state, { message, color }: { message: string; color: string }) {
      state.message = message
      state.color = color
      state.visible = true
      setTimeout(() => {
        state.visible = false
      }, 3000)
    },
  },
})
