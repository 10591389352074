<template>
  <modal
    max-width="500px"
    :title="$tc('GENERAL.DELETION')"
    btn-ok-color="error"
    :btn-ok-label="$tc('GENERAL.DELETE')"
    :btn-ok-disabled="btnOkDisabled"
    :md="12"
    @okAction="$emit('deleteAction')"
    @cancelAction="$emit('cancelAction')"
  >
    <slot name="content" />
    <div class="text-message">{{ message }}</div>
  </modal>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    message: {
      type: String,
      required: false,
    },
    btnOkDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
</script>
<style scoped lang="scss">
.text-message {
  margin: 5px;
  text-align: center;
}
</style>
