import { type RouteConfig } from 'vue-router'

import { dispatchFestival } from '../festival/festival.store'
import { dispatchPrograms } from '../programs/programs.store'
import { dispatchScenes } from '../scenes/scenes.store'
import { dispatchProgEvent } from './progEvent.store'

export const ProgEventRouter: RouteConfig[] = [
  {
    path: '/programmation',
    component: async () => import('./Programmation.vue'),

    async beforeEnter(_to, _from, next): Promise<void> {
      await Promise.all([
        dispatchProgEvent('list'),
        dispatchFestival('loadFestival'),
        dispatchScenes('list'),
        dispatchPrograms('list'),
      ])
      next()
    },
  },
  {
    path: '/programmation/create',
    component: async () => import('./AddingProgEvent.vue'),
  },
  {
    path: '/programmation/import',
    component: async () => import('./EventsImport.vue'),
  },
  {
    path: '/programmation/:id',
    component: async () => import('./AddingProgEvent.vue'),
    async beforeEnter(to, _from, next): Promise<void> {
      await dispatchProgEvent('loadProgEvent', {
        id: to.params.id,
        loadType: 'edit',
      })
      next()
    },
  },
  {
    path: '/programmation/duplicate/:id',
    component: async () => import('./AddingProgEvent.vue'),
    async beforeEnter(to, _from, next): Promise<void> {
      await dispatchProgEvent('loadProgEvent', {
        id: to.params.id,
        loadType: 'duplicate',
      })
      next()
    },
  },
]
