import { type RouteConfig } from 'vue-router'

import { dispatchApplicationCfg } from '../applicationCfg/store/applicationCfg.store'

export const ConfigDevRouter: RouteConfig[] = [
  {
    path: '/applicationConfig',
    component: async () => import('./ConfigDev.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await dispatchApplicationCfg('loadApplicationCfg')
      next()
    },
  },
]
