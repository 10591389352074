import 'material-design-icons-iconfont/dist/material-design-icons.css'

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Resize } from 'vuetify/lib/directives'
import fr from 'vuetify/src/locale/fr'

Vue.use(Vuetify, {
  directives: {
    Resize,
  },
})

export default new Vuetify({
  lang: {
    locales: { fr },
    current: 'fr',
  },
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes: {
      light: {
        primary: '#435FFF',
        secondary: '#E4E8FE',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        btnPrimary: '#607D8B',
        bleu2: '#0F708A',
        grey1: '#6D8794',
      },
    },
  },
})
