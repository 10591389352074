import cloneDeep from 'lodash/cloneDeep'

import { i18n } from '@/i18n/i18n'
import { type SponsorsOrderChange } from '@/pages/sponsors/models/SponsorsOrderChange.type'
import { ErrorService } from '@/shared/service/errorService'
import { FilesService } from '@/shared/service/files.service'
import { SnackbarService } from '@/shared/snackbar/snackbar.service'
import { type Sponsor, type SponsorCategory } from '@/shared/types/sponsor.type'

import router from '../../router'
import { InternalType } from '../../shared/types/files.type'
import store, { type RootGetters } from '../../store'
import { createStore, type GettersObj } from '../../utils/createStore'
import { SponsorsService } from './sponsors.service'
import {
  commitSponsorsCategories,
  dispatchSponsorsCategories,
} from './sponsorsCategories/sponsorsCategories.store'

export type GettersSponsors = GettersObj<typeof mapGettersSponsors>

export class SponsorsState {
  sponsors: Record<string, Sponsor> = {}
  loading = false
  deletingSponsorId: string | null = null
  editingSponsor: Sponsor | null = null
  image: string | null = null
}

export const {
  sponsors,
  mapGetters: mapGettersSponsors,
  useGetter: useGetterSponsors,
  useState: useStateSponsors,
  mapState: mapStateSponsors,
  dispatch: dispatchSponsors,
  commit: commitSponsors,
} = createStore({
  namespaced: true,
  moduleName: 'sponsors',
  initState: new SponsorsState(),
  mutations: {
    LOAD(state) {
      state.loading = true
    },

    UNLOAD(state) {
      state.loading = false
    },

    SET_SPONSORS(state, sponsors: Sponsor[]) {
      state.sponsors = sponsors.reduce<Record<string, Sponsor>>(
        (acc, sponsor) => {
          acc[sponsor._id] = sponsor
          return acc
        },
        {},
      )
    },

    SET_DELETING(state, id: string) {
      state.deletingSponsorId = id
    },

    SET_NOT_DELETING(state) {
      state.deletingSponsorId = null
    },

    SET_IMAGE(state, image: string) {
      state.image = image
    },

    RESET(state) {
      state.editingSponsor = null
      state.image = null
    },

    SET_EDITING(state, sponsor: Sponsor) {
      state.editingSponsor = sponsor
      if (sponsor.category) {
        commitSponsorsCategories(
          'SET_CATEGORIES_SELECTION',
          sponsor.category.nameNormalized,
        )
      }
      state.image = sponsor.image
    },
  },
  actions: {
    async list({ commit }) {
      commit('LOAD')
      try {
        const sponsors = await SponsorsService.getAll()
        commit('SET_SPONSORS', sponsors)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async delete({ state, commit, dispatch }) {
      commit('LOAD')
      try {
        const id = state.deletingSponsorId ? state.deletingSponsorId : ''
        await SponsorsService.delete(id)
        SnackbarService.info(
          i18n.tc('SPONSORS.SNACKBAR.DELETE', undefined, {
            string: state.sponsors[id].names.fr,
          }),
        )
        await dispatch('list')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_NOT_DELETING')
      }
    },

    async uploadImage({ commit }, file: File) {
      commit('LOAD')
      try {
        const savedImage = await FilesService.uploadImageFile(
          file,
          InternalType.SPONSOR,
        )
        commit('SET_IMAGE', savedImage.filename)
      } catch (e) {
        ErrorService.handleError(e)
        throw e
      } finally {
        commit('UNLOAD')
      }
    },

    async addSponsor({ state, commit, rootState }, sponsor: Sponsor) {
      commit('LOAD')
      try {
        await dispatchSponsorsCategories('updateSponsorsCategories')
        const selectedCategory = cloneDeep(
          rootState.sponsorsCategories.selectedCategory,
        )
        sponsor.image = state.image
        sponsor.category = selectedCategory
          ? cloneDeep(
              Object.values(
                rootState.sponsorsCategories.sponsorsCategories,
              ).filter(
                (n: SponsorCategory) =>
                  n.nameNormalized === selectedCategory.nameNormalized,
              )[0],
            )
          : selectedCategory
        let message
        if (state.editingSponsor) {
          await SponsorsService.updateSponsor(sponsor)
          message = i18n.tc('SPONSORS.SNACKBAR.EDIT', undefined, {
            string: sponsor.names.fr,
          })
        } else {
          await SponsorsService.addSponsor(sponsor)
          message = i18n.tc('SPONSORS.SNACKBAR.ADD', undefined, {
            string: sponsor.names.fr,
          })
        }
        SnackbarService.info(message.toString())
        commit('RESET')
        commit('UNLOAD')
        store.commit('sponsorsCategories/RESET_BOX')
        await router.push('/sponsors')
      } catch (e) {
        ErrorService.handleError(e)
        commit('UNLOAD')
      }
    },

    async loadSponsor({ commit }, id: string) {
      commit('LOAD')
      try {
        const sponsor = await SponsorsService.getSponsorById(id)
        commit('SET_EDITING', sponsor)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async updateOrder({ commit, rootGetters }, payload: SponsorsOrderChange) {
      commit('LOAD')
      try {
        const categories = (rootGetters as RootGetters)[
          'sponsorsCategories/getSponsorsCategories'
        ]
        const sorted = payload.categories.reduce<Record<string, string[]>>(
          (acc: Record<string, string[]>, cat: string) => {
            const category = categories.find(
              (c) => c.name.toLowerCase() === cat.toLowerCase(),
            )
            const id = category ? category.nameNormalized : 'noCategory'
            acc[id] = payload.sponsorsIdsByCategories[cat].map((s) => s._id)
            return acc
          },
          {},
        )
        await SponsorsService.updateOrder(sorted)
        SnackbarService.info(i18n.tc('SPONSORS.SNACKBAR.ORDER_CHANGE'))
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },
  },
  getters: {
    getSponsors: (state) => {
      return Object.values(state.sponsors)
    },

    getSponsor: (state) => {
      return state.editingSponsor
        ? state.editingSponsor
        : {
            names: { fr: '' },
            category: '',
            url: '',
            image: null,
          }
    },
  },
})
