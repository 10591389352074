import { i18n } from '@/i18n/i18n'
import {
  type PageChoice,
  type PageChoiceData,
  type PageContent,
  type PageContentLocation,
  type PageContentWithElements,
  type PageElement,
  type PageMainImageContent,
  type PageSubMainImageContent,
} from '@/pages/menuEntries/pageSetup/pageContent.type'
import { PageContentService } from '@/pages/menuEntries/pageSetup/pageContentService'
import { PAGE_MODE, type PageMode } from '@/shared/constants'
import { ErrorService } from '@/shared/service/errorService'
import { SnackbarService } from '@/shared/snackbar/snackbar.service'

import { createStore, type GettersObj } from '../../../utils/createStore'

export type GettersPageSetup = GettersObj<typeof mapGettersPageSetup>

export class PageSetupState {
  mode: PageMode = PAGE_MODE.VIEW
  currentPage: PageContentLocation = 'HOME_PAGE'
  pageContents: Omit<PageContent, 'page'>[] = []
  pageChoices: PageChoice[] = []
  eventChoices: PageChoiceData[] = []
  currentElement:
    | {
        parentContent: PageContentWithElements
        element: PageElement | undefined
        wantedIndex: number | undefined
      }
    | undefined = undefined
  selectedContent: Omit<PageContent, 'page'> | undefined = undefined
  showEditElementModal = false
  showDeleteElementModal = false
  showEditContentModal = false
  showDeleteContentModal = false
  loading = 0
  mainImageTemp: string | null = null
  subImageTemp: string | null = null
}

export const {
  pageSetup,
  commit: commitPageSetup,
  dispatch: dispatchPageSetup,
  mapGetters: mapGettersPageSetup,
  mapState: mapStatePageSetup,
  useGetter: useGetterPageSetup,
  useState: useStatePageSetup,
} = createStore({
  moduleName: 'pageSetup',
  initState: new PageSetupState(),
  mutations: {
    LOAD(state: PageSetupState) {
      state.loading++
    },
    UNLOAD(state: PageSetupState) {
      state.loading--
    },
    SHOW_EDIT_ELEMENT_MODAL(
      state: PageSetupState,
      {
        element,
        content,
      }: { element?: PageElement; content: PageContentWithElements },
    ) {
      state.mode = PAGE_MODE.EDIT
      state.currentElement = {
        parentContent: content,
        element,
        wantedIndex: undefined,
      }
      state.showEditElementModal = true
    },
    SHOW_ADD_ELEMENT_MODAL(
      state: PageSetupState,
      { content, index }: { content: PageContentWithElements; index: number },
    ) {
      state.mode = PAGE_MODE.ADD
      state.currentElement = {
        parentContent: content,
        element: undefined,
        wantedIndex: index,
      }
      state.showEditElementModal = true
    },
    SHOW_DELETE_ELEMENT_MODAL(
      state: PageSetupState,
      {
        element,
        content,
      }: { element?: PageElement; content: PageContentWithElements },
    ) {
      state.mode = PAGE_MODE.DELETE
      state.currentElement = {
        parentContent: content,
        element,
        wantedIndex: undefined,
      }
      state.showDeleteElementModal = true
    },
    SHOW_DELETE_CONTENT_MODAL(state: PageSetupState, content: PageContent) {
      state.mode = PAGE_MODE.DELETE
      state.selectedContent = content
      state.showDeleteContentModal = true
    },
    SHOW_ADD_CONTENT_MODAL(
      state: PageSetupState,
      content: Omit<PageContent, 'page'>,
    ) {
      state.mode = PAGE_MODE.ADD
      state.selectedContent = content
      state.showEditContentModal = true
    },
    SHOW_EDIT_CONTENT_MODAL(state: PageSetupState, content: PageContent) {
      state.mode = PAGE_MODE.EDIT
      state.selectedContent = content
      state.showEditContentModal = true
    },
    GO_TO_VIEW(state: PageSetupState) {
      state.mode = PAGE_MODE.VIEW
      state.loading--
      state.currentElement = undefined
      state.showEditElementModal = false
      state.showDeleteElementModal = false
      state.showEditContentModal = false
      state.showDeleteContentModal = false
    },
    ADD_IMAGE_TO_CONTENT(state) {
      if (state.pageContents.find((page) => page.type === 'HOME_IMAGE')) {
        return
      }
      const content: PageMainImageContent = {
        image: undefined,
        type: 'HOME_IMAGE',
        videoFilename: undefined,
        page: state.currentPage,
        id: undefined,
      }
      state.pageContents = [...state.pageContents, content]
    },
    ADD_SUB_IMAGE_TO_CONTENT(state) {
      if (state.pageContents.find((page) => page.type === 'HOME_SUB_IMAGE')) {
        return
      }
      const content: PageSubMainImageContent = {
        image: undefined,
        type: 'HOME_SUB_IMAGE',
        id: undefined,
        page: state.currentPage,
      }
      state.pageContents = [...state.pageContents, content]
    },
    REMOVE_IMAGES_FROM_CONTENT(state) {
      state.pageContents = state.pageContents.filter(
        (val) => val.type !== 'HOME_IMAGE' && val.type !== 'HOME_SUB_IMAGE',
      )
    },
    SET_MAIN_IMAGE_TEMP(state, payload: string | null) {
      state.mainImageTemp = payload
    },
    SET_SUB_IMAGE_TEMP(state, payload: string | null) {
      state.subImageTemp = payload
    },
  },
  actions: {
    async loadContent({ commit, state }, page: PageContentLocation) {
      commit('LOAD')
      try {
        state.currentPage = page
        const [pageChoices, pageContents, eventChoices] = await Promise.all([
          PageContentService.getChoices(),
          PageContentService.getAll(state.currentPage),
          PageContentService.getAllEvents(),
        ])
        state.pageChoices = pageChoices
        state.eventChoices = eventChoices
        state.pageContents = pageContents
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async saveAllContent(
      { commit, state },
      contents: Omit<PageContent, 'page'>[],
    ) {
      commit('LOAD')
      try {
        state.pageContents = contents
        await PageContentService.saveAll(contents, state.currentPage)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async addContent({ commit, state }, content: Omit<PageContent, 'page'>) {
      commit('LOAD')
      try {
        const contentList = await PageContentService.saveAll(
          [...state.pageContents, content],
          state.currentPage,
        )
        // Used to retrieve the id and page of the newly created HomeContent
        state.pageContents = contentList
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async editContent({ commit, state }, content: Omit<PageContent, 'page'>) {
      commit('LOAD')
      try {
        const foundContent = state.pageContents.find(
          (homeContent) => homeContent.id === content.id,
        )
        if (!foundContent) {
          // Only a debug error, should not happen if the code is valid
          SnackbarService.error('HomeContent not found inside the list')
          return
        }
        const obj = Object.assign(foundContent, content, {
          page: state.currentPage,
        })
        await PageContentService.saveOne(obj as PageContent)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async deleteContent({ commit, state }, content: Omit<PageContent, 'page'>) {
      commit('LOAD')
      try {
        const index = state.pageContents.indexOf(content)
        if (index > -1) {
          state.pageContents.splice(index, 1)
        }
        await PageContentService.saveAll(state.pageContents, state.currentPage)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async editElement(
      { commit },
      {
        content,
        element,
      }: { content: PageContentWithElements; element: PageElement },
    ) {
      commit('LOAD')
      try {
        const foundElement = content.elements.find(
          (listElement) => listElement && listElement.id === element.id,
        )
        if (!foundElement) {
          console.error('Page element not found')
        }
        Object.assign(foundElement ?? {}, element)
        await PageContentService.saveOne(content)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async deleteElement(
      { commit },
      {
        element,
        content,
      }: { element: PageElement; content: PageContentWithElements },
    ) {
      commit('LOAD')
      try {
        const index = content.elements.indexOf(element)
        if (index > -1) {
          content.elements.splice(index, 1)
        }
        await PageContentService.saveOne(content)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async addElement(
      { commit, dispatch, state },
      {
        content,
        element,
        index,
      }: {
        content: PageContentWithElements
        element: PageElement
        index: number
      },
    ) {
      commit('LOAD')
      try {
        let maxLengthElements: number | undefined = undefined
        switch (content.type) {
          case 'HOME_CUSTOM_THIRD':
            maxLengthElements = 3
            break
          case 'HOME_CUSTOM_HALF':
            maxLengthElements = 2
            break
          case 'HOME_CUSTOM_FULL':
            maxLengthElements = 1
            break
        }
        if (
          maxLengthElements &&
          (content.elements.length > maxLengthElements ||
            index >= maxLengthElements)
        ) {
          // Only a debug error, should not happen if the code is valid
          SnackbarService.error(
            'Cannot add another element inside the menu, the array is already at max size or the set index is out of bounds',
          )
        } else {
          element.position = index
          if (!content.elements[index]) {
            content.elements.splice(index, 1, element)
          } else {
            content.elements.splice(index, 0, element)
          }
        }
        await PageContentService.saveOne(content)
        await dispatch('loadContent', state.currentPage)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async moveElement(
      { commit },
      {
        content,
        oldPosition,
        newPosition,
        element,
      }: {
        content: PageContentWithElements
        oldPosition: number
        newPosition: number
        element: PageElement
      },
    ) {
      commit('LOAD')
      try {
        if (oldPosition === newPosition) {
          return
        } else if (newPosition > oldPosition) {
          // When moving to the right
          content.elements.forEach((el) => {
            if (
              el &&
              el.position <= newPosition &&
              el.position >= oldPosition
            ) {
              el.position--
            }
          })
        } else {
          // When moving to the left
          content.elements.forEach((el) => {
            if (
              el &&
              el.position >= newPosition &&
              el.position <= oldPosition
            ) {
              el.position++
            }
          })
        }
        if (element) {
          element.position = newPosition
        }
        content.elements.sort(
          (e1, e2) => (e1 ? e1.position : 1000) - (e2 ? e2.position : 1000),
        )
        await PageContentService.saveOne(content)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },
  },
  getters: {
    pageContents: (state: PageSetupState) => {
      return state.pageContents
    },
    currentElement: (state: PageSetupState) => {
      return state.currentElement
    },
    pageChoices: (state: PageSetupState): PageChoice[] => {
      return state.pageChoices.sort((sort1: PageChoice, sort2: PageChoice) => {
        const title1 = i18n.tc(`MENU_ENTRIES.TYPE.${sort1.type}`)
        const title2 = i18n.tc(`MENU_ENTRIES.TYPE.${sort2.type}`)
        return title1.localeCompare(title2)
      })
    },
    selectedContent: (state: PageSetupState) => {
      return state.selectedContent
    },
    showEditElementModal: (state: PageSetupState) => {
      return state.showEditElementModal
    },
    showDeleteElementModal: (state: PageSetupState) => {
      return state.showDeleteElementModal
    },
    showEditContentModal: (state: PageSetupState) => {
      return state.showEditContentModal
    },
    showDeleteContentModal: (state: PageSetupState) => {
      return state.showDeleteContentModal
    },
  },
})
