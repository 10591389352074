import { createStore, type GettersObj } from '../../utils/createStore'

export type GettersPublications = GettersObj<typeof mapGettersPublications>
export class PublicationsState {
  showPublicationModal = false
  showPublishModal = false
}

export const {
  publications,
  mapGetters: mapGettersPublications,
  commit: commitPublications,
  useState: useStatePublications,
  useGetter: useGetterPublications,
  mapState: mapStatePublications,
  dispatch: dispatchPublications,
} = createStore({
  namespaced: true,
  moduleName: 'publications',
  initState: new PublicationsState(),
  mutations: {
    showPublicationModal(state) {
      state.showPublicationModal = true
    },

    hidePublicationModal(state) {
      state.showPublicationModal = false
    },

    showPublishModal(state) {
      state.showPublishModal = true
    },

    hidePublishModal(state) {
      state.showPublishModal = false
    },
  },
  actions: {},
  getters: {},
})
