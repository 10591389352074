import { type RouteConfig } from 'vue-router'

import { dispatchApplicationCfg } from '../applicationCfg/store/applicationCfg.store'
import { dispatchFestival } from '../festival/festival.store'
import { dispatchProgEvent } from '../progEvent/progEvent.store'
import { dispatchMenuEntries } from './menuEntries.store'

export const MenuRouter: RouteConfig[] = [
  {
    path: '/menuEntries',
    component: async () => import('./MenuEntries.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await Promise.all([
        dispatchApplicationCfg('loadApplicationCfg'),
        dispatchFestival('loadFestival'),
        dispatchProgEvent('list'),
        dispatchMenuEntries('getAllContent'),
        dispatchMenuEntries('getAllChoices'),
      ])
      next()
    },
  },
]
