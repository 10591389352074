import { createStore, type GettersObj } from '../../utils/createStore'

export type GettersAppBar = GettersObj<typeof mapGettersAppBar>

export class AppBarState {
  text = ''
}

export const {
  appBar,
  mapGetters: mapGettersAppBar,
  commit: commitAppBar,
  mapState: mapStateAppBar,
  useState: useStateAppBar,
  dispatch: dispatchAppBar,
  useGetter: useGetterAppBar,
} = createStore({
  namespaced: false,
  initState: new AppBarState(),
  moduleName: 'appBar',
  getters: {},
  actions: {},
  mutations: {
    SET_TEXT(state: AppBarState, text: string) {
      state.text = text
    },
  },
})
