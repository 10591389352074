import axios from 'axios'

import { type TranslatableText } from '../../../types/TranslatableText.type'
import { i18n } from '../../i18n/i18n'
import { type ExportUrl } from '../../pages/import/store/import.type'
import { type MarkerIcon } from '../../pages/map/map.type'
import { entries, fromEntries, isNotNullish } from '../../utils'

export const SOCIAL_LINKS = [
  'website',
  'facebook',
  'twitter',
  'instagram',
  'myspace',
  'soundcloud',
  'bandcamp',
  'deezer',
  'spotify',
  'youtubeChannel',
  'youtubeVideoKey',
  'vimeo',
  'linkedin',
  'residentAdvisor',
  'twitch',
  'tiktok',
] as const

export type SocialLinks = (typeof SOCIAL_LINKS)[number]

export type SocialLinkDB = { [key in SocialLinks]?: string }

export type LinkEntry = {
  text: string
  value: SocialLinks
  url: string
}

export type SocialLink = {
  [key in SocialLinks]?: LinkEntry
}

export type Contact = {
  name: string
  address: string
  tel: string
  mail: string
}

export type Festival = {
  _id?: string
  name?: string
  links?: SocialLink
  startDate?: string
  endDate?: string
  locationName?: string
  locationCoords?: {
    /* number encoded in string ¯\_(ツ)_/¯ */
    lat: string
    /* number encoded in string ¯\_(ツ)_/¯ */
    lng: string
  }
  contact?: Contact
  alwaysShowEventHour?: boolean
  showEventHourDelay?: number
  descriptions?: TranslatableText
  markerIcons: MarkerIcon[]
  gcExportUrls: ExportUrl[]
  orderEventsByEndDate: boolean
  eventsHideEndDate: boolean
  saferApiKey?: string
}

type FestivalDB = Omit<Festival, 'links'> & { links?: SocialLinkDB }

export const FestivalService = {
  async getFestival(): Promise<Festival> {
    const { data } = await axios.get<FestivalDB>('/api/festival')
    return festivalToJsFormat(data)
  },

  async updateMarkerIcons(markerIcons: MarkerIcon[]): Promise<Festival> {
    const { data } = await axios.put(
      '/api/festival/updateMarkerIcons',
      markerIcons,
    )
    return data
  },

  async updateFestival(festival: Festival): Promise<Festival> {
    const { data } = await axios.put<FestivalDB>(
      '/api/festival',
      festivalToDbFormat(festival),
    )
    return festivalToJsFormat(data)
  },
}

function festivalToJsFormat(festival: FestivalDB): Festival {
  // Filter out nullish values and map the entries to the desired format
  const links: SocialLink = fromEntries(
    entries(festival.links ?? {})
      .filter(([_key, value]) => isNotNullish(value))
      .map(([key, value]) => {
        return [
          key,
          {
            value: key,
            url: value!,
            text: i18n.tc(`GUESTS.ADDING.LINKS_LIST.${key.toUpperCase()}`),
          },
        ]
      }),
  )

  return {
    ...festival,
    links,
  }
}

function festivalToDbFormat(festival: Festival): FestivalDB {
  // Convert the links object back to the original format
  const links = fromEntries(
    Object.values(festival.links ?? {}).map((link) => [link.value, link.url]),
  )

  return {
    ...festival,
    links,
  }
}
