export type GEDFile = {
  internalType: InternalType
  _id: string
  contentType: string
  extension: string
  fileName: string
  owner: string
}

export enum InternalType {
  PICTURE = 'PICTURE',
  MAP_MARKER = 'MAP_MARKER',
  MAP_OVERLAY = 'MAP_OVERLAY',
  SPONSOR = 'SPONSOR',
  PICTURE_IN_RICH_TEXT = 'PICTURE_IN_RICH_TEXT',
  EXTRACT = 'EXTRACT',
  VIDEO = 'VIDEO',
  FILE = 'FILE',
}
