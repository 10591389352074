import { ErrorService } from '@/shared/service/errorService'

import { i18n } from '../../i18n/i18n'
import { SnackbarService } from '../../shared/snackbar/snackbar.service'
import { createStore, type GettersObj } from '../../utils/createStore'
import { ResetAppService } from './resetApp.service.js'

export type GettersResetApp = GettersObj<typeof mapGettersResetApp>

export class ResetAppState {
  fullReset = false
  showModal = false
}

export const {
  resetApp,
  commit: commitResetApp,
  dispatch: dispatchResetApp,
  mapState: mapStateResetApp,
  mapGetters: mapGettersResetApp,
  useGetter: useGetterResetApp,
  useState: useStateResetApp,
} = createStore({
  namespaced: true,
  moduleName: 'resetApp',
  initState: new ResetAppState(),
  getters: {},
  mutations: {
    SET_FULL_RESET(state, val: boolean) {
      state.fullReset = val
    },

    RESET(state) {
      state.fullReset = false
      state.showModal = false
    },

    SHOW_MODAL(state) {
      state.showModal = true
    },
  },

  actions: {
    async reset({ commit, state }) {
      try {
        await ResetAppService.resetApp(state.fullReset)
        SnackbarService.info(i18n.tc('RESET_APP.SNACKBAR_MESSAGE'))
        commit('RESET')
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    async resetMenu() {
      try {
        await ResetAppService.resetMenu()
        SnackbarService.info(i18n.tc('RESET_APP.MENU_RESET_CONFIRM'))
      } catch (e) {
        ErrorService.handleError(e)
      }
    },
  },
})
