import cloneDeep from 'lodash/cloneDeep'
import groupBy from 'lodash/groupBy'

import { i18n } from '@/i18n/i18n'
import { AttendeesService } from '@/pages/attendee/attendees.service'
import { GuestsService } from '@/pages/guests/guests.service'
import router from '@/router'
import { ErrorService } from '@/shared/service/errorService'
import {
  FilesService,
  type ImageTransformations,
} from '@/shared/service/files.service'
import { SnackbarService } from '@/shared/snackbar/snackbar.service'
import {
  type Attendee,
  type AttendeeWithRateAndComment,
} from '@/shared/types/attendee.type'
import { type Pagination } from '@/shared/types/pagination.type'
import { type ProgEvent } from '@/shared/types/progEvent.type'
import { type Program } from '@/shared/types/program.type'
import { type Scene } from '@/shared/types/scene.type'

import { type RootGetters } from '../../store'
import { isNotNullish } from '../../utils'
import { createStore, type GettersObj } from '../../utils/createStore'
import {
  commitGuestGenres,
  dispatchGuestGenres,
} from '../guests/store/guestgenres.store'
import { ProgEventService, type ProgramEventUsage } from './progEvent.service'

export type ImportResult = {
  successes: ImportResultMessage[]
  errors: ImportResultMessage[]
  toProcess: ImportResultMessage[]
}
export type ImportResultMessage = {
  key: string
  content: Record<string, unknown>
}

export type GettersProgEvent = GettersObj<typeof mapGettersProgEvent>

export class ProgEventState {
  showMultipleDeleting = false
  showMultipleEdit = false
  progEventList: Record<string, ProgEvent> = {}
  loading = false
  deletingEventId: null | string = null
  gridMode = false

  selectedEventIdForDetails = ''
  attendeesList: AttendeeWithRateAndComment[] = []

  options: Pagination = {} as Pagination

  search = {
    guest: '',
    program: '',
    scene: '',
    day: '',
  }
  selectedProgram: Program | null = null
  editingProgEvent: ProgEvent | null = null
  selectedRows: ProgEvent[] = []
  image: any = null
  transformations: ImageTransformations | null = null

  importBatchId = ''
  importFeedback: ImportResult = {
    successes: [],
    errors: [],
    toProcess: [],
  }

  togglingVisibilityEventId: null | string = null
  showVisibilityModal = false
  batchShowMode = null as null | boolean
  progEventUsage: ProgramEventUsage = {
    pageContents: [],
  }
}

export const {
  commit: commitProgEvent,
  dispatch: dispatchProgEvent,
  mapGetters: mapGettersProgEvent,
  mapState: mapStateProgEvent,
  useGetter: useGetterProgEvent,
  useState: useStateProgEvent,
  progEvent,
} = createStore({
  moduleName: 'progEvent',
  initState: new ProgEventState(),
  mutations: {
    SET_PROG_EVENT_LIST(state, progEventList: ProgEvent[]) {
      state.progEventList = progEventList.reduce(
        (acc: Record<string, ProgEvent>, progEvent) => {
          acc[progEvent._id] = progEvent
          return acc
        },
        {},
      )
    },

    LOAD(state) {
      state.loading = true
    },

    UNLOAD(state) {
      state.loading = false
    },

    SET_SEARCH_GUEST(state, guest: string) {
      state.search.guest = guest
      state.options.page = 1
    },

    SET_SEARCH_PROGRAM(state, program: string) {
      state.search.program = program
      state.options.page = 1
    },

    SET_SEARCH_SCENE(state, scene: string) {
      state.search.scene = scene
      state.options.page = 1
    },

    SET_SEARCH_DAY(state, day: string) {
      state.search.day = day
      state.options.page = 1
    },

    SET_DELETING(state, progEventId: string) {
      state.deletingEventId = progEventId
    },

    SET_NOT_DELETING(state) {
      state.deletingEventId = null as unknown as string
    },

    SET_SHOW_MULTIPLE_DELETING(state, show: boolean) {
      state.showMultipleDeleting = show
    },

    SET_SHOW_MULTIPLE_EDITING(state, show: boolean) {
      state.showMultipleEdit = show
    },

    RESET_MULTI_SELECTION(state) {
      state.selectedRows = []
    },

    CHANGE_DISPLAY(state) {
      state.gridMode = !state.gridMode
    },

    SET_PROGRAM(state, program: Program) {
      state.selectedProgram = program
    },

    SET_IMAGE(state, fileName: string) {
      state.image = fileName
    },

    SET_IMAGE_TRANSFORMATIONS(
      state,
      transformations: ImageTransformations | null,
    ) {
      state.transformations = transformations
    },

    RESET(state) {
      state.image = null
      state.editingProgEvent = null as unknown as ProgEvent
      commitGuestGenres('RESET')
    },

    SET_EDITING(state, progEvent: ProgEvent) {
      state.editingProgEvent = progEvent
      state.image = progEvent.image
    },

    SET_DUPLICATE(state, progEvent: ProgEvent) {
      progEvent._id = ''
      state.editingProgEvent = progEvent
    },

    SET_OPTIONS(state, options: Pagination) {
      state.options = options
    },

    SET_SELECTED_ROWS(state, progEvents: ProgEvent[]) {
      state.selectedRows = progEvents
    },

    //Attending

    SET_EVENT_ID_FOR_DETAILS(state, id: string) {
      state.selectedEventIdForDetails = id
    },

    RESET_EVENT_ID_FOR_DETAILS(state) {
      state.selectedEventIdForDetails = ''
      state.attendeesList = []
    },

    SET_ATTENDEES_LIST(state, attendeesList: Attendee[]) {
      state.attendeesList = attendeesList.map((attendee) => {
        const tmp = state.progEventList[
          state.selectedEventIdForDetails
        ].eventAttendeesList.filter(
          (a) => a.firebaseUserId === attendee.firebaseUserId,
        )
        const newAttendee: AttendeeWithRateAndComment = {
          ...attendee,
        } as AttendeeWithRateAndComment
        newAttendee.comment = tmp.at(0)?.comment ?? ''
        newAttendee.rate = tmp.at(0)?.rate ?? -1
        return newAttendee
      })
    },

    SET_IMPORT_FEEDBACK(state, importResult: ProgEventState['importFeedback']) {
      state.importFeedback = importResult
    },

    SET_IMPORT_BATCH_ID(state, importBatchId: string) {
      state.importBatchId = importBatchId
    },
    CLOSE_VISIBILITY_MODAL(state) {
      state.showVisibilityModal = false
      state.togglingVisibilityEventId = null
      state.progEventUsage = {
        pageContents: [],
      }
    },
  },
  actions: {
    async list() {
      commitProgEvent('LOAD')
      try {
        const progEventList = await ProgEventService.getAll()
        commitProgEvent('SET_PROG_EVENT_LIST', progEventList)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commitProgEvent('UNLOAD')
      }
    },

    async initHide({ state }, programEventId: string) {
      // fetch usage only when hidding the event
      const usage = state.progEventList[programEventId].isVisible
        ? await ProgEventService.checkUsage([programEventId])
        : {
            pageContents: [],
          }
      // if used somewhere, show confirmation dialog
      if (usage.pageContents.length > 0) {
        state.togglingVisibilityEventId = programEventId
        state.progEventUsage = usage
        state.showVisibilityModal = true
      } else {
        // else hide directly
        await dispatchProgEvent('hide', programEventId)
      }
    },

    async hide({ state }, programEventId: string) {
      commitProgEvent('LOAD')
      try {
        const programEvent = cloneDeep(state.progEventList[programEventId])
        programEvent.isVisible = !programEvent.isVisible
        await ProgEventService.updateProgramEvent(programEvent)
        const message = programEvent.isVisible
          ? i18n.tc('PROGRAMMATION.SNACK_BAR.HIDE_OFF', undefined, {
              string: programEvent.titles?.fr ?? programEvent.fakeTitle,
            })
          : i18n.tc('PROGRAMMATION.SNACK_BAR.HIDE', undefined, {
              string: programEvent.titles?.fr ?? programEvent.fakeTitle,
            })
        SnackbarService.info(message)
        await dispatchProgEvent('list')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commitProgEvent('CLOSE_VISIBILITY_MODAL')
      }
    },

    async delete({ state }) {
      commitProgEvent('LOAD')
      try {
        if (!state.deletingEventId) return
        await ProgEventService.deleteProgramEvent(state.deletingEventId)
        SnackbarService.info(
          i18n.tc('PROGRAMMATION.SNACK_BAR.DELETE', undefined, {
            string:
              state.progEventList[state.deletingEventId].titles?.fr ??
              state.progEventList[state.deletingEventId].fakeTitle,
          }),
        )
        await dispatchProgEvent('list')
        commitProgEvent('SET_NOT_DELETING')
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    async uploadImage(_, file: File) {
      commitProgEvent('LOAD')
      try {
        const savedImage = await FilesService.uploadImageFile(file)
        commitProgEvent('SET_IMAGE', savedImage.filename)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commitProgEvent('UNLOAD')
      }
    },

    async initHideShowBatch({ state }, show: boolean) {
      // fetch usage only when hidding the event
      const usage = !show
        ? await ProgEventService.checkUsage(
            state.selectedRows.map((e) => e._id),
          )
        : {
            pageContents: [],
          }
      // if used somewhere, show confirmation dialog
      if (usage.pageContents.length > 0) {
        state.progEventUsage = usage
        state.showVisibilityModal = true
        state.batchShowMode = show
      } else {
        // else hide directly
        await dispatchProgEvent('hideShowBatch', show)
      }
    },

    async hideShowBatch({ state }, show: boolean) {
      commitProgEvent('LOAD')
      try {
        const nb = state.selectedRows.length
        await ProgEventService.hideShowBatch(
          state.selectedRows.map((e) => e._id),
          show,
        )
        await dispatchProgEvent('list')
        show
          ? SnackbarService.info(
              i18n.tc('PROGRAMMATION.SNACK_BAR.BATCH_SHOW', undefined, {
                string: nb,
              }),
            )
          : SnackbarService.info(
              i18n.tc('PROGRAMMATION.SNACK_BAR.BATCH_HIDE', undefined, {
                string: nb,
              }),
            )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commitProgEvent('RESET_MULTI_SELECTION')
        commitProgEvent('UNLOAD')
        commitProgEvent('CLOSE_VISIBILITY_MODAL')
      }
    },
    async editShowBatch(
      { state },
      { programId, sceneId }: { programId?: string; sceneId?: string },
    ) {
      commitProgEvent('LOAD')
      ;('LOAD')
      try {
        const nb = state.selectedRows.length
        await ProgEventService.editShowBatch(
          state.selectedRows.map((e) => e._id),
          programId,
          sceneId,
        )
        await dispatchProgEvent('list')
        SnackbarService.info(
          i18n.tc('PROGRAMMATION.SNACK_BAR.BATCH_EDIT', undefined, {
            string: nb,
          }),
        )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commitProgEvent('RESET_MULTI_SELECTION')
        commitProgEvent('UNLOAD')
      }
    },
    async multipleDelete({ state }) {
      commitProgEvent('LOAD')
      try {
        const nb = state.selectedRows.length
        await ProgEventService.multipleDelete(
          state.selectedRows.map((e) => e._id),
        )
        await dispatchProgEvent('list')
        SnackbarService.info(
          i18n.tc('PROGRAMMATION.SNACK_BAR.MULTIPLE_DELETE', undefined, {
            string: nb,
          }),
        )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commitProgEvent('SET_SHOW_MULTIPLE_DELETING', false)
        commitProgEvent('RESET_MULTI_SELECTION')
      }
    },
    async addProgEvent({ state, rootGetters }, progEvent: ProgEvent) {
      commitProgEvent('LOAD')
      try {
        const event = cloneDeep(progEvent)
        event.image = state.image
        event.genres = (rootGetters as RootGetters)[
          'guestGenres/getSelectedGenres'
        ]
        if (event.musicGroups) {
          event.musicGroups = await Promise.all(
            event.musicGroups.map(async (group) => {
              return await GuestsService.getGuestById(group._id!)
            }),
          )
        }

        let message = ''
        if (state.editingProgEvent) {
          await ProgEventService.updateProgramEvent(event)
          message = i18n.tc('PROGRAMMATION.SNACK_BAR.EDIT', undefined, {
            string: event.titles?.fr ?? event.fakeTitle,
          })
        } else {
          await ProgEventService.addProgramEvent(event)
          message = i18n.tc('PROGRAMMATION.SNACK_BAR.ADD', undefined, {
            string: event.titles?.fr ?? event.fakeTitle,
          })
        }

        if (state.transformations) {
          await FilesService.setImageTransform(
            state.transformations.imageId,
            state.transformations,
          )
        }
        SnackbarService.info(message)
        await dispatchProgEvent('list')
        await router.push('/programmation')
      } catch (e) {
        ErrorService.handleError(e)
        commitProgEvent('UNLOAD')
      }
    },

    async loadProgEvent(
      { rootGetters },
      data: { id: string; loadType: 'edit' | 'duplicate' },
    ) {
      commitProgEvent('LOAD')
      try {
        await dispatchGuestGenres('getGenres')
        const progEvent = await ProgEventService.getProgramEventById(data.id)

        commitProgEvent(
          data.loadType === 'edit' ? 'SET_EDITING' : 'SET_DUPLICATE',
          progEvent,
        )
        if (progEvent.image) {
          const transformations = await FilesService.getImageTransform(
            progEvent.image,
          )
          commitProgEvent('SET_IMAGE_TRANSFORMATIONS', transformations)
        }
        commitGuestGenres(
          'SET_GENRES_SELECTION',
          progEvent.genres
            ?.map((genre: string) =>
              (rootGetters as RootGetters)['guestGenres/getGenreById'](genre),
            )
            .filter(isNotNullish) ?? [],
        )
        commitProgEvent('UNLOAD')
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    async loadAttendees({ state }) {
      try {
        commitProgEvent('LOAD')
        const attendeesList = await AttendeesService.getAllByFirebaseUserIds(
          state.progEventList[
            state.selectedEventIdForDetails
          ].eventAttendeesList.map((e) => e.firebaseUserId),
        )

        commitProgEvent('SET_ATTENDEES_LIST', attendeesList)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commitProgEvent('UNLOAD')
      }
    },

    async saveFile(_, file: Blob): Promise<void> {
      commitProgEvent('LOAD')
      await new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (): void => {
          resolve(reader.result)
        }
        reader.onerror = (error): void => {
          console.error(error)
          reject(
            error instanceof Error ? error : new Error('Error reading file'),
          )
        }
      }).then(async (x64File) => {
        try {
          const batchId = await ProgEventService.uploadFile(x64File)
          commitProgEvent('SET_IMPORT_BATCH_ID', batchId)
          await dispatchProgEvent('updateImportList', batchId)
        } catch (e) {
          ErrorService.handleError(e)
          commitProgEvent('UNLOAD')
        }
      })
    },

    async updateImportList(_, batchId: string): Promise<void> {
      const importResult = await ProgEventService.getImportResult(batchId)
      commitProgEvent('SET_IMPORT_FEEDBACK', importResult)
      if (importResult.toProcess.length > 0) {
        await new Promise((res) => setTimeout(res, 7000))
        await dispatchProgEvent('updateImportList', batchId)
      } else {
        commitProgEvent('UNLOAD')
      }
    },

    resetImport() {
      commitProgEvent('SET_IMPORT_FEEDBACK', {
        successes: [],
        errors: [],
        toProcess: [],
      })
      commitProgEvent('SET_IMPORT_BATCH_ID', '')
    },
  },
  getters: {
    getProgEventList: (state): ProgEvent[] => {
      return Object.values(state.progEventList)
    },
    getSelectedEventIds: (state) => state.selectedRows.map((e) => e._id),

    getSelectedCount: (state) => {
      return state.selectedRows.length
    },
    getProgEvent: (state) => {
      return state.editingProgEvent
        ? state.editingProgEvent
        : {
            musicGroups: null,
            scene: null,
            day: null,
            titles: { fr: null },
            image: null,
            copyright: null,
            descriptions: {
              fr: '',
              en: '',
            },
            genres: [],
            eventTicketLink: null,
            startTime: '12:00',
            endTime: '13:00',
            date: null,
            isVisible: false,
            program: null,
            enableAttending: false,
            enableRating: false,
            programEventNotifSendTime: 'NEVER',
            notifPlanned: false,
            hideEndDate: false,
          }
    },

    getFilteredEventList: (state) => {
      const { search } = state
      const { guest, program, scene, day } = search

      const includesIgnoreCase = (str: string, substr: string): boolean =>
        str.toLowerCase().includes(substr.toLowerCase())

      const filteredEvents = Object.values(state.progEventList).filter(
        (event) => {
          if (
            guest &&
            !includesIgnoreCase(event.titles?.fr ?? '', guest) &&
            !event.musicGroups?.some((group) =>
              includesIgnoreCase(group.names.fr, guest),
            )
          ) {
            return false
          }
          if (program && event.program._id !== program) return false
          if (scene && event.scene._id !== scene) return false
          if (day && event.day !== day) return false

          return true
        },
      )

      return filteredEvents
    },

    getDays: (state) => {
      return Object.values(state.progEventList)
        .reduce((acc: string[], event) => {
          acc.includes(event.day) ? '' : acc.push(event.day)
          return acc
        }, [])
        .sort((a, b) => {
          return +(a > b) || -(a < b)
        })
    },

    getDaysByProgram: (state) => {
      return (programId: string): string[] => {
        const tab = cloneDeep(
          Object.values(state.progEventList).filter(
            (n) => n.program._id === programId,
          ),
        )

        return tab
          .reduce((acc: string[], event) => {
            acc.includes(event.day) ? '' : acc.push(event.day)
            return acc
          }, [])
          .sort((a, b) => {
            return +(a > b) || -(a < b)
          })
      }
    },

    getSelectedProgram: (state, _getters, _rootState) => {
      return state.selectedProgram
        ? state.selectedProgram
        : Object.values(_rootState.programs.programs)[0]
    },

    getItems: (state) => {
      return (
        selectedProgram: Program,
      ): (Record<string, ProgEvent> & {
        scene: string
      })[] => {
        const events = cloneDeep(
          Object.values(state.progEventList).filter(
            (p) => p.program._id === selectedProgram._id,
          ),
        )

        const listScene = events.reduce((acc: Scene[], event) => {
          acc.filter((s) => s._id === event.scene._id).length > 0
            ? ''
            : acc.push(event.scene)
          return acc
        }, [])

        return listScene.map((scene) => {
          return {
            ...groupBy(
              events.filter((n) => n.scene._id === scene._id),
              (event) => event.day,
            ),
            scene: scene.names.fr,
          } as Record<string, ProgEvent> & {
            scene: string
          }
        })
      }
    },

    getProgEventDetail: (state) =>
      state.progEventList[state.selectedEventIdForDetails],
  },
})
