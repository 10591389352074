<template>
  <v-row class="snackbar-div">
    <v-snackbar
      class="stickySnackbar"
      :value="visible"
      :color="color"
      :timeout="-1"
      top
    >
      {{ message }}
    </v-snackbar>
  </v-row>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import { mapStateSnackbar } from './snackbar.store'

export default defineComponent({
  computed: {
    ...mapStateSnackbar({
      visible: (state) => state.visible,
      message: (state) => state.message,
      color: (state) => state.color,
    }),
  },
})
</script>
<style scoped lang="scss">
.stickySnackbar {
  position: absolute;
}

.snackbar-div {
  height: 0px;
  width: 100%;
  top: 0px;
  position: sticky;
  z-index: 11;
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
