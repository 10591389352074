import { type RouteConfig } from 'vue-router'

import { dispatchGuestGenres } from './store/guestgenres.store'

export const GenresRouter: RouteConfig[] = [
  {
    path: '/genres',
    component: async () => import('./Genres.vue'),
    async beforeEnter(_to, _from, next): Promise<void> {
      await dispatchGuestGenres('getGenres')
      next()
    },
    children: [],
  },
]
