import { ErrorService } from '@/shared/service/errorService'
import { type ProgEvent } from '@/shared/types/progEvent.type'
import { type Program } from '@/shared/types/program.type'

import { makeTranslatableText } from '../../../types/TranslatableText.type'
import { i18n } from '../../i18n/i18n'
import { SnackbarService } from '../../shared/snackbar/snackbar.service'
import { createStore } from '../../utils/createStore'
import { type GettersObj } from '../../utils/createStore'
import { ProgEventService } from '../progEvent/progEvent.service'
import { ProgramsService } from './programs.service'

export type GettersPrograms = GettersObj<typeof mapGettersPrograms>

export class ProgramsState {
  programs: Record<string, Program> = {}
  deletingProgramId: string | null = null
  editingProgramId: string | null = null
  loading = false
  showModal = false
  events: ProgEvent[] = []
}

export const {
  programs,
  commit: commitPrograms,
  dispatch: dispatchPrograms,
  mapGetters: mapGettersPrograms,
  mapState: mapStatePrograms,
  useGetter: useGetterPrograms,
  useState: useStatePrograms,
} = createStore({
  namespaced: true,
  moduleName: 'programs',
  initState: new ProgramsState(),
  mutations: {
    LOAD(state) {
      state.loading = true
    },

    UNLOAD(state) {
      state.loading = false
    },

    SET_DELETING(state, id: string) {
      state.deletingProgramId = id
    },

    SET_NOT_DELETING(state) {
      state.deletingProgramId = null
    },

    SET_ADDING(state) {
      state.showModal = true
    },

    SET_EDITING(state, id: string) {
      state.editingProgramId = id
      state.showModal = true
    },

    RESET(state) {
      state.showModal = false
      state.editingProgramId = null
    },
  },
  actions: {
    async list({ commit, state }) {
      commit('LOAD')
      try {
        const programs = await ProgramsService.getAll()
        state.programs = programs.reduce(
          (acc: Record<string, Program>, program) => {
            acc[program._id] = program
            return acc
          },
          {},
        )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async setDeleting({ state, commit }, id: string) {
      commit('LOAD')
      try {
        state.deletingProgramId = id
        const events = await ProgEventService.getProgramEventByProgram(
          state.deletingProgramId,
        )
        state.events = events
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async deleteProgram({ state, commit, dispatch }) {
      commit('LOAD')
      try {
        await ProgramsService.delete(state.deletingProgramId!)
        SnackbarService.info(
          i18n.tc('PROGRAMS.SNACK_BAR.DELETE', undefined, {
            string: state.programs[state.deletingProgramId!].names.fr,
          }),
        )
        await dispatch('list')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_NOT_DELETING')
      }
    },

    async addProgram({ commit, state, dispatch }, program: Program) {
      commit('LOAD')
      try {
        let message = ''
        if (state.editingProgramId) {
          await ProgramsService.updateProgram(program)
          message = i18n.tc('PROGRAMS.SNACK_BAR.EDIT', undefined, {
            string: program.names.fr,
          })
        } else {
          await ProgramsService.addProgram(program)
          message = i18n.tc('PROGRAMS.SNACK_BAR.ADD', undefined, {
            string: program.names.fr,
          })
        }
        SnackbarService.info(message)
        await dispatch('list')
        commit('RESET')
      } catch (e) {
        ErrorService.handleError(e)
      }
    },
  },
  getters: {
    getPrograms: (state): Program[] =>
      Object.values(state.programs)
        .map(toProgramModel)
        .sort((a, b) => a.names.fr.localeCompare(b.names.fr)),

    getProgram: (state): Program => {
      return toProgramModel(
        state.editingProgramId
          ? state.programs[state.editingProgramId]
          : {
              names: makeTranslatableText(''),
              color: '#FFFFFFFF',
              isDeletable: true,
            },
      )
    },

    isLastMenuEntry: (state) => {
      return state.editingProgramId
        ? Object.values(state.programs).filter((n) => !n.isDeletable).length ===
            1
        : false
    },
  },
})

function toProgramModel(
  rawProgram: Pick<Program, 'names' | 'color' | 'isDeletable'>,
): Program {
  return {
    ...rawProgram,
  } as Program
}
