import Vue from 'vue'
import VueI18n from 'vue-i18n'

import fr_default from './fr_default.json'
import fr_festival from './fr_festival.json'

Vue.use(VueI18n)

const languages = {
  fr_default,
  fr_festival,
}
//In dev env, we preload all languages for the HMR
if (import.meta.hot) {
  languages.fr_festival = await import('./fr_festival.json')

  // Hot updates
  import.meta.hot.accept(
    ['./fr_default.json', './fr_festival.json'],
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    async () => {
      i18n.setLocaleMessage('fr_default', await import('./fr_default.json'))
      i18n.setLocaleMessage('fr_festival', await import('./fr_festival.json'))
    },
  )
}

const messages = Object.assign(languages)

export const i18n = new VueI18n({
  locale: 'fr_default',
  fallbackLocale: 'fr_default',
  messages,
  silentFallbackWarn: true,
})

// FIXME feature disabled https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
// const loadedLanguages = ["fr_default"]; // our default language that is preloaded
// export async function loadLanguageAsync(lang: string) {
//   if (i18n.locale !== lang) {
//     if (!import.meta.hot && !loadedLanguages.includes(lang)) {
//       const msgs = await import(
//         /* webpackChunkName: "lang-[request]" */ `./${lang}.json`
//       );
//       i18n.setLocaleMessage(lang, msgs);
//       loadedLanguages.push(lang);
//     }
//     i18n.locale = lang;
//   }
// }
