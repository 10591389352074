import { type RouteConfig } from 'vue-router'

import { dispatchStoresInformations } from './storesInformations.store'

export const StoresInformationsRouter: RouteConfig[] = [
  {
    path: '/store',
    component: async () => import('./StoresInformations.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await dispatchStoresInformations('loadAppStore')
      next()
    },
  },
]
