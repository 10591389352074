<template>
  <v-dialog
    value="true"
    persistent
    v-bind="$attrs"
    :max-width="maxWidth"
    :fullscreen="getIsMobile"
    :content-class="overflowing ? 'forced-modal-overflow' : ''"
  >
    <v-card>
      <v-card-title class="headline">
        {{ title }}
        <v-spacer />
        <v-icon class="icon-close-modal" @click="cancelAction()"
          >fa-times</v-icon
        >
      </v-card-title>
      <v-card-text v-if="subtitle" class="subtitle">
        {{ subtitle }}
      </v-card-text>
      <v-container>
        <v-row align="center" justify="center">
          <v-col :md="md">
            <slot />
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions class="action-btn">
        <v-btn
          v-if="!hideCancelButton"
          color="#E4E4E4"
          depressed
          @click="cancelAction()"
        >
          {{ $tc('GENERAL.CANCEL') }}
        </v-btn>
        <v-btn
          :loading="loading"
          :color="btnOkColor"
          :disabled="btnOkDisabled"
          depressed
          @click="okAction()"
        >
          {{ btnOkLabel || $tc('GENERAL.OK') }}
        </v-btn>
        <slot name="additionalButtons" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import { mapGettersIsMobile } from '../../../store/isMobile.store'

export default defineComponent({
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    btnOkColor: {
      type: String,
      required: false,
      default: 'success',
    },
    btnOkLabel: {
      type: String,
      required: false,
    },
    btnOkDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideCancelButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    maxWidth: {
      type: String,
      default: '1200',
    },
    md: {
      default: '6',
    },
    loading: {
      default: false,
    },
    overflowing: {
      default: false,
      type: Boolean,
    },
  },

  created() {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        this.okAction()
      } else if (e.key === 'Escape') {
        this.cancelAction()
      }
    })
  },

  computed: {
    ...mapGettersIsMobile(['getIsMobile']),
  },

  methods: {
    cancelAction() {
      this.$emit('cancelAction')
    },
    okAction() {
      this.$emit('okAction')
    },
  },
})
</script>
<style scoped lang="scss">
.headline {
  color: var(--dark-text);
  border-bottom: 1px solid rgb(220, 220, 220);
  padding-top: 26px;
  word-break: keep-all;
}

.icon-close-modal {
  position: absolute;
  top: 15px;
  right: 15px;
}

.dialog-no-margin {
  margin: 0px !important;
}

.subtitle {
  color: var(--dark-text);
  font-size: large;
}

.action-btn {
  border-top: 1px solid rgb(220, 220, 220);
  justify-content: center;
  padding-bottom: 1vw;
  padding-top: 1vw;
}
</style>
