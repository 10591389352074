import { commitSnackbar } from './snackbar.store'

function showSnackbar(message: string, color: string): void {
  commitSnackbar('SHOW', { message, color })
}

export const SnackbarService = {
  info(message: string): void {
    showSnackbar(message, 'green')
  },
  warn(message: string): void {
    showSnackbar(message, 'orange')
  },
  error(message: string): void {
    showSnackbar(message, 'red')
  },
}
