import { i18n } from '@/i18n/i18n'

import { SnackbarService } from '../snackbar/snackbar.service'

export const ErrorService = {
  handleError(error: any, showError = true): void {
    if (showError) {
      SnackbarService.error(i18n.tc('GENERAL.SNACKBAR_ERROR'))
      console.log(error)
    }
  },
}
