import axios from 'axios'

import { type GEDFile } from '../../shared/types/files.type'

export const OrphanedService = {
  async getAll(): Promise<GEDFile[]> {
    const { data } = await axios.get('/api/files/orphaned')
    return data
  },

  async delete(fileId: string): Promise<void> {
    await axios.delete(`/api/files/${fileId}`)
  },

  async deleteAll(): Promise<void> {
    await axios.delete('/api/files/orphaned')
  },

  async compressAll(): Promise<void> {
    await axios.put('/api/files/compress')
  },
}
