import cloneDeep from 'lodash/cloneDeep'

import { ErrorService } from '@/shared/service/errorService'

import { i18n } from '../../i18n/i18n'
import router from '../../router'
import { SnackbarService } from '../../shared/snackbar/snackbar.service'
import { createStore, type GettersObj } from '../../utils/createStore'
import { type User } from './users.service'
import { UsersService } from './users.service'

export type GettersUsers = GettersObj<typeof mapGettersUsers>

export class UsersState {
  users: Record<string, User> = {}
  loading = false
  search = ''
  deletingUserId: string | null = null
  editingUser: User | null = null
}

export const {
  users,
  commit: commitUsers,
  mapGetters: mapGettersUsers,
  mapState: mapStateUsers,
  dispatch: dispatchUsers,
  useGetter: useGetterUsers,
  useState: useStateUsers,
} = createStore({
  namespaced: true,
  initState: new UsersState(),
  moduleName: 'users',
  mutations: {
    SET_USERS(state, users: User[]) {
      state.users = users.reduce<Record<string, User>>((acc, user) => {
        acc[user._id] = user
        return acc
      }, {})
    },

    LOAD(state) {
      state.loading = true
    },

    UNLOAD(state) {
      state.loading = false
    },

    SET_SEARCH(state, val: string) {
      state.search = val
    },

    RESET(state) {
      state.search = ''
      state.deletingUserId = null
      state.editingUser = null
      state.loading = false
    },

    SET_DELETING(state, id: string) {
      state.deletingUserId = id
    },

    SET_NOT_DELETING(state) {
      state.deletingUserId = null
    },

    SET_EDITING(state, user: User) {
      state.editingUser = cloneDeep(user)
    },
  },
  actions: {
    async list({ commit }) {
      try {
        commit('LOAD')
        const users = await UsersService.getAll()
        commit('SET_USERS', users)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async deleteUser({ commit, state, dispatch }) {
      try {
        commit('LOAD')
        if (!state.deletingUserId) return
        await UsersService.deleteUser(state.deletingUserId)
        SnackbarService.info(
          i18n.tc('USERS.SNACKBAR.DELETE', undefined, {
            string: state.users[state.deletingUserId].name,
          }),
        )
        await dispatch('list')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('RESET')
      }
    },

    async resetPassword({ commit, state }, id: string) {
      try {
        commit('LOAD')
        await UsersService.resetPassword(id)
        SnackbarService.info(
          i18n.tc('USERS.SNACKBAR.RESET', undefined, {
            string: state.users[id].name,
          }),
        )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async blockUser({ commit, dispatch, state }, id: string) {
      try {
        commit('LOAD')
        const user = cloneDeep(state.users[id])
        user.blocked = !user.blocked
        await UsersService.updateUser(user)
        const message = user.blocked
          ? i18n
              .tc('USERS.SNACKBAR.BLOCK', undefined, { string: user.name })
              .toString()
          : i18n
              .tc('USERS.SNACKBAR.UNBLOCK', undefined, { string: user.name })
              .toString()
        SnackbarService.info(message)
        await dispatch('list')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async addUser({ commit, state }, user: User) {
      try {
        let message = ''
        if (state.editingUser) {
          await UsersService.updateUser(user)
          message = i18n
            .tc('USERS.SNACKBAR.EDIT', undefined, { string: user.name })
            .toString()
        } else {
          await UsersService.addUser(user)
          message = i18n
            .tc('USERS.SNACKBAR.ADD', undefined, { string: user.name })
            .toString()
        }

        SnackbarService.info(message)
        commit('RESET')
        await router.push('/users')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async loadUser({ commit }, id: string) {
      try {
        commit('LOAD')
        const user = await UsersService.getUserById(id)
        commit('SET_EDITING', user)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },
  },
  getters: {
    getFilteredUsers: (state): User[] => {
      return state.search && state.search.length > 0
        ? Object.values(state.users).filter(
            (u) =>
              u.name.includes(state.search) || u.email.includes(state.search),
          )
        : Object.values(state.users)
    },

    getUser: (state) => {
      return state.editingUser
        ? state.editingUser
        : {
            name: '',
            email: '',
            expiryDate: '',
            blocked: 'false',
          }
    },
  },
})
