import { type RouteConfig } from 'vue-router'

import { dispatchUsers } from './users.store'

export const UsersRouter: RouteConfig[] = [
  {
    path: '/users',
    component: async () => import('./Users.vue'),
  },
  {
    path: '/users/create',
    component: async () => import('./AddUser.vue'),
  },
  {
    path: '/users/:id',
    component: async () => import('./AddUser.vue'),
    async beforeEnter(to, from, next): Promise<void> {
      await dispatchUsers('loadUser', to.params.id)
      next()
    },
  },
]
