import axios from 'axios'

import {
  type PageChoice,
  type PageChoiceData,
  type PageContent,
  type PageContentLocation,
} from './pageContent.type'

export const PageContentService = {
  async getAll(page: PageContentLocation): Promise<PageContent[]> {
    const { data } = await axios.get(`/api/pageContent/${page}/all`)
    return data
  },
  async saveOne(homeContent: PageContent): Promise<PageContent> {
    const { data } = await axios.post('/api/pageContent', homeContent)
    return data
  },
  async saveAll(
    pageContents: Omit<PageContent, 'page'>[],
    page: PageContentLocation,
  ): Promise<PageContent[]> {
    const { data } = await axios.post(
      `/api/pageContent/${page}/all`,
      pageContents,
    )
    return data
  },
  async getChoices(): Promise<PageChoice[]> {
    const { data } = await axios.get('/api/pageContent/choices')
    return data
  },
  async getAllEvents(): Promise<PageChoiceData[]> {
    const { data } = await axios.get('/api/pageContent/eventChoices')
    return data
  },
}
