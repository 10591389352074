<template>
  <div style="text-align: right" v-if="versionObj">
    <a
      target="_blank"
      v-if="!versionObj.upToDate"
      :href="monitorLink"
      style="cursor: pointer"
    >
      <v-chip class="ma-2" color="warning">
        <v-avatar left><v-icon>fa-circle-exclamation</v-icon></v-avatar>
        Mettre à jour {{ versionObj.version }}</v-chip
      >
    </a>
    <v-chip class="ma-2" color="success" text-color="white" v-else
      ><v-avatar left><v-icon>fa-check-circle</v-icon></v-avatar
      >{{ versionObj.version }}</v-chip
    >
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'

import store from '../../store'
import { dispatchSession, useGetterSession } from '../store/session.store'

const versionObj = computed(() => store.state.session.version)
const isAdmin = useGetterSession('isAdmin')

onMounted(async () => {
  if (isAdmin.value && !versionObj.value) {
    await dispatchSession('CHECK_VERSION')
  }
})

const env = window.location.host.includes('.chapi.to')
  ? window.location.host.substring(0, window.location.host.indexOf('.chapi.to'))
  : 'itg'

const monitorLink = `https://itg-chapito-monitor.c-t.io/?display=list&search=${env}`
</script>
