import cloneDeep from 'lodash/cloneDeep'

type Sortable = { _id: string; weight: number }
export const IdsService = {
  generateId(): string {
    return '_' + Math.random().toString(36).substring(2, 9)
  },
}

export const colorService = {
  rgba_to_argb(color = ''): string {
    let newColor = ''
    if (color.length === 9) {
      newColor = '#' + color.slice(7) + color.slice(1, 7)
    } else {
      newColor = color
    }
    return newColor
  },

  remove_alpha(color = ''): string {
    if (color.length === 9) {
      return '#' + color.slice(1, 7)
    }
    return color
  },

  argb_to_rgba(color = ''): string {
    let newColor = ''
    if (color.length === 9) {
      const alpha = color.slice(1, 3)
      newColor = '#' + color.slice(3) + alpha
    } else {
      newColor = color
    }
    return newColor
  },
}

export const weightService = {
  getMinWeight(objects: Record<string, Sortable>): number {
    return Object.values(objects).length === 0
      ? 100
      : Object.values(objects).reduce(
          (acc, object) => (object.weight < acc ? object.weight : acc),
          Object.values(objects)[0].weight,
        )
  },

  getIndex<T extends { _id: string }>(
    objects: Record<string, T>,
    id: string,
    sortfunction: (a: T, b: T) => number,
  ): number {
    const objectsSorted = Object.values(cloneDeep(objects))
    objectsSorted.sort((a, b) => sortfunction(a, b))
    return objectsSorted.indexOf(objectsSorted.filter((n) => n._id === id)[0])
  },

  isMaxWeight<T extends { _id: string }>(
    objects: Record<string, T>,
    object: T,
    sortfunction: (a: T, b: T) => number,
  ): boolean {
    const objectsSorted = Object.values(cloneDeep(objects))
    objectsSorted.sort((a, b) => sortfunction(a, b))
    return object._id === objectsSorted[0]._id
  },

  sortWeight(a: Sortable | null, b: Sortable | null): number {
    return (b ? b.weight : 0) - (a ? a.weight : 0)
  },

  formatWeight<T extends Sortable>(objects: T[]): T[] {
    const results = cloneDeep(objects)
    results.sort((a, b) => this.sortWeight(a, b))
    let i = 99
    return results.map((o) => {
      o.weight = i--
      return o
    })
  },
}
