import axios from 'axios'

import { type PreviewGrabData } from '../types/applicationData.type'

export const ApplicationDataService = {
  async getCurrent(
    locale: 'fr' | 'en',
    filterOnlyVisible: boolean,
  ): Promise<PreviewGrabData> {
    const { data } = await axios.get<PreviewGrabData>(
      `/api/exports/current?locale=${locale}${filterOnlyVisible ? '&filterOnlyVisible=1' : ''}`,
    )
    return data
  },
}
