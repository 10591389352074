import { cloneDeep } from 'lodash'

import { i18n } from '@/i18n/i18n'
import router from '@/router'
import { ErrorService } from '@/shared/service/errorService'
import {
  FilesService,
  type ImageTransformations,
} from '@/shared/service/files.service'
import { IdsService } from '@/shared/service/utils.service'
import { SnackbarService } from '@/shared/snackbar/snackbar.service'

import { type Lang } from '../../../types/TranslatableText.type'
import { dispatchAppConfig } from '../../shared/store/app-config.store'
import { createStore, type GettersObj } from '../../utils/createStore'
import { type Button, type Info, type Section } from './Infos'
import { type InfosPratiqueUsage, InfosService } from './infos.service'

export type StateInfo = Omit<Info, 'sections'> & {
  sections: Record<string, StateSection>
}
export type StateSection = Omit<Section, 'buttons'> & {
  buttons: Record<string, Button>
}

export type GettersInfos = GettersObj<typeof mapGettersInfos>

export class InfosState {
  infos: Record<string, StateInfo> = {}
  deleteInfoId: string | null = null
  loading = false

  //form
  editingInfo: Partial<StateInfo> | null = null
  infoToMoveId: string | null = null

  //sections
  sections: Record<string, StateSection> = {}
  deletingSectionId: string | null = null
  //preview
  selectedInfo: string | null = null

  togglingVisibilityInfoId: string | null = null
  infoPratiqueUsage: InfosPratiqueUsage | null = null
  showVisibilityModal = false
}

export const {
  infos,
  commit: commitInfos,
  dispatch: dispatchInfos,
  mapGetters: mapGettersInfos,
  mapState: mapStateInfos,
  useState: useStateInfos,
  useGetter: useGetterInfos,
} = createStore({
  moduleName: 'infos',
  initState: new InfosState(),
  mutations: {
    RESET(state) {
      state.sections = {}
      state.deleteInfoId = null
      state.editingInfo = null
      state.loading = false
    },

    UNLOAD(state) {
      state.loading = false
    },

    LOAD(state) {
      state.loading = true
    },

    SET_INFOS(state, infos: StateInfo[]) {
      state.infos = infos.reduce(
        (acc: Record<string, StateInfo>, info: StateInfo) => {
          acc[info._id] = info
          return acc
        },
        {},
      )
    },

    HIDE_INFO(state, id: string) {
      state.infos[id].showInfoPratique = !state.infos[id].showInfoPratique
    },

    SET_DELETING(state, id: string) {
      state.deleteInfoId = id
    },

    SET_SELECTED_INFO(state, info: string) {
      state.selectedInfo = info
    },

    SET_EDITING(state, info: StateInfo) {
      state.editingInfo = { ...info }

      Object.values(info.sections).forEach((section) => {
        let sectionId: string
        do {
          sectionId = IdsService.generateId()
        } while (sectionId in state.sections)
        state.sections[sectionId] = { ...section, buttons: {} }
        Object.values(section.buttons).forEach((button) => {
          let buttonId
          do {
            buttonId = IdsService.generateId()
          } while (buttonId in state.sections[sectionId].buttons)
          state.sections[sectionId].buttons[buttonId] = button
        })
      })
      state.sections = { ...state.sections }
    },

    ADD_SECTION(state) {
      Object.values(state.sections).forEach((sections) => {
        sections.weight++
      })
      let id
      do {
        id = IdsService.generateId()
      } while (id in state.sections)
      state.sections[id] = {
        names: {
          fr: '',
          en: '',
        },
        weight: 0,
        buttons: {},
        links: null,
        descriptions: {
          fr: '',
          en: '',
        },
      }
      state.sections = { ...state.sections }
    },

    SET_DELETING_SECTION(state, id: string) {
      state.deletingSectionId = id
    },

    CANCEL_SECTION_DELETING(state) {
      state.deletingSectionId = null
    },

    DELETE_SECTION(state) {
      if (state.deletingSectionId) {
        const { weight } = state.sections[state.deletingSectionId]
        delete state.sections[state.deletingSectionId]
        Object.values(state.sections).forEach((section) => {
          section.weight > weight ? section.weight-- : ''
        })
        state.deletingSectionId = null
        state.sections = { ...state.sections }
      }
    },

    SET_SECTION_NAME(
      state,
      payload: { id: string; value: string; lang: Lang },
    ) {
      state.sections[payload.id].names[payload.lang] = payload.value
    },

    SET_SECTION_DESCRIPTION(
      state,
      payload: { id: string; value: string; lang: Lang },
    ) {
      state.sections[payload.id].descriptions[payload.lang] = payload.value
    },

    MOVE_SECTION(state, payload: { sectionId: string; up_down: number }) {
      const tab = Object.values(state.sections).sort((a, b) => {
        return a.weight - b.weight
      })
      const index = tab.indexOf(state.sections[payload.sectionId])
      const { weight } = tab[index]
      tab[index].weight = tab[index + payload.up_down].weight
      tab[index + payload.up_down].weight = weight

      state.sections = { ...state.sections }
    },

    ADD_BUTTON(state, sectionId: string) {
      let id
      do {
        id = IdsService.generateId()
      } while (id in state.sections[sectionId].buttons)

      state.sections[sectionId].buttons[id] = {
        names: {
          fr: '',
          en: '',
        },
        icon: '',
        target: '',
      }

      state.sections[sectionId].buttons = {
        ...state.sections[sectionId].buttons,
      }
    },

    REMOVE_BUTTON(state, payload: { sectionId: string; buttonId: string }) {
      delete state.sections[payload.sectionId].buttons[payload.buttonId]
      state.sections[payload.sectionId].buttons = {
        ...state.sections[payload.sectionId].buttons,
      }
    },

    SET_BUTTON_NAME(
      state,
      payload: {
        sectionId: string
        buttonId: string
        value: string
        lang: Lang
      },
    ) {
      state.sections[payload.sectionId].buttons[payload.buttonId].names[
        payload.lang
      ] = payload.value
    },

    SET_BUTTON_URL(
      state,
      payload: { sectionId: string; buttonId: string; value: string },
    ) {
      const button = state.sections[payload.sectionId].buttons[payload.buttonId]
      button.target = payload.value
    },

    SET_BUTTON_ICON(
      state,
      payload: { sectionId: string; id: string; value: string },
    ) {
      const button = state.sections[payload.sectionId].buttons[payload.id]
      button.icon = payload.value
    },

    CLOSE_VISIBILITY_MODAL(state) {
      state.showVisibilityModal = false
      state.togglingVisibilityInfoId = null
      state.infoPratiqueUsage = {
        pageContents: [],
        navBarElements: [],
      }
    },

    MOVE_INFO(state, payload: { infoId: string; up_down: number }) {
      const tab = Object.values(state.infos).sort((a, b) => {
        if (a.weight === b.weight) {
          if (a.names.fr < b.names.fr) return -1
          if (a.names.fr > b.names.fr) return 1
          return 0
        }
        return a.weight - b.weight
      })
      const index = tab.indexOf(state.infos[payload.infoId])
      const { weight } = tab[index]
      tab[index].weight = tab[index + payload.up_down].weight
      tab[index + payload.up_down].weight = weight
      state.infoToMoveId = tab[index + payload.up_down]._id
      state.infos = { ...state.infos }
    },
  },
  actions: {
    async list({ commit }) {
      commit('LOAD')
      try {
        await dispatchAppConfig('LOAD_APP_CONFIG')
        const infos = await InfosService.getAll()
        commit('SET_INFOS', infos)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async initHide({ state }, infoId: string): Promise<void> {
      // fetch usage only when hidding the event
      const usage = state.infos[infoId].showInfoPratique
        ? await InfosService.checkUsage([infoId])
        : {
            pageContents: [],
            navBarElements: [],
          }
      // if used somewhere, show confirmation dialog
      if (usage.pageContents.length > 0 || usage.navBarElements.length > 0) {
        state.togglingVisibilityInfoId = infoId
        state.infoPratiqueUsage = usage
        state.showVisibilityModal = true
      } else {
        // else hide directly
        await dispatchInfos('hideInfo', infoId)
      }
    },

    async hideInfo({ state }, id: string) {
      commitInfos('LOAD')
      try {
        const info = { ...state.infos[id] }
        info.showInfoPratique = !info.showInfoPratique
        await InfosService.updateInfo(info)
        commitInfos('HIDE_INFO', id)
        info.showInfoPratique
          ? SnackbarService.info(
              i18n.tc('INFOS.SNACK_BAR.SHOW', undefined, {
                string: info.names.fr,
              }),
            )
          : SnackbarService.info(
              i18n.tc('INFOS.SNACK_BAR.HIDE', undefined, {
                string: info.names.fr,
              }),
            )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commitInfos('UNLOAD')
        commitInfos('CLOSE_VISIBILITY_MODAL')
      }
    },

    async deleteInfo({ state, commit, dispatch }) {
      commit('LOAD')
      if (state.deleteInfoId) {
        try {
          await InfosService.delete(state.deleteInfoId)
          SnackbarService.info(
            i18n.tc('INFOS.SNACK_BAR.DELETE', undefined, {
              string: state.infos[state.deleteInfoId].names.fr,
            }),
          )
          await dispatch('list')
        } catch (e) {
          ErrorService.handleError(e)
        } finally {
          commit('RESET')
        }
      }
    },

    async addInfo(
      { state, commit, getters },
      {
        info,
        imageTransformations,
      }: {
        info: StateInfo
        imageTransformations: ImageTransformations | null | undefined
      },
    ) {
      commit('LOAD')
      {
        try {
          const infoToSend = cloneDeep(info)
          if (state.editingInfo == null) {
            infoToSend.weight = getters.getInfoMinWeight - 1
            await InfosService.addInfo(infoToSend)
            SnackbarService.info(
              i18n.tc('INFOS.SNACK_BAR.ADD', undefined, {
                string: info.names.fr,
              }),
            )
          } else {
            await InfosService.updateInfo(infoToSend)
            SnackbarService.info(
              i18n.tc('INFOS.SNACK_BAR.EDIT', undefined, {
                string: info.names.fr,
              }),
            )
          }
          if (infoToSend.image && imageTransformations) {
            await FilesService.setImageTransform(
              infoToSend.image,
              imageTransformations,
            )
          }
          await router.push('/infosPratiques')
        } catch (e) {
          ErrorService.handleError(e)
        }
      }
    },

    async loadInfo({ commit }, infoId: string | undefined) {
      commit('RESET')
      commit('LOAD')
      try {
        if (infoId) {
          commit('SET_EDITING', await InfosService.getInfoById(infoId))
        } else {
          commit('SET_EDITING', {
            icon: undefined,
            names: {
              fr: '',
              en: '',
            },
            sections: {},
            showInfoPratique: false,
            weight: 100,
          })
        }
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    async moveInfo(
      { state, commit, dispatch },
      payload: {
        infoToMove: StateInfo
        up_down: number
      },
    ) {
      commit('LOAD')
      try {
        commit('MOVE_INFO', {
          infoId: payload.infoToMove._id,
          up_down: payload.up_down,
        })
        await InfosService.updateInfo(state.infos[payload.infoToMove._id])
        if (state.infoToMoveId) {
          await InfosService.updateInfo(state.infos[state.infoToMoveId])
        }
        await dispatch('list')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('RESET')
      }
    },

    reset({ commit }) {
      commit('RESET')
    },
  },
  getters: {
    getInfos: (state) => {
      const tab = cloneDeep(state.infos)
      return Object.values(tab)
    },

    getInfoMinWeight: (state) => {
      return Object.values(state.infos).length === 0
        ? 100
        : Math.min(...Object.values(state.infos).map((info) => info.weight))
    },

    getSortedSections: (state) => {
      const sortedSections = [...Object.values(state.sections)]
      return sortedSections.sort(function (a, b) {
        return b.weight - a.weight
      })
    },

    getSections: (state) => {
      return Object.values(state.sections)
    },

    getButtons(state) {
      return (sectionId: string): Button[] => {
        return Object.values(state.sections[sectionId].buttons)
      }
    },
  },
})
