<template>
  <modal
    :title="$tc('PUBLICATION.CREATE_MODAL.TITLE')"
    :btn-ok-label="$tc('ACTION.CONFIRM')"
    max-width="900px"
    @okAction="agreeAction"
    @cancelAction="disagreeAction"
  >
    <v-textarea
      v-model="exportDetails.message"
      rows="15"
      color="bleu2"
      :label="$tc('PUBLICATION.CREATE_MODAL.COMMENT')"
      no-resize
      :placeholder="$tc('PUBLICATION.CREATE_MODAL.COMMENT_PLACEHOLDER')"
    />
  </modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { dispatchWarningBanner } from '../../shared/store/warningBanner.store'
import { PublicationsService } from './publications.service'
import { commitPublications } from './publications.store'

export default defineComponent({
  components: {},
  data: () => ({
    exportDetails: {
      message: '',
    },
  }),
  methods: {
    async agreeAction() {
      await PublicationsService.doPublication(this.exportDetails)
      this.$emit('refresh-data')
      commitPublications('hidePublicationModal')
      setTimeout(this.updateBanner, 2000)
    },

    async updateBanner() {
      await dispatchWarningBanner('loadWarningBanner')
    },

    disagreeAction() {
      commitPublications('hidePublicationModal')
    },
  },
})
</script>
