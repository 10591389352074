import cloneDeep from 'lodash/cloneDeep'

import { ErrorService } from '@/shared/service/errorService'

import { type TranslatableText } from '../../../../types/TranslatableText.type'
import { createStore, type GettersObj } from '../../../utils/createStore'
import { GenresService } from '../genres.service'

export type Genre = {
  _id: string
  names: TranslatableText
  nameNormalized?: string
  color?: string
  externalId?: string
}

export type NewGenre = Omit<Genre, '_id'> & { _id?: string }

export type GettersGuestGenres = GettersObj<typeof mapGettersGuestGenres>

export class GuestGenresState {
  genresSearch = ''
  filter = ''
  genres: Record<string, Genre> = {}
  editing?: Genre = undefined
  selectedGenres: Genre[] = []
}

export const {
  guestGenres,
  commit: commitGuestGenres,
  dispatch: dispatchGuestGenres,
  mapGetters: mapGettersGuestGenres,
  mapState: mapStateGuestGenres,
  useGetter: useGetterGuestGenres,
  useState: useStateGuestGenres,
} = createStore({
  namespaced: true,
  moduleName: 'guestGenres',
  initState: new GuestGenresState(),

  actions: {
    async addGenres({ dispatch }, genre: Genre) {
      try {
        await GenresService.addGenre(genre)
        await dispatch('getGenres')
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    async getGenres({ commit }) {
      try {
        const genres = await GenresService.getAll()
        commit('SET_GENRES', genres)
      } catch (e) {
        ErrorService.handleError(e)
      }
    },
    async deleteGenre({ state }, genre: Genre) {
      delete state.genres[genre._id]
      state.genres = { ...state.genres }
      state.selectedGenres = state.selectedGenres.filter(
        (g) => g._id !== genre._id,
      )
      await GenresService.deleteGenre(genre._id)
    },

    async updateGenre({ state }, genre: Genre) {
      state.genres[genre._id] = genre
      await GenresService.updateGenre(genre)
      state.genres = { ...state.genres }
      // state.selectedGenres = [
      //   ...state.selectedGenres.filter(g => typeof g !== "string")
      // ];
      const selectedItemUpdatedIndex = state.selectedGenres.findIndex(
        (g) => g._id === genre._id,
      )
      if (selectedItemUpdatedIndex !== -1) {
        state.selectedGenres[selectedItemUpdatedIndex].names.fr = genre.names.fr
      }
    },
  },

  getters: {
    getGenres(state) {
      return Object.values(state.genres)
    },

    getGenreById(state) {
      return (id: string): Genre | undefined => {
        return cloneDeep(state.genres[id])
      }
    },

    filteredGenres(state) {
      return state.genresSearch && state.genresSearch.length > 0
        ? [
            ...Object.values(state.genres),
            {
              names: { fr: state.genresSearch, en: state.genresSearch },
              nameNormalized: state.genresSearch.toLowerCase(),
              color: '',
              _id: null,
              icon: null,
            },
          ]
        : Object.values(state.genres)
    },

    filteredGenresWithoutNonSaved(state) {
      return Object.values(state.genres)
        .filter((g) => !g._id.startsWith('_'))
        .filter((item) =>
          item.nameNormalized
            ?.toLowerCase()
            .includes(state.filter.toLowerCase()),
        )
    },

    getSelectedGenres(state) {
      return state.selectedGenres.map((genre) => genre._id)
    },
  },

  mutations: {
    RESET(state) {
      state.genresSearch = ''
      state.genres = {}
      state.selectedGenres = []
      state.filter = ''
    },

    SET_GENRES(state, genres: Genre[]) {
      state.genres = genres.reduce(
        (acc: Record<string, Genre>, genre: Genre) => {
          acc[genre._id] = genre
          return acc
        },
        {},
      )
    },

    SET_GENRES_SEARCH(state, genresSearch: string) {
      state.genresSearch = genresSearch
    },

    SET_GENRES_FILTER(state, filter: string) {
      state.filter = filter
    },

    SET_GENRES_SELECTION(state, genres: Genre[]) {
      state.selectedGenres = genres
    },
  },
})
