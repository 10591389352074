import { createStore, type GettersObj } from '../../utils/createStore'

export type GettersScrollPosition = GettersObj<typeof mapGettersScrollPosition>

export class ScrollPositionState {
  scrollPosition: Record<string, number> = {}
}

export const {
  scrollPosition,
  mapGetters: mapGettersScrollPosition,
  useGetter: useGetterScrollPosition,
  useState: useStateScrollPosition,
  mapState: mapStateScrollPosition,
  dispatch: dispatchScrollPosition,
  commit: commitScrollPosition,
} = createStore({
  namespaced: true,
  initState: new ScrollPositionState(),
  moduleName: 'scrollPosition',
  actions: {},
  mutations: {
    SET_SCROLL_POSITION(
      state: ScrollPositionState,
      payload: { key: string; posY: number },
    ) {
      state.scrollPosition[payload.key] = payload.posY
    },
  },

  getters: {
    getScrollPosition: (state: ScrollPositionState) => {
      return (key: string): number => {
        return state.scrollPosition[key] ? state.scrollPosition[key] : 0
      }
    },
  },
})
