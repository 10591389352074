import { ErrorService } from '@/shared/service/errorService'

import { i18n } from '../../i18n/i18n'
import { weightService } from '../../shared/service/utils.service'
import { SnackbarService } from '../../shared/snackbar/snackbar.service'
import { type ProgEvent } from '../../shared/types/progEvent.type'
import { type Scene } from '../../shared/types/scene.type'
import { createStore, type GettersObj } from '../../utils/createStore'
import { ScenesService } from './scenes.service'

export type GettersScenes = GettersObj<typeof mapGettersScenes>
export class ScenesState {
  namespaced = true
  scenes: Record<string, Scene> = {}
  deletingSceneId: string | null = null
  editingSceneId: string | null = null
  loading = false
  showModal = false
  sceneToMoveId: string | null = null
  deletingSceneEvents: ProgEvent[] = []
  deletingSceneMarkers: { _id: string; name: string }[] = []
}

export function sortScenes(a: Scene, b: Scene): number {
  if (a.weight === b.weight) {
    if (a.names.fr < b.names.fr) return -1
    if (a.names.fr > b.names.fr) return 1
    return 0
  }
  return b.weight - a.weight
}

export const {
  scenes,
  commit: commitScenes,
  dispatch: dispatchScenes,
  mapGetters: mapGettersScenes,
  mapState: mapStateScenes,
  useGetter: useGetterScenes,
  useState: useStateScenes,
} = createStore({
  namespaced: true,
  initState: new ScenesState(),
  moduleName: 'scenes',
  mutations: {
    LOAD(state) {
      state.loading = true
    },

    UNLOAD(state) {
      state.loading = false
    },

    SET_SCENES(state, scenes: Scene[]) {
      state.scenes = scenes.reduce<Record<string, Scene>>((acc, scene) => {
        acc[scene._id] = scene
        return acc
      }, {})
    },

    SET_DELETING(state, id: string) {
      state.deletingSceneId = id
    },

    SET_NOT_DELETING(state) {
      state.deletingSceneId = null
    },

    SET_ADDING(state) {
      state.showModal = true
    },

    SET_EDITING(state, id: string) {
      state.editingSceneId = id
      state.showModal = true
    },

    RESET(state) {
      state.editingSceneId = null
      state.showModal = false
    },

    SET_SCENE_EVENTS(state, events: ProgEvent[]) {
      state.deletingSceneEvents = [...events]
    },

    SET_SCENE_MARKERS(state, markers: { _id: string; name: string }[]) {
      state.deletingSceneMarkers = [...markers]
    },
  },
  actions: {
    async list({ commit }) {
      commit('LOAD')
      try {
        const scenes = await ScenesService.getAll()
        commit('SET_SCENES', scenes)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async deleteScene({ dispatch, state, commit }) {
      commit('LOAD')
      try {
        if (!state.deletingSceneId) {
          return
        }
        await ScenesService.deleteScene(state.deletingSceneId)
        SnackbarService.info(
          i18n.tc('SCENES.SNACK_BAR.DELETE', undefined, {
            string: state.scenes[state.deletingSceneId].names.fr,
          }),
        )
        await dispatch('list')
      } catch (e) {
        ErrorService.handleError(e)
        commit('UNLOAD')
      } finally {
        commit('SET_NOT_DELETING')
      }
    },

    async addScene({ commit, dispatch, state, getters }, scene: Scene) {
      commit('LOAD')
      try {
        let message = ''
        if (state.editingSceneId) {
          await ScenesService.updateScene(scene)
          message = i18n.tc('SCENES.SNACK_BAR.EDIT', undefined, {
            string: scene.names.fr,
          })
        } else {
          scene.weight = getters.getMinWeight
          await ScenesService.addScene(scene)
          message = i18n.tc('SCENES.SNACK_BAR.ADD', undefined, {
            string: scene.names.fr,
          })
        }
        SnackbarService.info(message)
        commit('RESET')
        await dispatch('list')
      } catch (e) {
        ErrorService.handleError(e)
        commit('UNLOAD')
      }
    },

    async move(
      { state, commit },
      payload: { sceneToMove: Scene; up_down: number },
    ) {
      commit('LOAD')
      try {
        const tab = Object.values(state.scenes).sort(sortScenes)
        const index = tab.indexOf(state.scenes[payload.sceneToMove._id])
        const { weight } = tab[index]
        tab[index].weight = tab[index + payload.up_down].weight
        tab[index + payload.up_down].weight = weight
        state.sceneToMoveId = tab[index + payload.up_down]._id
        state.scenes = { ...state.scenes }

        await ScenesService.updateScene(state.scenes[payload.sceneToMove._id])
        await ScenesService.updateScene(state.scenes[state.sceneToMoveId])

        state.sceneToMoveId = null
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async setDeletionMode({ commit }, sceneId: string) {
      commit('LOAD')
      try {
        const [eventList, markers] = await Promise.all([
          ScenesService.getSceneEvents(sceneId),
          ScenesService.getSceneMarkers(sceneId),
        ])
        commit('SET_SCENE_EVENTS', eventList)
        commit('SET_SCENE_MARKERS', markers)
        commit('SET_DELETING', sceneId)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },
  },
  getters: {
    getScenes: (state) => {
      return Object.values(state.scenes).sort((a, b) =>
        a.names.fr.localeCompare(b.names.fr),
      )
    },

    getScene: (state) => {
      return state.editingSceneId
        ? state.scenes[state.editingSceneId]
        : {
            names: { fr: '' },
            color: '#FFFFFFFF',
            weight: 100,
            address: '',
            // googleAddress: null,
            addressPlaceId: null,
            addressPlaceLatitude: null,
            addressPlaceLongitude: null,
            addressPlaceUrl: null,
          }
    },

    getMinWeight: (state) => {
      return weightService.getMinWeight(state.scenes)
    },

    getIndex: (state) => {
      return (sceneId: string): number => {
        return weightService.getIndex(state.scenes, sceneId, sortScenes)
      }
    },

    getSceneEvents: (state) => {
      return state.deletingSceneEvents
    },

    sceneIsDeletable: (state) => {
      return state.deletingSceneEvents
        ? state.deletingSceneEvents.length === 0
        : true
    },

    isMaxWeight: (state) => {
      return (scene: Scene): boolean => {
        return weightService.isMaxWeight(state.scenes, scene, sortScenes)
      }
    },

    isMinWeight: (state) => {
      return (scene: Scene): boolean => {
        return scene.weight === weightService.getMinWeight(state.scenes)
      }
    },
  },
})
